/*
            This file is semi-automatically generated from
            cliq/taxonomy/scripts/generate_gics_2023__ceda_5_weighted_mapping.py in the cliq repo
            Do not manually modify this file
        */
import type { WeightedBeaCodes } from './industryCodeUtils';

const GICS_CODES_2023: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['10101010']: { label: 'Oil & Gas Drilling', BEA_CODES: { '213111': 1.0 } },
  ['10101020']: {
    label: 'Oil & Gas Equipment & Services',
    BEA_CODES: { '333130': 1.0 },
  },
  ['10102010']: { label: 'Integrated Oil & Gas', BEA_CODES: { '211000': 1.0 } },
  ['10102020']: {
    label: 'Oil & Gas Exploration & Production',
    BEA_CODES: { '211000': 1.0 },
  },
  ['10102030']: {
    label: 'Oil & Gas Refining & Marketing',
    BEA_CODES: { '324110': 1.0 },
  },
  ['10102040']: {
    label: 'Oil & Gas Storage & Transportation',
    BEA_CODES: { '424700': 0.78949169, '486000': 0.21050831 },
  },
  ['10102050']: {
    label: 'Coal & Consumable Fuels',
    BEA_CODES: {
      '212100': 0.36655104,
      '324121': 0.14192524,
      '324122': 0.11239732,
      '324190': 0.37912639,
    },
  },
  ['15101010']: {
    label: 'Commodity Chemicals',
    BEA_CODES: {
      '325110': 0.17222405,
      '325130': 0.01461389,
      '325180': 0.0623789,
      '325190': 0.21665167,
      '325211': 0.1430755,
      '3252A0': 0.03820507,
      '325510': 0.03854839,
      '325520': 0.01942357,
      '325910': 0.00842106,
      '326110': 0.06716428,
      '326120': 0.0253035,
      '326130': 0.0060225,
      '326140': 0.01295346,
      '326150': 0.01529545,
      '326160': 0.02056117,
      '326190': 0.13915756,
    },
  },
  ['15101020']: {
    label: 'Diversified Chemicals',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['15101030']: {
    label: 'Fertilizers & Agricultural Chemicals',
    BEA_CODES: { '325310': 0.63980906, '325320': 0.36019094 },
  },
  ['15101040']: { label: 'Industrial Gases', BEA_CODES: { '325120': 1.0 } },
  ['15101050']: {
    label: 'Specialty Chemicals',
    BEA_CODES: {
      '325411': 0.25316377,
      '325413': 0.24498983,
      '325414': 0.5018464,
    },
  },
  ['15102010']: {
    label: 'Construction Materials',
    BEA_CODES: {
      '212310': 0.13633539,
      '2123A0': 0.15345015,
      '327100': 0.06968591,
      '327310': 0.05567649,
      '327320': 0.19443327,
      '327330': 0.05213264,
      '327390': 0.08210403,
      '327400': 0.05772665,
      '327910': 0.04629456,
      '327991': 0.03459889,
      '327992': 0.03153341,
      '327993': 0.04742703,
      '327999': 0.03860158,
    },
  },
  ['15103010']: {
    label: 'Metal & Glass Containers',
    BEA_CODES: { '327200': 0.55418884, '332430': 0.44581116 },
  },
  ['15103020']: {
    label: 'Paper Packaging',
    BEA_CODES: { '322210': 0.72835235, '322220': 0.27164765 },
  },
  ['15104010']: { label: 'Aluminum', BEA_CODES: { '331313': 1.0 } },
  ['15104020']: {
    label: 'Diversified Metals & Mining',
    BEA_CODES: {
      '331410': 0.28709566,
      '331420': 0.23563654,
      '331490': 0.13719539,
      '331510': 0.2039719,
      '331520': 0.13610051,
    },
  },
  ['15104025']: { label: 'Copper', BEA_CODES: { '212230': 1.0 } },
  ['15104030']: { label: 'Gold', BEA_CODES: { '2122A0': 1.0 } },
  ['15104040']: {
    label: 'Precious Metals & Minerals',
    BEA_CODES: { '2122A0': 1.0 },
  },
  ['15104045']: { label: 'Silver', BEA_CODES: { '2122A0': 1.0 } },
  ['15104050']: {
    label: 'Steel',
    BEA_CODES: { '331110': 0.93019215, '331200': 0.06980785 },
  },
  ['15105010']: {
    label: 'Forest Products',
    BEA_CODES: {
      '113000': 0.15662921,
      '321100': 0.2763826,
      '321200': 0.1803321,
      '321910': 0.18444001,
      '3219A0': 0.20221608,
    },
  },
  ['15105020']: {
    label: 'Paper Products',
    BEA_CODES: {
      '322110': 0.07116557,
      '322120': 0.45073734,
      '322130': 0.26406223,
      '322230': 0.06754027,
      '322291': 0.09801552,
      '322299': 0.04847907,
    },
  },
  ['20101010']: {
    label: 'Aerospace & Defense',
    BEA_CODES: {
      '336411': 0.50489821,
      '336412': 0.19148476,
      '336413': 0.16410575,
      '336414': 0.08619123,
      '33641A': 0.02546012,
      '336992': 0.02785994,
    },
  },
  ['20102010']: {
    label: 'Building Products',
    BEA_CODES: {
      '233210': 0.22124004,
      '233262': 0.39100026,
      '2332A0': 0.3877597,
    },
  },
  ['20103010']: {
    label: 'Construction & Engineering',
    BEA_CODES: {
      '230301': 0.31783421,
      '233230': 0.08806789,
      '233240': 0.20488679,
      '2332C0': 0.2201598,
      '2332D0': 0.16905131,
    },
  },
  ['20104010']: {
    label: 'Electrical Components & Equipment',
    BEA_CODES: {
      '335110': 0.01518014,
      '335120': 0.08560754,
      '335210': 0.02674234,
      '335221': 0.03717447,
      '335222': 0.02957597,
      '335224': 0.03046148,
      '335228': 0.03719977,
      '335311': 0.04832344,
      '335312': 0.09221934,
      '335313': 0.09028809,
      '335314': 0.08196431,
      '335911': 0.05291121,
      '335912': 0.0371576,
      '335920': 0.12476386,
      '335930': 0.09971664,
      '335991': 0.02795675,
      '335999': 0.08275705,
    },
  },
  ['20104020']: {
    label: 'Heavy Electrical Equipment',
    BEA_CODES: {
      '333611': 0.323531,
      '333612': 0.08162198,
      '333613': 0.0778537,
      '333618': 0.51699331,
    },
  },
  ['20105010']: {
    label: 'Industrial Conglomerates',
    BEA_CODES: { '550000': 1.0 },
  },
  ['20106010']: {
    label: 'Construction Machinery & Heavy Trucks',
    BEA_CODES: {
      '333120': 0.21975885,
      '333912': 0.05538961,
      '33391A': 0.08958164,
      '333920': 0.16585659,
      '336120': 0.2100869,
      '336500': 0.0806124,
      '336611': 0.10977459,
      '336612': 0.03406222,
      '336999': 0.0348772,
    },
  },
  ['20106015']: {
    label: 'Agricultural & Farm Machinery',
    BEA_CODES: { '333111': 1.0 },
  },
  ['20106020']: {
    label: 'Industrial Machinery',
    BEA_CODES: {
      '332114': 0.01818065,
      '332119': 0.02541486,
      '33211A': 0.03360257,
      '332310': 0.08386372,
      '332320': 0.07790159,
      '332410': 0.01513653,
      '332420': 0.01994164,
      '332500': 0.01605463,
      '332600': 0.01290211,
      '332710': 0.08827283,
      '332720': 0.06092221,
      '332800': 0.06043108,
      '332913': 0.00715678,
      '33291A': 0.05700645,
      '332991': 0.01501928,
      '332996': 0.01792624,
      '332999': 0.03199644,
      '33299A': 0.03014032,
      '33329A': 0.04327765,
      '333314': 0.01046216,
      '333316': 0.00380931,
      '333318': 0.03576036,
      '333413': 0.01226755,
      '333414': 0.00983762,
      '333415': 0.06082281,
      '333511': 0.01344426,
      '333514': 0.01720187,
      '333517': 0.01554331,
      '33351B': 0.01724116,
      '333991': 0.00766412,
      '333993': 0.01113013,
      '333994': 0.00537894,
      '33399A': 0.04222916,
      '33399B': 0.02205967,
    },
  },
  ['20107010']: {
    label: 'Trading Companies & Distributors',
    BEA_CODES: { '423800': 1.0 },
  },
  ['20201010']: {
    label: 'Commercial Printing',
    BEA_CODES: { '323110': 0.93181885, '323120': 0.06818115 },
  },
  ['20201050']: {
    label: 'Environmental & Facilities Services',
    BEA_CODES: {
      '562111': 0.46359142,
      '562212': 0.07462718,
      '562213': 0.04646524,
      '562910': 0.13758992,
      '562920': 0.06150698,
      '562HAZ': 0.12931473,
      '562OTH': 0.08690453,
    },
  },
  ['20201060']: {
    label: 'Office Services & Supplies',
    BEA_CODES: {
      '339940': 0.01160445,
      '561100': 0.20953944,
      '561200': 0.12036709,
      '561400': 0.33030969,
      '561500': 0.17262252,
      '561900': 0.15555681,
    },
  },
  ['20201070']: {
    label: 'Diversified Support Services',
    BEA_CODES: { '561700': 1.0 },
  },
  ['20201080']: {
    label: 'Security & Alarm Services',
    BEA_CODES: { '561600': 1.0 },
  },
  ['20202010']: {
    label: 'Human Resource & Employment Services',
    BEA_CODES: { '541200': 0.39651599, '561300': 0.60348401 },
  },
  ['20202020']: {
    label: 'Research & Consulting Services',
    BEA_CODES: {
      '541300': 0.25044318,
      '541400': 0.02319541,
      '541610': 0.17003564,
      '5416A0': 0.03891155,
      '541700': 0.4152724,
      '541920': 0.00983939,
      '541940': 0.0222737,
      '5419A0': 0.07002874,
    },
  },
  ['20202030']: {
    label: 'Data Processing & Outsourced Services',
    BEA_CODES: {
      '518200': 0.27749665,
      '561100': 0.0907285,
      '561300': 0.48875402,
      '561400': 0.14302083,
    },
  },
  ['20301010']: {
    label: 'Air Freight & Logistics',
    BEA_CODES: { '492000': 1.0 },
  },
  ['20302010']: { label: 'Airlines', BEA_CODES: { '481000': 1.0 } },
  ['20303010']: { label: 'Marine', BEA_CODES: { '483000': 1.0 } },
  ['20304010']: { label: 'Railroads', BEA_CODES: { '482000': 1.0 } },
  ['20304030']: {
    label: 'Cargo Ground Transportation',
    BEA_CODES: { '484000': 1.0 },
  },
  ['20304040']: {
    label: 'Passenger Ground Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['20305010']: { label: 'Airport Services', BEA_CODES: { '48A000': 1.0 } },
  ['20305020']: {
    label: 'Highways & Railtracks',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['20305030']: {
    label: 'Marine Ports & Services',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['25101010']: {
    label: 'Auto Parts & Equipment',
    BEA_CODES: {
      '336211': 0.02979046,
      '336212': 0.03363783,
      '336213': 0.01034912,
      '336214': 0.04254685,
      '336310': 0.11673327,
      '336320': 0.07889445,
      '336350': 0.15343451,
      '336360': 0.09483354,
      '336370': 0.11912776,
      '336390': 0.22778284,
      '3363A0': 0.09286938,
    },
  },
  ['25101020']: {
    label: 'Tires & Rubber',
    BEA_CODES: { '326210': 0.470142, '326220': 0.1243866, '326290': 0.4054714 },
  },
  ['25102010']: {
    label: 'Automobile Manufacturers',
    BEA_CODES: { '336111': 0.24163515, '336112': 0.75836485 },
  },
  ['25102020']: {
    label: 'Motorcycle Manufacturers',
    BEA_CODES: { '336991': 1.0 },
  },
  ['25201010']: { label: 'Consumer Electronics', BEA_CODES: { '334300': 1.0 } },
  ['25201020']: {
    label: 'Home Furnishings',
    BEA_CODES: {
      '337110': 0.41326742,
      '337121': 0.33417607,
      '337122': 0.15501302,
      '33712N': 0.09754349,
    },
  },
  ['25201030']: {
    label: 'Homebuilding',
    BEA_CODES: {
      '230302': 0.18161036,
      '233411': 0.33011152,
      '233412': 0.0688569,
      '2334A0': 0.41942122,
    },
  },
  ['25201040']: {
    label: 'Household Appliances',
    BEA_CODES: { '333112': 0.04084939, '423600': 0.95915061 },
  },
  ['25201050']: {
    label: 'Housewares & Specialties',
    BEA_CODES: { '332200': 1.0 },
  },
  ['25202010']: {
    label: 'Leisure Products',
    BEA_CODES: { '339920': 0.84255617, '339930': 0.15744383 },
  },
  ['25203010']: {
    label: 'Apparel, Accessories & Luxury Goods',
    BEA_CODES: {
      '315000': 0.42134781,
      '316000': 0.22490885,
      '339910': 0.35374335,
    },
  },
  ['25203020']: {
    label: 'Footwear',
    BEA_CODES: { '316000': 0.42817911, '339920': 0.57182089 },
  },
  ['25203030']: {
    label: 'Textiles',
    BEA_CODES: {
      '313100': 0.09866094,
      '313200': 0.2413779,
      '313300': 0.18423796,
      '314110': 0.18573035,
      '314120': 0.0761934,
      '314900': 0.21379945,
    },
  },
  ['25301010']: { label: 'Casinos & Gaming', BEA_CODES: { '713200': 1.0 } },
  ['25301020']: {
    label: 'Hotels, Resorts & Cruise Lines',
    BEA_CODES: { '721000': 1.0 },
  },
  ['25301030']: {
    label: 'Leisure Facilities',
    BEA_CODES: { '713100': 0.15494286, '713900': 0.84505714 },
  },
  ['25301040']: {
    label: 'Restaurants',
    BEA_CODES: {
      '722110': 0.38500025,
      '722211': 0.40996607,
      '722A00': 0.20503367,
    },
  },
  ['25302010']: {
    label: 'Education Services',
    BEA_CODES: {
      '611100': 0.12347016,
      '611A00': 0.65933,
      '611B00': 0.21719984,
    },
  },
  ['25302020']: {
    label: 'Specialized Consumer Services',
    BEA_CODES: {
      '541100': 0.30787799,
      '541920': 0.01379213,
      '541940': 0.03122162,
      '624100': 0.0893006,
      '624400': 0.05229969,
      '624A00': 0.04842285,
      '811100': 0.19587012,
      '811200': 0.04987719,
      '811300': 0.06292508,
      '811400': 0.03394243,
      '812200': 0.02026403,
      '812300': 0.02707634,
      '812900': 0.06712992,
    },
  },
  ['25501010']: {
    label: 'Distributors',
    BEA_CODES: {
      '423100': 0.1961997,
      '423400': 0.35977908,
      '423A00': 0.44402122,
    },
  },
  ['25503030']: {
    label: 'Broadline Retail',
    BEA_CODES: { '452000': 0.51472428, '454000': 0.48527572 },
  },
  ['25504010']: { label: 'Apparel Retail', BEA_CODES: { '448000': 1.0 } },
  ['25504020']: {
    label: 'Computer & Electronics Retail',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['25504030']: {
    label: 'Home Improvement Retail',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['25504040']: { label: 'Specialty Stores', BEA_CODES: { '4B0000': 1.0 } },
  ['25504050']: {
    label: 'Automotive Retail',
    BEA_CODES: { '441000': 0.66471583, '447000': 0.33528417 },
  },
  ['25504060']: {
    label: 'Homefurnishing Retail',
    BEA_CODES: { '444000': 1.0 },
  },
  ['30101010']: { label: 'Drug Retail', BEA_CODES: { '446000': 1.0 } },
  ['30101020']: { label: 'Food Distributors', BEA_CODES: { '424A00': 1.0 } },
  ['30101030']: { label: 'Food Retail', BEA_CODES: { '445000': 1.0 } },
  ['30101040']: {
    label: 'Hypermarkets & Super Centers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['30201010']: { label: 'Brewers', BEA_CODES: { '312120': 1.0 } },
  ['30201020']: {
    label: 'Distillers & Vintners',
    BEA_CODES: { '312130': 0.45389594, '312140': 0.54610406 },
  },
  ['30201030']: { label: 'Soft Drinks', BEA_CODES: { '312110': 1.0 } },
  ['30202010']: {
    label: 'Agricultural Products',
    BEA_CODES: {
      '1111A0': 0.09734972,
      '1111B0': 0.21083034,
      '111200': 0.03302653,
      '111300': 0.05864755,
      '111400': 0.04272857,
      '111900': 0.04008193,
      '112120': 0.08385625,
      '1121A0': 0.17875052,
      '112300': 0.08560225,
      '112A00': 0.09149902,
      '114000': 0.01845432,
      '115000': 0.059173,
    },
  },
  ['30202030']: {
    label: 'Packaged Foods & Meats',
    BEA_CODES: {
      '311111': 0.02562963,
      '311119': 0.05014088,
      '311210': 0.02642114,
      '311221': 0.02088866,
      '311224': 0.04986128,
      '311225': 0.02551752,
      '311230': 0.01302215,
      '311300': 0.04592938,
      '311410': 0.04097499,
      '311420': 0.05154291,
      '311513': 0.05053934,
      '311514': 0.02714917,
      '31151A': 0.05078652,
      '311520': 0.0097656,
      '311615': 0.07816936,
      '31161A': 0.18989835,
      '311700': 0.01425264,
      '311810': 0.06266462,
      '3118A0': 0.03564916,
      '311910': 0.04364264,
      '311920': 0.01706076,
      '311930': 0.0129276,
      '311940': 0.02613885,
      '311990': 0.03142686,
    },
  },
  ['30203010']: { label: 'Tobacco', BEA_CODES: { '312200': 1.0 } },
  ['30301010']: {
    label: 'Household Products',
    BEA_CODES: { '325610': 0.59885008, '325620': 0.40114992 },
  },
  ['30302010']: { label: 'Personal Products', BEA_CODES: { '446000': 1.0 } },
  ['35101010']: {
    label: 'Health Care Equipment',
    BEA_CODES: {
      '339112': 0.47543221,
      '339113': 0.41075911,
      '339114': 0.05485113,
      '339116': 0.05895756,
    },
  },
  ['35101020']: { label: 'Health Care Supplies', BEA_CODES: { '339115': 1.0 } },
  ['35102010']: {
    label: 'Health Care Distributors',
    BEA_CODES: { '424200': 1.0 },
  },
  ['35102015']: {
    label: 'Health Care Services',
    BEA_CODES: {
      '621100': 0.4902334,
      '621200': 0.12655897,
      '621300': 0.09706615,
      '621500': 0.06152026,
      '621600': 0.10312156,
      '621900': 0.04000329,
      '812100': 0.08149637,
    },
  },
  ['35102020']: {
    label: 'Health Care Facilities',
    BEA_CODES: {
      '622000': 0.81169225,
      '623A00': 0.15132977,
      '623B00': 0.03697797,
    },
  },
  ['35102030']: { label: 'Managed Health Care', BEA_CODES: { '621400': 1.0 } },
  ['35103010']: {
    label: 'Health Care Technology',
    BEA_CODES: { '541511': 1.0 },
  },
  ['35201010']: { label: 'Biotechnology', BEA_CODES: { '541700': 1.0 } },
  ['35202010']: { label: 'Pharmaceuticals', BEA_CODES: { '325412': 1.0 } },
  ['35203010']: {
    label: 'Life Sciences Tools & Services',
    BEA_CODES: { '339112': 0.53648935, '339113': 0.46351065 },
  },
  ['40101010']: { label: 'Diversified Banks', BEA_CODES: { '52A000': 1.0 } },
  ['40101015']: { label: 'Regional Banks', BEA_CODES: { '52A000': 1.0 } },
  ['40201020']: {
    label: 'Other Diversified Financial Services',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['40201030']: {
    label: 'Multi-Sector Holdings',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['40201040']: { label: 'Specialized Finance', BEA_CODES: { '522A00': 1.0 } },
  ['40201050']: {
    label: 'Commercial & Residential Mortgage Finance',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['40201060']: {
    label: 'Transaction & Payment Processing Services',
    BEA_CODES: { '511200': 0.3904722, '522A00': 0.6095278 },
  },
  ['40202010']: { label: 'Consumer Finance', BEA_CODES: { '522A00': 1.0 } },
  ['40203010']: {
    label: 'Asset Management & Custody Banks',
    BEA_CODES: { '523900': 1.0 },
  },
  ['40203020']: {
    label: 'Investment Banking & Brokerage',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['40203030']: {
    label: 'Diversified Capital Markets',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['40203040']: {
    label: 'Financial Exchanges & Data',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['40204010']: { label: 'Mortgage REITs', BEA_CODES: { '525000': 1.0 } },
  ['40301010']: { label: 'Insurance Brokers', BEA_CODES: { '524200': 1.0 } },
  ['40301020']: {
    label: 'Life & Health Insurance',
    BEA_CODES: { '524113': 0.15705037, '5241XX': 0.84294963 },
  },
  ['40301030']: { label: 'Multi-line Insurance', BEA_CODES: { '5241XX': 1.0 } },
  ['40301040']: {
    label: 'Property & Casualty Insurance',
    BEA_CODES: { '5241XX': 1.0 },
  },
  ['40301050']: { label: 'Reinsurance', BEA_CODES: { '5241XX': 1.0 } },
  ['45102010']: {
    label: 'IT Consulting & Other Services',
    BEA_CODES: {
      '541511': 0.48499242,
      '541512': 0.34164378,
      '54151A': 0.1733638,
    },
  },
  ['45102030']: {
    label: 'Internet Services & Infrastructure',
    BEA_CODES: { '518200': 1.0 },
  },
  ['45103010']: { label: 'Application Software', BEA_CODES: { '511200': 1.0 } },
  ['45103020']: { label: 'Systems Software', BEA_CODES: { '511200': 1.0 } },
  ['45201020']: {
    label: 'Communications Equipment',
    BEA_CODES: {
      '334210': 0.22249948,
      '334220': 0.6671253,
      '334290': 0.11037522,
    },
  },
  ['45202030']: {
    label: 'Technology Hardware, Storage & Peripherals',
    BEA_CODES: {
      '334111': 0.29131751,
      '334112': 0.30540103,
      '334118': 0.40328146,
    },
  },
  ['45203010']: {
    label: 'Electronic Equipment & Instruments',
    BEA_CODES: {
      '334510': 0.19874451,
      '334511': 0.32775499,
      '334512': 0.01863118,
      '334513': 0.07511366,
      '334514': 0.04743265,
      '334515': 0.08914091,
      '334516': 0.09806865,
      '334517': 0.06186211,
      '33451A': 0.08325133,
    },
  },
  ['45203015']: {
    label: 'Electronic Components',
    BEA_CODES: {
      '334418': 0.37547721,
      '33441A': 0.55596227,
      '334610': 0.06856052,
    },
  },
  ['45203020']: {
    label: 'Electronic Manufacturing Services',
    BEA_CODES: { '33441A': 1.0 },
  },
  ['45203030']: {
    label: 'Technology Distributors',
    BEA_CODES: { '425000': 1.0 },
  },
  ['45301010']: {
    label: 'Semiconductor Equipment',
    BEA_CODES: { '333242': 1.0 },
  },
  ['45301020']: { label: 'Semiconductors', BEA_CODES: { '334413': 1.0 } },
  ['50101010']: { label: 'Alternative Carriers', BEA_CODES: { '517A00': 1.0 } },
  ['50101020']: {
    label: 'Integrated Telecommunication Services',
    BEA_CODES: { '517110': 1.0 },
  },
  ['50102010']: {
    label: 'Wireless Telecommunication Services',
    BEA_CODES: { '517210': 1.0 },
  },
  ['50201010']: { label: 'Advertising', BEA_CODES: { '541800': 1.0 } },
  ['50201020']: { label: 'Broadcasting', BEA_CODES: { '515100': 1.0 } },
  ['50201030']: { label: 'Cable & Satellite', BEA_CODES: { '515200': 1.0 } },
  ['50201040']: {
    label: 'Publishing',
    BEA_CODES: {
      '511110': 0.06574539,
      '511120': 0.08605246,
      '511130': 0.2344392,
      '5111A0': 0.08040021,
      '519130': 0.45736971,
      '5191A0': 0.07599304,
    },
  },
  ['50202010']: {
    label: 'Movies & Entertainment',
    BEA_CODES: { '512100': 0.87928881, '512200': 0.12071119 },
  },
  ['50202020']: {
    label: 'Interactive Home Entertainment',
    BEA_CODES: { '339930': 1.0 },
  },
  ['50203010']: {
    label: 'Interactive Media & Services',
    BEA_CODES: { '339930': 1.0 },
  },
  ['55101010']: { label: 'Electric Utilities', BEA_CODES: { '221100': 1.0 } },
  ['55102010']: { label: 'Gas Utilities', BEA_CODES: { '221200': 1.0 } },
  ['55103010']: {
    label: 'Multi-Utilities',
    BEA_CODES: { '221200': 0.57477644, '221300': 0.42522356 },
  },
  ['55104010']: { label: 'Water Utilities', BEA_CODES: { '221300': 1.0 } },
  ['55105010']: {
    label: 'Independent Power Producers & Energy Traders',
    BEA_CODES: { '221100': 1.0 },
  },
  ['55105020']: {
    label: 'Renewable Electricity',
    BEA_CODES: {
      '221111': 0.12216129032258063,
      '221113': 0.3490322580645161,
      '221114': 0.002908602150537634,
      '221115': 0.06108064516129032,
      '221116': 0.005817204301075268,
      '221121': 0.12,
      '221122': 0.339,
    },
  },
  ['60101010']: { label: 'Diversified REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60102510']: {
    label: 'Industrial REITs',
    BEA_CODES: { '493000': 0.11697375, '531ORE': 0.88302625 },
  },
  ['60103010']: { label: 'Hotel & Resort REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60104010']: { label: 'Office REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60105010']: { label: 'Health Care REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60106010']: {
    label: 'Multi-Family Residential REITs',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60106020']: {
    label: 'Single-Family Residential REITs',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60107010']: { label: 'Retail REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60108010']: {
    label: 'Other Specialized REITs',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60108020']: { label: 'Self Storage REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60108030']: { label: 'Telecom Tower REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60108040']: { label: 'Timber REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60108050']: { label: 'Data Center REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60201010']: {
    label: 'Diversified Real Estate Activities',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60201020']: {
    label: 'Real Estate Operating Companies',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60201030']: {
    label: 'Real Estate Development',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60201040']: { label: 'Real Estate Services', BEA_CODES: { '531ORE': 1.0 } },
};

export const HISTORICAL_GICS_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['20304020']: {
    label: '[Deprecated] Trucking',
    BEA_CODES: { '484000': 0.75644665, '532100': 0.24355335 },
  },
  ['25502010']: {
    label: '[Deprecated] Catalog Retail',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['25502020']: {
    label: '[Deprecated] Internet & Direct Marketing Retail',
    BEA_CODES: { '454000': 1.0 },
  },
  ['25503010']: {
    label: '[Deprecated] Department Stores',
    BEA_CODES: { '452000': 1.0 },
  },
  ['25503020']: {
    label: '[Deprecated] General Merchandise Stores',
    BEA_CODES: { '452000': 1.0 },
  },
  ['40102010']: {
    label: '[Deprecated] Thrifts & Mortgage Finance',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['45102020']: {
    label: '[Deprecated] Data Processing & Outsourced Services',
    BEA_CODES: { '518200': 1.0 },
  },
  ['60101080']: {
    label: '[Deprecated] Specialized REITs',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60102020']: {
    label: '[Deprecated] Real Estate Operating Companies',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60102030']: {
    label: '[Deprecated] Real Estate Development',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60102040']: {
    label: '[Deprecated] Real Estate Services',
    BEA_CODES: { '531ORE': 1.0 },
  },
};

export const GICS_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = { ...GICS_CODES_2023, ...HISTORICAL_GICS_CODES };
