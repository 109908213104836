import {
  ColumnValueFormatter,
  getFormatterForField,
} from './SupplierColumnFormatters';
import { Supplier, SupplierTableRow } from './supplierTypes';
import { parseSupplierFilterDimension } from './SupplierFilterUtils';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import assertNever from '@watershed/shared-util/assertNever';

export const SUPPLIER_DEFAULT_FIELD_ORDER = [
  'beaCodes',
  'supplierName',
  'priority',
  'emissionsRank',
  'targetsAndCommitments',
  'initiatives',
  'footprints',
  'totalEmissions',
  'percentEmissions',
  'emissiveSpend',
  'spend',
  'percentSpend',
  'surveyState',
  'engagementTasks',
  'topCategorizedEmission',
  'disclosures',
  'netZero',
  'carbonNeutral',
  'cleanEnergy',
  'sbtCommitmentStage',
  'engagementCohorts',
  'notes',
  'contacts',
  'latestDisclosureDate',
  'latestCdpDisclosurePublishingYear',
  'surveyPortalUrl',
  'ghgCategoryIds',
  'emissionsFactors',
  'targets',
  // Always keeps supplierDetailsUrl last.
  'supplierDetailsUrl',
] as const;

export type SupplierColumnName = (typeof SUPPLIER_DEFAULT_FIELD_ORDER)[number];

export type SupplierDefaultField =
  (typeof SUPPLIER_DEFAULT_FIELD_ORDER)[number];

type SupplierDefaultColumnSchema = {
  field: SupplierDefaultField;
  headerName: MessageDescriptor;
  defaultVisible: boolean;
  defaultWidth: number;
  getFormattedValue: ColumnValueFormatter<Supplier | SupplierTableRow>;
};

export const SUPPLIER_DEFAULT_COLUMN_SCHEMAS: {
  [key in SupplierDefaultField]: SupplierDefaultColumnSchema;
} = {
  beaCodes: {
    field: 'beaCodes',
    headerName: msg({
      message: 'Industries',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('beaCodes'),
  },
  supplierName: {
    field: 'supplierName',
    headerName: msg({ message: 'Supplier', context: 'Supplier table column' }),
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('supplierName'),
  },
  priority: {
    field: 'priority',
    headerName: msg({ message: 'Priority', context: 'Supplier table column' }),
    defaultVisible: true,
    defaultWidth: 120,
    getFormattedValue: getFormatterForField('priority'),
  },
  emissionsRank: {
    field: 'emissionsRank',
    headerName: msg({
      message: 'Rank by emissions',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 90,
    getFormattedValue: getFormatterForField('emissionsRank'),
  },
  totalEmissions: {
    field: 'totalEmissions',
    headerName: msg({
      message: 'Emissions',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 100,
    getFormattedValue: getFormatterForField('totalEmissions'),
  },
  spend: {
    field: 'spend',
    headerName: msg({ message: 'Spend', context: 'Supplier table column' }),
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('spend'),
  },
  percentSpend: {
    field: 'percentSpend',
    headerName: msg({
      message: '% of your spend',
      context: 'Supplier table column',
    }),
    defaultVisible: false,
    defaultWidth: 100,
    getFormattedValue: getFormatterForField('percentSpend'),
  },
  percentEmissions: {
    field: 'percentEmissions',
    headerName: msg({
      message: '% of your emissions',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 100,
    getFormattedValue: getFormatterForField('percentEmissions'),
  },
  topCategorizedEmission: {
    field: 'topCategorizedEmission',
    headerName: msg({
      message: 'Top categorized emission',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('topCategorizedEmission'),
  },
  disclosures: {
    field: 'disclosures',
    headerName: msg({
      message: 'Disclosures',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('disclosures'),
  },
  netZero: {
    field: 'netZero',
    headerName: msg({
      message: 'Net zero',
      context: 'Supplier table column',
    }),
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('netZero'),
  },
  carbonNeutral: {
    field: 'carbonNeutral',
    headerName: msg({
      message: 'Carbon neutral',
      context: 'Supplier table column',
    }),
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('carbonNeutral'),
  },
  cleanEnergy: {
    field: 'cleanEnergy',
    headerName: msg({
      message: 'Clean energy',
      context: 'Supplier table column',
    }),
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('cleanEnergy'),
  },
  sbtCommitmentStage: {
    field: 'sbtCommitmentStage',
    headerName: msg({
      message: 'SBTi status',
      context: 'Supplier table column',
    }),
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('sbtCommitmentStage'),
  },
  notes: {
    field: 'notes',
    headerName: msg({ message: 'Notes', context: 'Supplier table column' }),
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('notes'),
  },
  engagementCohorts: {
    field: 'engagementCohorts',
    headerName: msg({
      message: 'Cohorts',
      context: 'Supply chain cohorts',
    }),
    defaultVisible: true,
    defaultWidth: 130,
    getFormattedValue: getFormatterForField('engagementCohorts'),
  },
  contacts: {
    field: 'contacts',
    headerName: msg({ message: 'Contacts', context: 'Supplier table column' }),
    defaultVisible: false,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('contacts'),
  },
  latestDisclosureDate: {
    field: 'latestDisclosureDate',
    headerName: msg({
      message: 'Latest disclosure date',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('latestDisclosureDate'),
  },
  latestCdpDisclosurePublishingYear: {
    field: 'latestCdpDisclosurePublishingYear',
    headerName: msg({
      message: 'Latest CDP publishing year',
      context: 'Supplier table column',
    }),
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField(
      'latestCdpDisclosurePublishingYear'
    ),
  },
  surveyState: {
    field: 'surveyState',
    headerName: msg({
      message: 'Status',
      context: 'Supplier survey status',
    }),
    defaultVisible: false,
    defaultWidth: 175,
    getFormattedValue: getFormatterForField('surveyState'),
  },
  engagementTasks: {
    field: 'engagementTasks',
    headerName: msg({
      message: 'Tasks',
      context: 'Supplier engagement tasks',
    }),
    defaultVisible: false,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('engagementTasks'),
  },
  surveyPortalUrl: {
    field: 'surveyPortalUrl',
    headerName: msg({
      message: 'Survey portal',
      context: 'Supplier survey portal URL',
    }),
    defaultVisible: false,
    defaultWidth: 130,
    getFormattedValue: getFormatterForField('surveyPortalUrl'),
  },
  ghgCategoryIds: {
    field: 'ghgCategoryIds',
    headerName: msg({
      message: 'GHG categories',
      context: 'GHG protocol categories',
    }),
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('ghgCategoryIds'),
  },
  targets: {
    field: 'targets',
    headerName: msg({
      message: 'Targets',
      context: 'Supplier reduction initiative targets',
    }),
    defaultVisible: true,
    defaultWidth: 250,
    getFormattedValue: getFormatterForField('targets'),
  },
  targetsAndCommitments: {
    field: 'targetsAndCommitments',
    headerName: msg({
      message: 'Targets',
      context: 'Supplier reduction initiative targets',
    }),
    defaultVisible: true,
    defaultWidth: 250,
    getFormattedValue: getFormatterForField('targetsAndCommitments'),
  },
  supplierDetailsUrl: {
    field: 'supplierDetailsUrl',
    headerName: msg({
      message: 'View details',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 130,
    getFormattedValue: getFormatterForField('supplierDetailsUrl'),
  },
  emissionsFactors: {
    field: 'emissionsFactors',
    headerName: msg({
      message: 'Emissions factors',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 130,
    getFormattedValue: getFormatterForField('emissionsFactors'),
  },
  emissiveSpend: {
    field: 'emissiveSpend',
    headerName: msg({
      message: 'Emissive spend',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('emissiveSpend'),
  },
  initiatives: {
    field: 'initiatives',
    headerName: msg({
      message: 'Initiatives',
      context: 'Supplier reduction initiatives',
    }),
    defaultVisible: true,
    defaultWidth: 250,
    getFormattedValue: getFormatterForField('initiatives'),
  },
  footprints: {
    field: 'footprints',
    headerName: msg({
      message: 'Reported footprints',
      context: 'Supplier table column',
    }),
    defaultVisible: true,
    defaultWidth: 250,
    getFormattedValue: getFormatterForField('footprints'),
  },
};

export function isDefaultField(field: string): field is SupplierDefaultField {
  const { dimension } = parseSupplierFilterDimension(field);
  return dimension in SUPPLIER_DEFAULT_COLUMN_SCHEMAS;
}

export function isDefaultFieldUserAccessible(
  field: SupplierDefaultField,
  {
    supplierEngagementSurveysEnabled,
  }: {
    supplierEngagementSurveysEnabled: boolean;
  }
): boolean {
  switch (field) {
    case 'supplierName':
    case 'priority':
    case 'emissionsRank':
    case 'totalEmissions':
    case 'percentSpend':
    case 'spend':
    case 'percentEmissions':
    case 'topCategorizedEmission':
    case 'disclosures':
    case 'netZero':
    case 'carbonNeutral':
    case 'sbtCommitmentStage':
    case 'notes':
    case 'engagementCohorts':
    case 'contacts':
    case 'latestDisclosureDate':
    case 'latestCdpDisclosurePublishingYear':
    case 'ghgCategoryIds':
      return true;
    case 'supplierDetailsUrl':
    case 'emissionsFactors': // TODO(SC): Fix the data in this column
      return false;
    case 'cleanEnergy':
    case 'targets':
      return false;
    case 'targetsAndCommitments':
    case 'beaCodes':
    case 'initiatives':
    case 'footprints':
      return true;
    // TODO SUP-7468: Remove surveyState logic
    case 'surveyState':
      return false;
    case 'engagementTasks':
    case 'surveyPortalUrl':
      return supplierEngagementSurveysEnabled;
    case 'emissiveSpend':
      return true;
    default:
      assertNever(field);
  }
}
