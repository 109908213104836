import { GQPermissionType } from '../generated/graphql';
import uniq from 'lodash/uniq';
import { PermissionType } from './permissionUtils';
import { getObjectKeys } from '../getObjectKeys';

export const PERMISSION_PARENTS: {
  [key in GQPermissionType]?: Array<GQPermissionType>;
} = {
  // Admin magicsauce
  [GQPermissionType.Admin]: [GQPermissionType.WatershedAdmin],
  [GQPermissionType.CorporateAdmin]: [GQPermissionType.Admin],
  [GQPermissionType.ManageMarketplacePurchases]: [
    GQPermissionType.CorporateAdmin,
  ],
  [GQPermissionType.ManageCompanyTags]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageOrgHierarchy]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ViewEmployeeReport]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageSingleSignOn]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageMeasurement]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageReductionPlans]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageSuppliers]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageDisclosures]: [GQPermissionType.CorporateAdmin],

  // Manage/View and other hierarchies
  [GQPermissionType.ViewFootprintDetail]: [
    GQPermissionType.ViewReductions,
    GQPermissionType.ManageMeasurement,
    // Note: This doesn't seem right but we don't want to hit permissions
    // landmines with Finance Reporting
    GQPermissionType.FinanceAdmin,
  ],
  [GQPermissionType.ViewAuditDetail]: [GQPermissionType.ViewFootprintDetail],
  [GQPermissionType.ViewReductions]: [GQPermissionType.ManageReductionPlans],
  [GQPermissionType.ManageDataset]: [GQPermissionType.ManageMeasurement],
  [GQPermissionType.ManageDatasource]: [GQPermissionType.ManageDataset],
  [GQPermissionType.ApproveDataset]: [GQPermissionType.ManageMeasurement],
  [GQPermissionType.ApproveDatasource]: [GQPermissionType.ApproveDataset],
  [GQPermissionType.EditReport]: [GQPermissionType.ViewFootprintDetail],
  [GQPermissionType.ViewReport]: [GQPermissionType.EditReport],
  [GQPermissionType.EditReportQuestionInstance]: [
    GQPermissionType.EditReport,
    GQPermissionType.ViewFootprintDetail,
  ],
  [GQPermissionType.ViewReportQuestionInstance]: [
    GQPermissionType.ViewReport,
    GQPermissionType.EditReportQuestionInstance,
  ],

  // Finance
  [GQPermissionType.FinanceAdmin]: [GQPermissionType.Admin],
  [GQPermissionType.ManageFund]: [GQPermissionType.FinanceAdmin],
  [GQPermissionType.FinanceReadOnly]: [GQPermissionType.ManageFund],

  // Learning Hub
  // This is unique permission in that the learning hub is not actually gated by
  // any permission, but we want to allow orgs to create a user that can _exclusively_
  // access learning hub.

  // It's only part of the permission hierarchy so that admin users can grant it to others.
  [GQPermissionType.ViewLearningHub]: [
    GQPermissionType.CorporateAdmin,
    GQPermissionType.FinanceAdmin,
  ],
};

function _getPermissionsHierarchy(
  permission: GQPermissionType
): Array<GQPermissionType> {
  return uniq(
    [
      permission,
      ...(PERMISSION_PARENTS[permission] || []).map((parent) =>
        _getPermissionsHierarchy(parent)
      ),
    ].flat()
  );
}

const ANCESTORS_FOR_PERMISSION = Object.fromEntries(
  getObjectKeys(GQPermissionType).map((permission) => [
    permission,
    _getPermissionsHierarchy(permission),
  ])
);

export function getPermissionsHierarchy(
  permission: GQPermissionType
): Array<GQPermissionType> {
  return ANCESTORS_FOR_PERMISSION[permission];
}

export const HIERARCHY_LENGTHS_FOR_PERMISSION = Object.fromEntries(
  getObjectKeys(GQPermissionType).map((permission) => [
    permission,
    getPermissionsHierarchy(permission).length,
  ])
);

export type SourceObjectWithPermissionDelegate = {
  id: string;
  permissionDelegateId?: string | null;
};

export interface PermissionOrigin {
  permission: string;
  objectId?: string | null;
  objectName?: string | null;
}

type PermissionChecker = (params: {
  permission: string;
  objectId?: string | null;
  source?: SourceObjectWithPermissionDelegate | null;
  allowAnyObject?: boolean;
  allowPermission: PermissionType;
}) => PermissionOrigin | null;

const PERMISSION_ORIGIN_CHECKERS = Object.fromEntries(
  getObjectKeys(GQPermissionType).map((permission) => {
    const permissionsToCheck = getPermissionsHierarchy(permission);

    // Create an optimized origin checker function for this permission
    const checker: PermissionChecker = ({
      permission,
      objectId,
      source,
      allowAnyObject,
    }) => {
      for (const checkPermission of permissionsToCheck) {
        if (checkPermission === permission) {
          if (!objectId) {
            return { permission: checkPermission };
          }
          if (allowAnyObject) {
            return { permission: checkPermission };
          }
          if (
            objectId === source?.id ||
            objectId === source?.permissionDelegateId
          ) {
            return { permission: checkPermission, objectId };
          }
        }
      }
      return null;
    };

    return [permission, checker];
  })
);

export function getPermissionOrigin({
  permission,
  objectId,
  source,
  allowPermission,
  allowAnyObject,
}: {
  // This is the permission that the user has
  permission: string;

  // This is the permission to check
  allowPermission: PermissionType;

  // This is the objectId associated with the permision that the user has
  objectId?: string | null;

  // This is the object being tested
  source?: SourceObjectWithPermissionDelegate | null;

  // If true, we don't check the object ID (useful for things like determining if a user can access a page)
  allowAnyObject?: boolean;
}): PermissionOrigin | null {
  const checker = PERMISSION_ORIGIN_CHECKERS[allowPermission];
  return checker({
    permission,
    objectId,
    source,
    allowAnyObject,
    allowPermission,
  });
}

/**
 * Sorts permissions so that the one with the shortest hierarchy is first. If
 * two permissions have the same hierarchy length, then the one without an
 * objectId is prioritized.
 *
 * @param permissions an array of permissions to sort
 * @returns a new array of permissions sorted by the shortest hierarchy
 */
export function sortPermissionsByShortestHierarchy<
  T extends {
    permission: GQPermissionType;
    objectId?: string | null;
  },
>(permissions: Array<T>): Array<T> {
  return permissions.toSorted((a, b) => {
    const aLength =
      HIERARCHY_LENGTHS_FOR_PERMISSION[a.permission] + (!!a.objectId ? 1 : 0);
    const bLength =
      HIERARCHY_LENGTHS_FOR_PERMISSION[b.permission] + (!!b.objectId ? 1 : 0);

    return aLength - bLength;
  });
}

export function getUserOrRolePermissionOrigin({
  permissions,
  allowPermission,
  source,
}: {
  // Permissions that a user or a role has
  permissions: Array<{
    permission: GQPermissionType;
    objectId?: string | null;
  }>;

  // This is the permission being tested
  allowPermission: GQPermissionType;

  // This is the object being tested
  source?: SourceObjectWithPermissionDelegate | null;
}): PermissionOrigin | null {
  for (const p of sortPermissionsByShortestHierarchy(permissions)) {
    const origin = getPermissionOrigin({
      permission: p.permission,
      objectId: p.objectId,
      allowPermission,
      source,
    });
    if (origin) return origin;
  }
  return null;
}
