import HomeIcon from '@watershed/icons/components/Home';
import {
  routeForFinanceOverview,
  routeForFund,
  routeForFinanceMeasurement,
  routeForFundGroup,
  routeForBenchmarks,
  routeForFinanceView,
  routeForFundCategory,
  routeForFormalReporting,
  routeForFinanceYearOverYear,
  routeForFinanceDueDiligence,
} from '@watershed/shared-universal/dashboardRoutes';
import {
  GQActiveOrganizationFundFragment,
  GQActiveOrganizationSavedViewFragment,
  GQFinanceSnapshotForContextFragment,
  GQFlags,
  GQPermissionType,
} from '@watershed/shared-universal/generated/graphql';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { NavSectionItem, NavSectionData, SideBarVariant } from '../utils';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { ParsedUrlQuery } from 'querystring';
import omit from 'lodash/omit';
import SetSquareIcon from '@watershed/icons/components/SetSquare';
import {
  HAS_ACCESS_TO_BENCHMARKS,
  checkAccessChecks,
} from '../../../utils/featureAccess';
import { useUserContext, UserContextProps } from '../../../utils/UserContext';
import {
  FeatureFlagsMap,
  useAllFeatureFlags,
  useFeatureFlag,
} from '../../../utils/FeatureFlag';
import { BENCHMARKS_TITLE } from '@watershed/shared-frontend/utils/benchmarks';
import { makeNavItemLocationFromRoute } from './SideBarStandardVariant';
import SimulateIcon from '@watershed/icons/components/Simulate';
import ReportIcon from '@watershed/icons/components/Report';
import JudicialScalesIcon from '@watershed/icons/components/JudicialScales';
import GavelIcon from '@watershed/icons/components/Gavel';
import NavSection from '../NavSection';
import DatabaseIcon from '@watershed/icons/components/Database';
import {
  routeForFinanceDataExplorer,
  routeForFinanceDataExplorerOverview,
} from '@watershed/shared-universal/dashboardRoutes';
import { useLatestFinanceSnapshotWithFootprint } from '../../../utils/finance/FinanceSnapshotsContext';
import useHasPermission from '../../../utils/useHasPermission';

/**
 * This is a rare moment where we strip out a bunch of known query params
 *
 * Selecting Overview from the sidebar is a signal to clear these query params
 * but not necessarily financeSnapshotId or low level filters
 */
const ROUTE_LEVEL_FILTERS = [
  'dataFormat',
  'fundId',
  'fundGroup',
  'fundCategory',
  'assetId',
  'viewId',
  'activeTab',
  'model',
  'category',
  'title',
];

export default function useSidebarFinanceVariant({
  funds,
  views,
  toggleCreateFinanceSavedView,
}: {
  funds: ReadonlyArray<GQActiveOrganizationFundFragment>;
  views: ReadonlyArray<GQActiveOrganizationSavedViewFragment>;
  toggleCreateFinanceSavedView: () => void;
}): SideBarVariant {
  const userContext = useUserContext();
  const featureFlags = useAllFeatureFlags();

  const hasBiV2 = featureFlags.get(GQFlags.AssetManagerBiV2Query);
  const canAccessDataExplorer = useHasPermission(
    GQPermissionType.ManageFund,
    // If BiV2 is enabled, we have fund-level filtering in data
    // explorer
    { allowAnyObject: hasBiV2 }
  );
  const hasFinanceAdminPermissions = useHasPermission(
    GQPermissionType.FinanceAdmin
  );

  const router = useRouter();
  const { query } = router;
  const getAdditionalNavEntryData = useAdditionalNavEntryData();
  const latestSnapshotWithFootprint = useLatestFinanceSnapshotWithFootprint();
  return useMemo(() => {
    const navEntries = generateNavEntries({
      userContext,
      canAccessDataExplorer,
      hasFinanceAdminPermissions,
      featureFlags,
      query,
      latestSnapshotWithFootprint,
    });
    const additionalNavEntryData = getAdditionalNavEntryData(
      funds,
      views,
      toggleCreateFinanceSavedView
    );
    return {
      navEntries,
      ...additionalNavEntryData,
    };
  }, [
    userContext,
    canAccessDataExplorer,
    hasFinanceAdminPermissions,
    featureFlags,
    views,
    funds,
    query,
    toggleCreateFinanceSavedView,
    getAdditionalNavEntryData,
    latestSnapshotWithFootprint,
  ]);
}

function useAdditionalNavEntryData(): (
  funds: ReadonlyArray<GQActiveOrganizationFundFragment>,
  views: ReadonlyArray<GQActiveOrganizationSavedViewFragment>,
  toggleCreateFinanceSavedView: () => void
) => {
  syntheticNavEntries: Array<NavSectionData>;
  contentAfterNavEntries: (props: {
    appearsCollapsed: boolean;
    activeEntry: NavSectionItem | null;
  }) => JSX.Element;
} {
  const router = useRouter();
  const { query } = router;
  const shouldCollapseFundCategories = useFeatureFlag(
    GQFlags.AssetManagerSidebarCanCollapse
  );
  const shouldRenameFund = useFeatureFlag(GQFlags.AssetManagerRenameFunds);
  const hasHideOverviewAndCustomViews = useFeatureFlag(
    GQFlags.AssetManagerHideOverviewAndCustomViews
  );

  return useCallback(
    (funds, views, toggleCreateFinanceSavedView) => {
      // The easiest thing to do was to just keep these as "nav entries", but now
      // that we have the ability to render arbitrary JSX in the sidebar, we could
      // structure this differently.
      const navEntries = hasHideOverviewAndCustomViews
        ? []
        : [
            ...getViewItems(query, views, toggleCreateFinanceSavedView),
            ...getFundHierarchy(query, funds, {
              shouldCollapseFundCategories,
              shouldRenameFund,
            }),
          ];
      return {
        syntheticNavEntries: navEntries,
        contentAfterNavEntries: ({ appearsCollapsed, activeEntry }) => (
          <>
            {navEntries.map((section, i) => (
              <NavSection
                key={i}
                section={section}
                appearsCollapsed={appearsCollapsed}
                activeEntry={activeEntry}
              />
            ))}
          </>
        ),
      };
    },
    [
      query,
      shouldCollapseFundCategories,
      shouldRenameFund,
      hasHideOverviewAndCustomViews,
    ]
  );
}

function generateNavEntries({
  userContext,
  canAccessDataExplorer,
  hasFinanceAdminPermissions,
  featureFlags,
  query,
  latestSnapshotWithFootprint,
}: {
  userContext: UserContextProps;
  canAccessDataExplorer: boolean;
  hasFinanceAdminPermissions: boolean;
  featureFlags: FeatureFlagsMap;
  query: ParsedUrlQuery;
  latestSnapshotWithFootprint?: GQFinanceSnapshotForContextFragment;
}) {
  // TODO: Remove this once we have a better way to check access
  const benchmarksAccessCheck = checkAccessChecks(
    HAS_ACCESS_TO_BENCHMARKS,
    userContext,
    featureFlags
  );
  const hasHideOverviewAndCustomViews = featureFlags.get(
    GQFlags.AssetManagerHideOverviewAndCustomViews
  );
  const yearOverYear = featureFlags.get(
    GQFlags.AssetManagerYearOverYearComparison
  );
  const hasDueDiligence = featureFlags.get(GQFlags.AssetManagerDueDiligence);
  const hasDataExplorer =
    latestSnapshotWithFootprint &&
    featureFlags.get(GQFlags.AssetManagerDataExplorer);
  const hasSavedViewsOnDataExplorer = featureFlags.get(
    GQFlags.AssetManagerSavedViewsOnDataExplorer
  );

  const dataManagementNavSection = {
    name: 'Data management',
    location: routeForFinanceMeasurement(omit(query, ROUTE_LEVEL_FILTERS)),
    dataTest: TestIds.FinanceMeasurementSidebarItem,
    icon: SetSquareIcon,
    hasPermission: true,
  };

  const navSections: Array<NavSectionData> = [
    {
      subentries: [
        hasHideOverviewAndCustomViews
          ? dataManagementNavSection
          : {
              name: 'Overview',
              location: routeForFinanceOverview(
                omit(query, ROUTE_LEVEL_FILTERS)
              ),
              icon: HomeIcon,
              hasPermission: true,
            },
        yearOverYear
          ? {
              name: 'Comparison',
              location: routeForFinanceYearOverYear(
                omit(query, ROUTE_LEVEL_FILTERS)
              ),
              icon: JudicialScalesIcon,
              hasPermission: true,
            }
          : null,
        benchmarksAccessCheck.hasAccess
          ? {
              name: BENCHMARKS_TITLE,
              location: makeNavItemLocationFromRoute(routeForBenchmarks()),
              icon: SimulateIcon,
              hasPermission: true,
            }
          : null,
        // If Overview is hidden, we show this section at the top instead.
        hasHideOverviewAndCustomViews ? null : dataManagementNavSection,
        hasDataExplorer
          ? {
              name: 'Data explorer',
              location: hasSavedViewsOnDataExplorer
                ? { pathname: routeForFinanceDataExplorerOverview() }
                : routeForFinanceDataExplorer(omit(query, ROUTE_LEVEL_FILTERS)),
              icon: DatabaseIcon,
              hasPermission: canAccessDataExplorer,
            }
          : null,
        {
          name: 'Reporting',
          location: {
            pathname: routeForFormalReporting(),
          },
          icon: ReportIcon,
          hasPermission: hasFinanceAdminPermissions,
        },
        hasDueDiligence
          ? {
              name: 'Due diligence',
              location: routeForFinanceDueDiligence(
                omit(query, ROUTE_LEVEL_FILTERS)
              ),
              icon: GavelIcon,
              hasPermission: true,
            }
          : null,
      ].filter(isNotNullish),
    },
  ];

  return navSections;
}

function getViewItems(
  query: ParsedUrlQuery,
  views: ReadonlyArray<GQActiveOrganizationSavedViewFragment>,
  toggleCreateFinanceSavedView: () => void
): Array<NavSectionData> {
  return [
    {
      name: 'Views',

      // TODO: Handle add view
      onAdd: toggleCreateFinanceSavedView,
      onAddDataTest: TestIds.FinanceCreateSavedViewButton,

      subentries:
        views.length > 0
          ? sortBy(views, (view) => view.name).map((view) => ({
              name: view.name,
              location: routeForFinanceView(
                view.id,
                omit(query, ROUTE_LEVEL_FILTERS)
              ),
              hasPermission: true,
              tooltip: view.name.length > 22 ? view.name : undefined,
            }))
          : [
              {
                name: 'No views yet',
                location: { pathname: '#' },
                hasPermission: true,
                subentries: [],
                disabled: true,
              },
            ],
    },
  ];
}

/**
 * You are a tricky one. We have three possible levels of hierarchy,
 * and we are trying to sift through these as simply as possible.
 */
function getFundHierarchy(
  query: ParsedUrlQuery,
  funds: ReadonlyArray<GQActiveOrganizationFundFragment>,
  options: { shouldCollapseFundCategories?: boolean; shouldRenameFund: boolean }
): Array<NavSectionData> {
  if (!funds || funds.length === 0) {
    return [];
  }
  const { shouldRenameFund } = options;

  let items: Array<NavSectionItem> = [];

  const fundCategories = uniq(
    funds.map((fund) => fund.fundCategory).filter(isNotNullish)
  );
  if (fundCategories.length) {
    items = getFundCategoryItems(fundCategories, funds, query, options);
  } else {
    const fundGroups = uniq(
      funds.map((fund) => fund.fundGroup).filter(isNotNullish)
    );
    if (fundGroups.length) {
      items = getFundGroupItems(null, fundGroups, funds, query, {
        shouldRenameFund,
      });
    } else {
      items = getFundItems(funds, query, { shouldRenameFund });
    }
  }

  return [
    {
      name: shouldRenameFund ? 'Lines of business' : 'Funds',
      subentries: items,
      disabled: true,
    },
  ];
}

function getFundCategoryItems(
  fundCategories: Array<string>,
  funds: ReadonlyArray<GQActiveOrganizationFundFragment>,
  query: ParsedUrlQuery,
  options: { shouldCollapseFundCategories?: boolean; shouldRenameFund: boolean }
) {
  const { shouldRenameFund } = options;
  const collapsed = options.shouldCollapseFundCategories || undefined;
  const items = fundCategories.map((category) => {
    const fundGroupsInCategory = uniq(
      funds
        .filter((fund) => fund.fundCategory === category)
        .map((fund) => fund.fundGroup)
        .filter(isNotNullish)
    );
    const subentries = fundGroupsInCategory.length
      ? getFundGroupItems(
          category,
          fundGroupsInCategory,
          funds.filter((fund) => fund.fundCategory === category),
          query,
          { shouldRenameFund }
        )
      : getFundItems(
          funds.filter((fund) => fund.fundCategory === category),
          query,
          { shouldRenameFund }
        );
    return {
      name: category,
      location: routeForFundCategory(
        category,
        omit(query, ROUTE_LEVEL_FILTERS)
      ),
      collapsed,
      hasPermission: true,
      subentries,
    };
  });
  const fundsWithoutCategory = funds.filter((fund) => !fund.fundCategory);
  const fundGroupsWithoutCategory = uniq(
    fundsWithoutCategory.map((fund) => fund.fundGroup).filter(isNotNullish)
  );

  if (fundGroupsWithoutCategory.length) {
    items.push({
      name: 'Other',
      location: routeForFundCategory('Other', omit(query, ROUTE_LEVEL_FILTERS)),
      hasPermission: true,
      collapsed,
      subentries: getFundGroupItems(
        'Other',
        fundGroupsWithoutCategory,
        funds,
        query,
        { shouldRenameFund }
      ),
    });
  } else if (fundsWithoutCategory.length) {
    items.push({
      name: 'Other',
      location: routeForFundCategory('Other', omit(query, ROUTE_LEVEL_FILTERS)),
      hasPermission: true,
      collapsed,
      subentries: getFundItems(fundsWithoutCategory, query, {
        shouldRenameFund,
      }),
    });
  }
  return items;
}

function getFundGroupItems(
  category: string | null,
  fundGroups: Array<string>,
  funds: ReadonlyArray<GQActiveOrganizationFundFragment>,
  query: ParsedUrlQuery,
  { shouldRenameFund }: { shouldRenameFund: boolean }
) {
  const items = fundGroups.map((group) => {
    return {
      name: group,
      location: routeForFundGroup(
        group,
        { fundCategory: category },
        omit(query, ROUTE_LEVEL_FILTERS)
      ),
      hasPermission: true,
      subentries: getFundItems(
        funds.filter(
          (fund) =>
            fund.fundGroup === group &&
            (fund.fundCategory === category ||
              (fund.fundCategory === null && category === 'Other'))
        ),
        query,
        { shouldRenameFund }
      ),
    };
  });

  const fundsWithoutGroup = funds.filter((fund) => !fund.fundGroup);
  if (fundsWithoutGroup.length) {
    items.push({
      name: 'Other',
      location: routeForFundGroup('Other', { fundCategory: category }, query),
      hasPermission: true,
      subentries: getFundItems(fundsWithoutGroup, query, { shouldRenameFund }),
    });
  }
  return items;
}

function getFundItems(
  funds: ReadonlyArray<GQActiveOrganizationFundFragment>,
  query: ParsedUrlQuery,
  { shouldRenameFund }: { shouldRenameFund: boolean }
) {
  return sortBy(
    funds,
    (fund) =>
      `${fund.excludeAsSandbox ? 'b' : 'a'} ${fund.nameSortable ?? fund.name}`
  ).map((fund) => ({
    name: fund.name,
    location: routeForFund(fund.id, omit(query, ROUTE_LEVEL_FILTERS)),
    hasPermission: true,
    tooltip: fund.name.length > 22 ? fund.name : undefined,
    chip: fund.excludeAsSandbox ? 'Excluded' : undefined,
    chipTooltip: fund.excludeAsSandbox
      ? shouldRenameFund
        ? 'Financed emissions from excluded lines of business will not be aggregated in the Overview.'
        : 'Financed emissions from excluded funds will not be aggregated in the Overview.'
      : undefined,
    subentries: [],
    isNavItem: true,
  }));
}
