import {
  LAUNCHED_LOCALES,
  SUPPORTED_LOCALES,
  SupportedLocale,
} from '@watershed/intl/constants';
import { useUserContext } from './UserContext';
import { GQFlags } from '@watershed/shared-universal/generated/graphql';
import { useFeatureFlag } from './FeatureFlag';

export function useAvailableLocales(): Array<SupportedLocale> {
  const userContext = useUserContext();
  const showAllLanguages = useFeatureFlag(GQFlags.ShowAllLanguages);
  return userContext.userIsWatershedEmployee ||
    userContext.userIsWatershedContractor ||
    showAllLanguages
    ? SUPPORTED_LOCALES
    : LAUNCHED_LOCALES;
}
