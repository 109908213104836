import { useState } from 'react';
import { Trans } from '@lingui/react/macro';
import Button from '@watershed/ui-core/components/Button';
import { GQSupportCaseIssueType } from '@watershed/shared-universal/generated/graphql';
import { Box, Chip, Menu, MenuItem, useTheme } from '@mui/material';
import { useSupportCaseNotificationCountQuery } from '@watershed/shared-frontend/generated/urql';
import { getSharedSidebarStyles } from '../navigation/variants/sharedSidebarStyles';
import { OpenDiscussionCount } from './OpenDiscussionCount';
import {
  routeForLearningHub,
  routeForSupport,
} from '@watershed/shared-universal/dashboardRoutes';
import UnstyledLink from '@watershed/ui-core/components/UnstyledLink';
import HelpIcon from '@watershed/icons/components/Help';
import LightBulbIcon from '@watershed/icons/components/LightBulb';
import ConversationIcon from '@watershed/icons/components/Conversation';
import { useRouter } from 'next/router';
import QuestionMarkIcon from '@watershed/icons/components/QuestionMark';
import { ErrorBoundary } from 'react-error-boundary';
import { maybeNotifySentry } from '@watershed/shared-frontend/utils/errorUtils';
import { useSupportCaseDialog } from './NewSupportCaseDialog';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

export default function NavHelpButton({
  isOnFinancePage,
  appearsCollapsed,
}: {
  isOnFinancePage: boolean;
  appearsCollapsed: boolean;
}) {
  const router = useRouter();
  const [supportCaseResult] = useSupportCaseNotificationCountQuery({
    variables: {
      issueType: null,
      objectId: null,
    },
  });
  const openDiscussionCount =
    supportCaseResult.data?.supportCaseNotificationCount ?? 0;

  const [helpAnchorEl, setHelpAnchorEl] = useState<null | HTMLButtonElement>(
    null
  );

  const sharedSidebarStyles = getSharedSidebarStyles(useTheme());
  const { COLORS, BOX_SHADOW_FOCUS } = sharedSidebarStyles;
  const openSupportCaseDialog = useSupportCaseDialog();

  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error) => maybeNotifySentry(error)}
    >
      <Button
        variant="text"
        startIcon={<HelpIcon />}
        aria-controls="help-menu"
        aria-haspopup="true"
        onClick={(e) => setHelpAnchorEl(e.currentTarget)}
        sx={{
          minWidth: 'unset',
          width: '100%',
          height: '32px',
          justifyContent: 'flex-start',
          textAlign: 'left',
          color: COLORS.TEXT_PRIMARY,
          borderRadius: 1,
          paddingInline: 1, // Match alignment of gear icon
          paddingBlock: 1,
          gap: 1,

          '&:hover, &:focus': {
            backgroundColor: COLORS.BACKGROUND_ACTIVE,
          },
          '&:focus-visible': {
            boxShadow: `inset ${BOX_SHADOW_FOCUS}`,
          },
          '&[aria-current="page"]': {
            color: COLORS.TEXT_PRIMARY,
            backgroundColor: COLORS.BACKGROUND_ACTIVE,
          },
          '& .MuiButton-startIcon': {
            margin: 0,
            '& svg': {
              width: 16,
              height: 16,
            },
          },
        }}
        aria-current={helpAnchorEl ? 'page' : undefined}
        data-testid={TestIds.NavHelpButton}
      >
        <Box
          component="span"
          sx={{
            '[data-appears-collapsed="true"] &': {
              display: 'none',
            },
          }}
        >
          <Trans context="Navbar item that opens Learning Hub/Support menu">
            Help
          </Trans>
        </Box>
        {openDiscussionCount ? (
          <Chip
            sx={{ marginLeft: 'auto' }}
            size="small"
            label={openDiscussionCount}
          />
        ) : null}
      </Button>
      <Menu
        id="help-menu"
        anchorEl={helpAnchorEl}
        keepMounted
        open={Boolean(helpAnchorEl)}
        onClose={() => setHelpAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: sharedSidebarStyles.menuPaperSxProps,
          'data-appears-collapsed': appearsCollapsed,
        }}
        MenuListProps={{
          component: 'div',
        }}
      >
        <MenuItem
          id="new-support-case"
          key="new-support-case"
          component="button"
          sx={{
            width: 1,

            // This little weirdo is holding onto a focus state in a way that
            // just doesn't make sense, and I cannot figure out why.
            // Sometimes you just need a little more CSS violence
            backgroundColor: 'transparent !important',
            '&:hover': {
              backgroundColor: `${COLORS.BACKGROUND_ACTIVE} !important`,
            },
          }}
          onClick={() => {
            setHelpAnchorEl(null);
            openSupportCaseDialog({
              issueType: GQSupportCaseIssueType.GettingStarted,
            });
          }}
          data-testid={TestIds.SupportNewCaseMenuItem}
        >
          <QuestionMarkIcon />
          <Trans context="Menu item copy to open a support case dialog">
            Ask a question…
          </Trans>
        </MenuItem>

        <MenuItem
          key="support-center"
          component="button"
          sx={{
            width: 1,
          }}
          onClick={() => {
            setHelpAnchorEl(null);
            void router.push(routeForSupport());
          }}
          data-testid={TestIds.SupportCenterMenuItem}
        >
          <ConversationIcon />
          <OpenDiscussionCount
            count={openDiscussionCount}
            label={
              <Trans context="Navbar item for Support">Support center</Trans>
            }
          />
        </MenuItem>

        <UnstyledLink
          href={routeForLearningHub({
            from: 'Sidebar',
            viewMode: isOnFinancePage ? 'finance' : 'regular',
          })}
        >
          <MenuItem
            component="a"
            onClick={() => {
              setHelpAnchorEl(null);
            }}
          >
            <LightBulbIcon />
            <Trans context="Navbar item for Learning Hub">Learning hub</Trans>
          </MenuItem>
        </UnstyledLink>
      </Menu>
    </ErrorBoundary>
  );
}
