import React, { useEffect, useState } from 'react';
import invariant from 'invariant';
import {
  GQActiveOrganizationFieldsFragment,
  GQFlagsInitFieldsFragment,
  GQActiveOrganizationQuery,
  GQForceRedirectQuery,
} from '@watershed/shared-universal/generated/graphql';
import {
  useActiveOrganizationQuery,
  useForceRedirectQuery,
} from '@watershed/shared-frontend/generated/urql';
import LoadingPage from '@watershed/shared-frontend/components/LoadingPage';
import SharedAppContainer from '../components/SharedAppContainer';
import useGlobalLocation from '@watershed/ui-core/hooks/useGlobalLocation';
import { routeForLogin } from '@watershed/shared-universal/dashboardRoutes';
import isFetchingOrPartial from '@watershed/shared-frontend/utils/isFetchingOrPartial';
import type { UseQueryState } from 'urql';
import Redirect from '@watershed/shared-frontend/components/Redirect';
import FailedToContactApiDevelopmentErrorBox from '@watershed/shared-frontend/components/FailedToContactApiDevelopmentErrorBox';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';
import {
  switchPinnedOrganizationId,
  clearPinnedOrganizationId,
  getPinnedOrganizationId,
  initPinnedOrganizationId,
} from '@watershed/shared-frontend/utils/pinnedOrganizationId';
import { ORG_ID_PARAM } from '@watershed/shared-universal/utils/pinnedOrganizationUniversalConstants';
import { ViewerNoPermissionPage } from '../components/NotFoundPage';
import { useRouter } from 'next/router';
import { getGqlResultDataBang } from '@watershed/shared-frontend/utils/errorUtils';

type ActiveOrganizationState =
  | { state: 'loading' }
  | { state: 'redirecting'; redirectPath: string }
  | { state: 'loaded'; orgId: string; accessibleOrgs: Array<{ id: string }> };

function getActiveOrganizationState(
  result: UseQueryState<GQActiveOrganizationQuery>,
  forceRedirectResult: UseQueryState<GQForceRedirectQuery>
): ActiveOrganizationState {
  if (isFetchingOrPartial(result) || isFetchingOrPartial(forceRedirectResult)) {
    return { state: 'loading' };
  }
  // Note: This logic is duplicative to the logic in `errorExchange`,
  // and that logic & redirect often happens before this happens.
  if (result.error?.response?.status === 401) {
    const pathAndQuery = window.location.toString().replace(window.origin, '');
    return {
      state: 'redirecting',
      redirectPath: routeForLogin({ redirect: pathAndQuery }),
    };
  }
  const forceRedirect = forceRedirectResult.data?.forceRedirect;
  if (forceRedirect && !window.location.pathname.startsWith(forceRedirect)) {
    return { state: 'redirecting', redirectPath: forceRedirect };
  }
  const resultData = getGqlResultDataBang(result);
  return {
    state: 'loaded',
    orgId: resultData.activeOrganization.id,
    accessibleOrgs: resultData.activeOrganization.user.accessibleOrgs,
  };
}

type FetchActiveOrganizationResult = {
  activeOrganization: GQActiveOrganizationFieldsFragment;
  flags: Array<GQFlagsInitFieldsFragment>;
  preferredLocale: string | null;
};

function FetchActiveOrganizationQuery({
  children,
}: {
  children: (
    activeOrganizationQuery: FetchActiveOrganizationResult
  ) => React.ReactNode;
}) {
  // Step one: Process the location, query params, and pinned organization id from session storage
  const { location } = useGlobalLocation();
  const router = useRouter();
  const [loginAsRedirect] = useQueryParam('loginAsRedirect');
  const [urlOrgId] = useQueryParam(ORG_ID_PARAM);
  const pinnedOrganizationId = getPinnedOrganizationId();

  // Step two: Fetch the active organization
  const [result] = useActiveOrganizationQuery();
  const { data, error } = result;

  // Step three: Fetch the force redirect query
  const [forceRedirectValidated, setForceRedirectValidated] = useState(false);
  const [forceRedirectResult] = useForceRedirectQuery({
    variables: { pathname: location.pathname },
    pause: forceRedirectValidated,
  });

  // Step four: Consolidate the active organization state based on the above
  const activeOrganizationState = getActiveOrganizationState(
    result,
    forceRedirectResult
  );

  // Step five: Various effects and early return outcomes for various app-level scenarios
  useEffect(() => {
    if (!forceRedirectValidated && forceRedirectResult.data) {
      setForceRedirectValidated(true);
    }
  }, [forceRedirectValidated, forceRedirectResult.data]);

  // Outcome one:Loading state
  if (activeOrganizationState.state === 'loading') {
    return <LoadingPage />;
  }

  // Outcome two: Redirecting via forceRedirect
  if (activeOrganizationState.state === 'redirecting') {
    const redirectUrl = new URL(
      activeOrganizationState.redirectPath,
      window.location.origin
    );

    // When someone logs in to dashboard from admin (using login-as), we
    // broadcast a message to all browser tabs with an active dashboard
    // session, which then shows a warning if the session's active org is
    // different from the org the user just logged in to. This logic depends on
    // having `loginAsRedirect` param set to true. Therefore we should preserve
    // the param when we force redirect. See useLoginAsCrossOrgWarning.
    if (loginAsRedirect === 'true') {
      redirectUrl.searchParams.set('loginAsRedirect', 'true');
    }

    return <Redirect to={`${redirectUrl.pathname}${redirectUrl.search}`} />;
  }

  // Oooooh…in this case we have to do something with this ORG_ID_PARAM
  if (urlOrgId) {
    const { orgId, accessibleOrgs } = activeOrganizationState;
    const urlOrgIsValid =
      urlOrgId && accessibleOrgs.map((org) => org.id).includes(urlOrgId);
    const redirectUrl = new URL(window.location.href);
    redirectUrl.searchParams.delete(ORG_ID_PARAM);
    const searchParamsString = redirectUrl.searchParams.toString();
    const redirectClean =
      searchParamsString.length > 0
        ? `${redirectUrl.pathname}?${searchParamsString}`
        : redirectUrl.pathname;

    if (urlOrgIsValid && urlOrgId !== orgId) {
      // Outcome three: Redirecting to the correct org via ORG_ID_PARAM
      switchPinnedOrganizationId({
        orgId: urlOrgId,
        shouldOpenInNewTab: false,
        redirect: redirectClean,
      });

      // Switch pinned organization should post to the server and result in a hard refresh.
      // So we don't want to render anything else in the meantime.
      return <LoadingPage />;
    } else if (urlOrgIsValid && urlOrgId === orgId) {
      // don't switch orgs but trim out the org id param in the url
      void router.replace(redirectClean);
    } else if (pinnedOrganizationId === null) {
      initPinnedOrganizationId(orgId);
    } else if (pinnedOrganizationId !== orgId) {
      // This should never happen unless the data stored in sessionStorage
      // is malformed in some way. However, this is a scary thing to fix
      // so we'll be as defensive as possible. Reset the value in
      // session storage, warn and then force a refresh to clear the cache.
      console.warn(
        `ERROR: initOrganizationId was called with inconsistent ids - {orgId: ${orgId} vs ${pinnedOrganizationId}}`
      );
      clearPinnedOrganizationId();
    }

    // Outcome four: No permission page via ORG_ID_PARAM
    return <ViewerNoPermissionPage orgName={urlOrgId} />;
  }

  // Outcome five: Error handling for local dev
  if ((error || !data) && process.env.NODE_ENV === 'development') {
    return <FailedToContactApiDevelopmentErrorBox error={error} />;
  }

  // At this point we should be golden. If not, throw specifically.
  invariant(
    error === undefined && data,
    `Failed to load activeOrganization query. Error: ${error?.message}`
  );

  // Outcome six: Render the children per usual. Nothing out of the ordinary here.
  return <>{children(data)}</>;
}

/**
 * The logged in layout is the outermost layout component for the dashboard.
 * It is responsible for fetching the active organization and providing it
 * to the shared app container.
 *
 * Notably, this is an important place to put any top level redirects because
 * at this point we have not initiated any downstream queries that might fail
 * if we have cross-org issues or need to redirect a user based on permissions.
 */
export default function LoggedInLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <FetchActiveOrganizationQuery>
      {(props) => (
        <SharedAppContainer {...props}>{children}</SharedAppContainer>
      )}
    </FetchActiveOrganizationQuery>
  );
}
