import AssetYearModel, {
  AssetYearFields,
} from '@watershed/db/models/finance/AssetYearModel';
import { GQOneSchemaDataFormat } from '@watershed/shared-universal/generated/graphql';
import { AssetCorporateFields } from '@watershed/db/models/finance/AssetCorporateModel';
import { AssetCommercialRealEstateFields } from '@watershed/db/models/finance/AssetRealEstateModel';
import { AssetGroupFields } from '@watershed/db/models/finance/AssetGroupModel';
import { AssetSovereignBondFields } from '@watershed/db/models/finance/AssetSovereignBondModel';
import { AssetPersonalMotorVehicleInsuranceFields } from '@watershed/db/models/finance/AssetPersonalMotorVehicleInsuranceModel';
import { GQFundAssetClass } from '../../generated/graphql';
import AssetGroup from '../classes/AssetGroup';
import AssetCorporate from '../classes/AssetCorporate';
import AssetYear from '../classes/AssetYear';
import AssetPersonalMotorVehicleInsurance from '../classes/AssetPersonalMotorVehicleInsurance';
import AssetRealEstate from '../classes/AssetRealEstate';
import AssetSovereignBond from '../classes/AssetSovereignBond';
import assertNever from '@watershed/shared-util/assertNever';
import { ValueOf } from '../../utils/utilTypes';
import { AssetYearFieldsTemp } from '../types/FinanceFragments';

export type AnyAsset =
  | AssetCorporate
  | AssetRealEstate
  | AssetGroup
  | AssetSovereignBond
  | AssetPersonalMotorVehicleInsurance;

const ASSET_TYPE_STRING_TO_FK_NAME = {
  AssetCorporate: 'assetCorporateId',
  AssetRealEstate: 'assetCommercialRealEstateId',
  AssetGroup: 'assetGroupId',
  AssetSovereignBond: 'assetSovereignBondId',
  AssetPersonalMotorVehicleInsurance: 'assetPersonalMotorVehicleInsuranceId',
} as const;

export enum AssetTypeStrings {
  AssetCorporate = 'AssetCorporate',
  AssetRealEstate = 'AssetRealEstate',
  AssetGroup = 'AssetGroup',
  AssetSovereignBond = 'AssetSovereignBond',
  AssetPersonalMotorVehicleInsurance = 'AssetPersonalMotorVehicleInsurance',
}

export const ASSET_YEAR_ASSET_FOREIGN_KEY_IDS = Object.values(
  ASSET_TYPE_STRING_TO_FK_NAME
);

export type AssetTypeStringToFkName = typeof ASSET_TYPE_STRING_TO_FK_NAME;

// This type is used to ensure that the values of AssetTypeStringToFkName
// are fields on AssetYearFields
type CheckMapper<T extends Record<string, keyof AssetYearFields>> = T;
type _ = CheckMapper<AssetTypeStringToFkName>;

export type AssetTypeString = keyof AssetTypeStringToFkName;
export type AssetYearAssetForeignKeyId = ValueOf<AssetTypeStringToFkName>;
export type AssetTypeIdValue = Partial<{
  [p in AssetYearAssetForeignKeyId]: string;
}>;

export const customAssetYearTemplateAssetTypes: Array<AssetTypeString> = [
  'AssetSovereignBond',
  'AssetPersonalMotorVehicleInsurance',
];

export const standardAssetYearTemplateAssetTypes: Array<AssetTypeString> = [
  'AssetCorporate',
  'AssetRealEstate',
  'AssetGroup',
];

export const customAssetYearTemplateAssetForeignKeyIds: Array<AssetYearAssetForeignKeyId> =
  customAssetYearTemplateAssetTypes.map((assetType) =>
    getAssetIdFieldFromAssetType(assetType)
  );

export const customAssetHoldingTemplateAssetClasses: Array<GQFundAssetClass> = [
  GQFundAssetClass.PersonalMotorVehicleInsurance,
];

export const customAssetHoldingTemplateAssetTypes: Array<AssetTypeString> = [
  'AssetPersonalMotorVehicleInsurance',
];

export const AllAssetClasses = Object.values(GQFundAssetClass);

export const standardAssetHoldingTemplateAssetClasses = AllAssetClasses.filter(
  (ac) => !customAssetHoldingTemplateAssetClasses.includes(ac)
);

export const standardAssetHoldingTemplateAssetTypes: Array<AssetTypeString> = [
  'AssetCorporate',
  'AssetRealEstate',
  'AssetGroup',
  'AssetSovereignBond',
];

export type AssetClassFields =
  | AssetCorporateFields
  | AssetCommercialRealEstateFields
  | AssetGroupFields
  | AssetSovereignBondFields
  | AssetPersonalMotorVehicleInsuranceFields;

export function getAssetTypeFromId(id: string): AssetTypeString {
  if (id.startsWith('fnh_')) {
    return 'AssetCorporate';
  }
  if (id.startsWith('acre_')) {
    return 'AssetRealEstate';
  }
  if (id.startsWith('agp_')) {
    return 'AssetGroup';
  }
  if (id.startsWith('asb_')) {
    return 'AssetSovereignBond';
  }
  if (id.startsWith('apmvi_')) {
    return 'AssetPersonalMotorVehicleInsurance';
  }
  throw new Error(`Invalid asset id: ${id}`);
}

export const AssetOneSchemaDataFormats: Array<GQOneSchemaDataFormat> = [
  GQOneSchemaDataFormat.AssetCorporate,
  GQOneSchemaDataFormat.AssetGroup,
  GQOneSchemaDataFormat.AssetRealEstate,
  GQOneSchemaDataFormat.AssetSovereignBond,
  GQOneSchemaDataFormat.AssetPersonalMotorVehicleInsurance,
];

export function getAssetIdFromAssetType(
  typename: AssetTypeString,
  assetId: string
): AssetTypeIdValue {
  return typename === 'AssetCorporate'
    ? { assetCorporateId: assetId }
    : typename === 'AssetRealEstate'
      ? { assetCommercialRealEstateId: assetId }
      : typename === 'AssetGroup'
        ? { assetGroupId: assetId }
        : typename === 'AssetSovereignBond'
          ? { assetSovereignBondId: assetId }
          : typename === 'AssetPersonalMotorVehicleInsurance'
            ? { assetPersonalMotorVehicleInsuranceId: assetId }
            : {};
}

export function getAssetIdFieldFromAssetType(
  typename: AssetTypeString
): AssetYearAssetForeignKeyId {
  switch (typename) {
    case 'AssetCorporate':
      return 'assetCorporateId';
    case 'AssetRealEstate':
      return 'assetCommercialRealEstateId';
    case 'AssetGroup':
      return 'assetGroupId';
    case 'AssetSovereignBond':
      return 'assetSovereignBondId';
    case 'AssetPersonalMotorVehicleInsurance':
      return 'assetPersonalMotorVehicleInsuranceId';
    default:
      throw new Error('Invalid asset id');
  }
}

export function getAssetTypeDisplayString(value: string): string {
  const AssetTypeStringValue = value as AssetTypeString;
  switch (AssetTypeStringValue) {
    case 'AssetCorporate':
      return 'Asset corporate';
    case 'AssetRealEstate':
      return 'Asset real estate';
    case 'AssetGroup':
      return 'Asset group';
    case 'AssetSovereignBond':
      return 'Asset sovereign debt';
    case 'AssetPersonalMotorVehicleInsurance':
      return 'Asset personal motor vehicle insurance';
    default:
      assertNever(AssetTypeStringValue);
  }
}

export function getAssetIdFromAssetYear(
  assetYear: AssetYear | AssetYearModel | AssetYearFields | AssetYearFieldsTemp
): string | undefined {
  return (
    assetYear.assetCorporateId ??
    assetYear.assetCommercialRealEstateId ??
    assetYear.assetGroupId ??
    assetYear.assetSovereignBondId ??
    assetYear.assetPersonalMotorVehicleInsuranceId ??
    undefined
  );
}

// This is the list of corporate asset classes that are allowed for holdings of asset groups + relevant for pcaf score 5 calculations
export const nonInsuranceCorporateAssetClasses = [
  GQFundAssetClass.ListedEquity,
  GQFundAssetClass.CorporateBonds,
  GQFundAssetClass.UnlistedEquity,
  GQFundAssetClass.BusinessLoans,
] as const;

// This is the list of all possible asset classes for holdings of corporate assets
export const corporateAssetClasses = [
  ...nonInsuranceCorporateAssetClasses,
  GQFundAssetClass.CommercialLineOfInsurance,
] as const;

// This is the list of all possible asset classes for holdings of real estate assets
export const realEstateAssetClassesWithMortgages = [
  GQFundAssetClass.CommercialRealEstate,
  GQFundAssetClass.Mortgages,
] as const;

export const realEstateAssetClasses = [
  GQFundAssetClass.CommercialRealEstate,
] as const;

export const assetGroupAssetClasses = [GQFundAssetClass.AssetGroup] as const;

export const sovereignDebtAssetClasses = [
  GQFundAssetClass.SovereignDebt,
] as const;

export const personalMotorVehicleInsuranceAssetClasses = [
  GQFundAssetClass.PersonalMotorVehicleInsurance,
] as const;

export const commercialLinesAssetClasses = [
  GQFundAssetClass.CommercialLineOfInsurance,
] as const;

const assetHoldingClassDisplayNames: Record<GQFundAssetClass, string> = {
  [GQFundAssetClass.AssetGroup]: 'Asset group',
  [GQFundAssetClass.ListedEquity]: 'Listed equity',
  [GQFundAssetClass.CorporateBonds]: 'Corporate bonds',
  [GQFundAssetClass.UnlistedEquity]: 'Unlisted equity',
  [GQFundAssetClass.BusinessLoans]: 'Business loans',
  [GQFundAssetClass.SovereignDebt]: 'Sovereign debt',
  [GQFundAssetClass.PersonalMotorVehicleInsurance]:
    'Personal motor vehicle insurance',
  [GQFundAssetClass.CommercialLineOfInsurance]: 'Commercial line of insurance',
  [GQFundAssetClass.CommercialRealEstate]: 'Commercial real estate',
  [GQFundAssetClass.Mortgages]: 'Mortgages',
};

export const getAssetHoldingClassDisplayName = (assetClass: string): string => {
  if (assetClass in assetHoldingClassDisplayNames) {
    return assetHoldingClassDisplayNames[
      assetClass as keyof typeof GQFundAssetClass
    ];
  }
  return assetClass;
};

export const assetClassValidationMap: Record<
  AssetYearAssetForeignKeyId,
  { validAssetClasses: ReadonlyArray<GQFundAssetClass>; errorMsg: string }
> = {
  ['assetCorporateId']: {
    validAssetClasses: corporateAssetClasses,
    errorMsg:
      'Incorrect asset class provided - for corporate assets, holding asset class must be Listed equity, Corporate bonds, Unlisted equity, Business loans, or Commercial lines of insurance',
  },
  ['assetCommercialRealEstateId']: {
    validAssetClasses: [GQFundAssetClass.CommercialRealEstate],
    errorMsg:
      'Incorrect asset class provided - for commercial real estate assets, holding asset class must be Commercial real estate',
  },
  ['assetGroupId']: {
    validAssetClasses: [
      GQFundAssetClass.AssetGroup,
      ...nonInsuranceCorporateAssetClasses,
    ],
    errorMsg:
      'Incorrect asset class provided - for asset groups, holding asset class must be Asset group, Listed equity, Corporate bonds, Unlisted equity, or Business loans',
  },
  ['assetSovereignBondId']: {
    validAssetClasses: [GQFundAssetClass.SovereignDebt],
    errorMsg:
      'Incorrect asset class provided - for sovereign debt, holding asset class must be Sovereign debt',
  },
  ['assetPersonalMotorVehicleInsuranceId']: {
    validAssetClasses: [GQFundAssetClass.PersonalMotorVehicleInsurance],
    errorMsg:
      'Incorrect asset class provided - for personal motor vehicle insurance, holding asset class must be Personal motor vehicle insurance',
  },
};

export function getAssetClassValidationMap(canAccessMortgages: boolean) {
  if (canAccessMortgages) {
    return {
      ...assetClassValidationMap,
      ['assetCommercialRealEstateId']: {
        validAssetClasses: realEstateAssetClassesWithMortgages,
        errorMsg:
          'Incorrect asset class provided - for commercial real estate assets, holding asset class must be Commercial real estate or Mortgages',
      },
    };
  }
  return assetClassValidationMap;
}
