import { RequestPermission } from '@watershed/service-essentials/RequestContext';
import {
  GQPermissionFieldsFragment,
  GQPermissionType,
  GQPermissionObjectType,
} from '../generated/graphql';
import assertNever from '@watershed/shared-util/assertNever';
import mapStrToEnum from '../utils/mapStrToEnum';
import isEqual from 'lodash/isEqual';
import {
  getPermissionOrigin,
  SourceObjectWithPermissionDelegate,
} from './permissionsHierarchy';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { DBPermissionItemObjectType } from '@watershed/global-db/generated/db';
import {
  Action,
  ResourceSpecificPermissionType,
} from '@watershed/shared-universal/permissions/permissionTypes';
import {
  getLegacyPermissionFromResourceAndAction,
  RESOURCE_LEVEL_PERMISSION_TYPES,
} from '@watershed/shared-universal/permissions/permissionsConfig';
import must from '../utils/must';

export type PermissionSelectionItem = {
  id?: string;
  permission: GQPermissionType;
  objectId?: string;
  objectType?: GQPermissionObjectType;
  removedBy?: string;
};

// The enum values have been converted to their string literals so that this function
// can accept both GQ and DB enum types
export type PermissionType = `${GQPermissionType}`;

export type UserPermission = {
  permission: PermissionType;
  objectId?: string | null;
  objectType?: GQPermissionObjectType | null;
};

export type UserPermissions = Array<UserPermission>;

/**
 * Asserts the user has any of the required permissions
 */
export function hasPermission(
  userPermissions: Readonly<UserPermissions>,
  allowList: ReadonlyArray<PermissionType>,
  {
    allowAnyObject,
    source,
  }: {
    allowAnyObject?: boolean;
    source?: SourceObjectWithPermissionDelegate;
  } = {}
): boolean {
  return allowList.some((allowPermission) =>
    userPermissions.some(
      ({ permission, objectId }) =>
        !!getPermissionOrigin({
          permission,
          allowPermission,
          objectId,
          allowAnyObject,
          source,
        })
    )
  );
}

export function objectIdsForPermissionItemsOfObjectType({
  userPermissions,
  permissions,
  objectType,
}: {
  userPermissions: ReadonlyArray<RequestPermission>;
  permissions: Array<PermissionType>;
  objectType: DBPermissionItemObjectType;
}): Array<string> {
  return userPermissions
    .filter((permissionItem) => {
      return (
        permissionItem.objectId &&
        permissionItem.objectType === objectType &&
        permissions.includes(permissionItem.permission)
      );
    })
    .map((permissionItem) => must(permissionItem.objectId));
}

// A permission item can either be object-specific or not, i.e. is there an
// object associated with it . For example, "Manage datasource X" and
// "Manage supplier Y" are object-specific, whereas "View reductions" is not.
export function isObjectSpecificPermission(
  permission: GQPermissionType
): boolean {
  switch (permission) {
    case GQPermissionType.ManageDataset:
    case GQPermissionType.ManageDatasource:
    case GQPermissionType.ApproveDatasource:
    case GQPermissionType.ApproveDataset:
    case GQPermissionType.ManageDisclosures:
    case GQPermissionType.ManageSuppliers:
    case GQPermissionType.ManageFund:
    case GQPermissionType.FinanceReadOnly:
    case GQPermissionType.EditReport:
    case GQPermissionType.ViewReport:
    case GQPermissionType.EditReportQuestionInstance:
    case GQPermissionType.ViewReportQuestionInstance:
      return true;
    case GQPermissionType.Admin:
    case GQPermissionType.CorporateAdmin:
    case GQPermissionType.FinanceAdmin:
    case GQPermissionType.ManageMarketplacePurchases:
    case GQPermissionType.ManageMeasurement:
    case GQPermissionType.ManageCompanyTags:
    case GQPermissionType.ManageOrgHierarchy:
    case GQPermissionType.ManageReductionPlans:
    case GQPermissionType.ManageSingleSignOn:
    case GQPermissionType.ViewEmployeeReport:
    case GQPermissionType.ViewFootprintDetail:
    case GQPermissionType.ViewAuditDetail:
    case GQPermissionType.ViewLearningHub:
    case GQPermissionType.ViewReductions:
    case GQPermissionType.WatershedAdmin:
    case GQPermissionType.AnyUser:
      return false;
    default:
      // If we add a new permission type, make sure to update this.
      assertNever(permission);
  }
}

export function toPermissionSelectionItem(
  permission: GQPermissionFieldsFragment
): PermissionSelectionItem {
  if (permission.objectId && permission?.object) {
    return {
      permission: permission.permission,
      objectId: permission.objectId,
      objectType: mapStrToEnum(
        permission.object.__typename,
        GQPermissionObjectType
      ),
    };
  } else {
    return {
      permission: permission.permission,
    };
  }
}

export const GlobalPermissions = pick(GQPermissionType, [
  GQPermissionType.Admin,
  GQPermissionType.WatershedAdmin,
]);
export const FinancePermissions = pick(GQPermissionType, [
  GQPermissionType.FinanceAdmin,
  GQPermissionType.ManageFund,
  GQPermissionType.FinanceReadOnly,
]);

export const CorporatePermissions = omit(GQPermissionType, [
  ...Object.values(GlobalPermissions),
  ...Object.values(FinancePermissions),
]);

export function getPermissionsToRevoke(
  currentPermissions: Array<PermissionSelectionItem>,
  permissionSelection: Array<PermissionSelectionItem>
): Array<PermissionSelectionItem> {
  return currentPermissions.filter((current) => {
    // Object-specific permission like "Manage datasource X" will always show
    // up in the AddPermissionDialog so that users can add/remove associated
    // objects. A non-object-specific permission like "View reductions" only
    // shows up in the AddPermissionDialog if the user does not already have
    // it. We only need to handle permission revocation for object-specific
    // permissions here. Non-object-specific permissions can only be revoked in
    // UserPermissionsList.
    if (!isObjectSpecificPermission(current.permission)) {
      return false;
    }

    // If the selected list doesn't have the current object-specific
    // permission, that means the user hasn't changed it, so we don't need to
    // do anything.
    if (!permissionSelection.some((p) => p.permission === current.permission)) {
      return false;
    }

    // If an object-specific permission is in the current list but not the
    // selected list, the user must have unchecked it in the dialog. In that
    // case, we should revoke it. For example, if the user has "Manage
    // datasource A, B, C" whereas the selected list only has "Manage
    // datasource A, B", we should revoke the permission for datasource C.
    return !permissionSelection.some((selected) => isEqual(selected, current));
  });
}

export function userCanGrantPermission(
  userPermissions: UserPermissions,
  permission: GQPermissionType
): boolean {
  return hasPermission(userPermissions, [permission], {
    allowAnyObject: true,
  });
}

// Returns the permissions that a Watershed employee should have. The context
// here is that the permissions dialog is currently shared between dashboard
// and admin, and it should only show the permissions that the current user
// have permissions to grant. On dashboard, this is the logged-in user. On
// admin, we presume that the logged-in user is a Watershed employee.
export function getPermissionsForWatershedAdminUser(): UserPermissions {
  return [{ permission: GQPermissionType.WatershedAdmin }];
}

// For permissions for a value mapping-related object we hardcode the object name to be "Value mappings" for now
export const VALUE_MAPPING_OBJECT_NAME = 'Value mappings';

/**
 * TODO (tzv): Remove or add unit tests if we keep this function
 * Translates the current data structure of user permissions to the new format
 * which I think is more structured and intuitive but I may be wrong.
 */

export function permissionItemToPermissionId(
  permissionItem: UserPermission
): string {
  const resourcePermissionConfig =
    RESOURCE_LEVEL_PERMISSION_TYPES[
      permissionItem.permission as ResourceSpecificPermissionType
    ];
  if (resourcePermissionConfig) {
    return `${resourcePermissionConfig.resourceType}:${
      resourcePermissionConfig.action
    }:${permissionItem.objectId ?? 'all'}`;
  }
  return permissionItem.permission;
}

export function permissionIdToPermissionItem(
  permissionId: string
): UserPermission {
  const [permissionOrResourceType, action, objectId] = permissionId.split(':');
  if (action && objectId) {
    const objectType = mapStrToEnum(
      permissionOrResourceType,
      GQPermissionObjectType
    );
    const permission = getLegacyPermissionFromResourceAndAction(
      objectType,
      mapStrToEnum(action, Action)
    );
    if (objectId === 'all') {
      return { permission };
    }
    return {
      permission,
      objectType,
      objectId,
    };
  }
  return {
    permission: permissionOrResourceType as PermissionType,
  };
}
