import { hasPermissionToManageAnySupplier } from '@watershed/shared-frontend/utils/permissions/SupplierPermissionUtils';
import {
  GQFlags,
  GQPermissionType,
  GQWatershedPlan,
  GQWatershedPlanLegacy,
} from '@watershed/shared-universal/generated/graphql';
import { hasPermission } from '@watershed/shared-universal/permissions/permissionUtils';
import { UserContextProps, useUserContext } from './UserContext';
import {
  FeatureFlagsMap,
  useAllFeatureFlags,
  useFeatureFlag,
} from './FeatureFlag';
import {
  getWatershedPlanLegacyName,
  isWatershedPlanAtLeastWithFallback,
} from '@watershed/shared-universal/utils/watershedPlanUtils';
import assertNever from '@watershed/shared-util/assertNever';

export type FeatureAccess =
  | {
      hasAccess: true;
    }
  | {
      hasAccess: false;
      reason: string;
    };

export type FeatureAccessFunction = (metadata: {
  userContext: UserContextProps;
  featureFlags: FeatureFlagsMap;
}) => FeatureAccess;

export type FeatureAccessCheckDescriptor =
  | {
      kind: 'some';
      accessChecks: ReadonlyArray<FeatureAccessFunction>;
    }
  | {
      kind: 'all';
      accessChecks: ReadonlyArray<FeatureAccessFunction>;
    };

const INSUFFICIENT_PERMISSIONS = {
  hasAccess: false,
  reason:
    'You don’t have permission to view this page. Please contact your admin for access.',
} as const;
const FEATURE_FLAG_GATED = {
  hasAccess: false,
  reason:
    'This feature is not yet enabled for your organization. Please contact your Customer Success Manager.',
} as const;
export const HAS_ACCESS = {
  hasAccess: true,
} as const;

function hasPermissionsAccessCheck(
  hasPermissions: (userContext: UserContextProps) => boolean,
  reason?: string
): FeatureAccessFunction {
  return ({ userContext }) => {
    const hasAccess = hasPermissions(userContext);
    if (!hasAccess) {
      return {
        hasAccess: false,
        reason: reason ?? INSUFFICIENT_PERMISSIONS.reason,
      };
    }
    return HAS_ACCESS;
  };
}

function hasFeatureFlagAccessCheck(
  featureFlag: GQFlags,
  reason?: string,
  negate?: boolean
): FeatureAccessFunction {
  return ({ featureFlags }) => {
    if (negate === true) {
      if (featureFlags.get(featureFlag)) {
        return {
          hasAccess: false,
          reason: reason ?? FEATURE_FLAG_GATED.reason,
        };
      }
      return HAS_ACCESS;
    } else {
      if (!featureFlags.get(featureFlag)) {
        return {
          hasAccess: false,
          reason: reason ?? FEATURE_FLAG_GATED.reason,
        };
      }
      return HAS_ACCESS;
    }
  };
}

function hasWatershedPlanAccessCheck(access: {
  atLeast: GQWatershedPlan;
  /**
   * In the case of `Custom` and `Other` Watershed Plan, optional fallback to a
   * legacy plan for gating.
   */
  legacyFallbackAtLeast?: GQWatershedPlanLegacy;
}): FeatureAccessFunction {
  return ({ userContext }) => {
    const { watershedPlan, watershedPlanLegacy } = userContext;
    const { hasAtLeast, usedFallback } = isWatershedPlanAtLeastWithFallback({
      watershedPlan,
      watershedPlanAtLeast: access.atLeast,
      watershedPlanLegacy,
      watershedPlanLegacyAtLeast: access.legacyFallbackAtLeast,
    });
    if (hasAtLeast) return HAS_ACCESS;
    const planName = usedFallback
      ? getWatershedPlanLegacyName(watershedPlanLegacy)
      : watershedPlan;
    return {
      hasAccess: false,
      reason: `This feature is not available on the ${planName} plan. Please contact your Customer Success Manager to learn more.`,
    };
  };
}

function hasFootprintIntervalCheck(reason: string): FeatureAccessFunction {
  return () => {
    const { footprintInterval } = useUserContext();
    if (footprintInterval == null) {
      return { hasAccess: false, reason };
    }
    return HAS_ACCESS;
  };
}

export function useAccessChecks(
  accessDescriptor: FeatureAccessCheckDescriptor | null | undefined
) {
  const userContext = useUserContext();
  const featureFlags = useAllFeatureFlags();
  if (!accessDescriptor) {
    return HAS_ACCESS;
  }
  return checkAccessChecks(accessDescriptor, userContext, featureFlags);
}

export function checkAccessChecks(
  accessDescriptor: FeatureAccessCheckDescriptor,
  userContext: UserContextProps,
  featureFlags: FeatureFlagsMap
): FeatureAccess {
  const deniedChecks = accessDescriptor.accessChecks
    .map((accessCheck) => accessCheck({ userContext, featureFlags }))
    .filter((featureAccess) => !featureAccess.hasAccess);
  const accessDenied = (() => {
    switch (accessDescriptor.kind) {
      case 'all': {
        if (deniedChecks.length > 0) {
          return deniedChecks[0];
        }
        return null;
      }
      case 'some': {
        if (deniedChecks.length === accessDescriptor.accessChecks.length) {
          return deniedChecks[0];
        }
        return null;
      }
      default:
        assertNever(accessDescriptor);
    }
  })();
  return accessDenied ?? HAS_ACCESS;
}

// Reduction plans
export function hasPermissionsForReductionPlans(userContext: UserContextProps) {
  return hasPermission(userContext.permissions, [
    GQPermissionType.ViewReductions,
  ]);
}
export const HAS_ACCESS_TO_REDUCTION_PLANS: FeatureAccessCheckDescriptor = {
  kind: 'all',
  accessChecks: [hasPermissionsAccessCheck(hasPermissionsForReductionPlans)],
};

// Measurement projects
export function hasPermissionsForMeasurementProjects(
  userContext: UserContextProps
) {
  return hasPermission(
    userContext.permissions,
    [GQPermissionType.ManageDatasource, GQPermissionType.ApproveDatasource],
    { allowAnyObject: true }
  );
}
export const HAS_ACCESS_TO_MEASUREMENT_PROJECTS: FeatureAccessCheckDescriptor =
  {
    kind: 'all',
    accessChecks: [
      hasPermissionsAccessCheck(hasPermissionsForMeasurementProjects),
    ],
  };

// Facilities
export function useHasPermissionForGlobalFacilities(
  facilitiesMetadataBuildingsDatasetId?: string
) {
  const userContext = useUserContext();

  const isFeatureFlagEnabled = useFeatureFlag(
    GQFlags.DataIngestionGlobalFacilitiesPage
  );
  if (!isFeatureFlagEnabled) {
    return false;
  }

  const adminOrManageMeasurement = hasPermission(userContext.permissions, [
    GQPermissionType.Admin,
    GQPermissionType.ManageMeasurement,
  ]);

  if (adminOrManageMeasurement) {
    return true;
  }

  if (facilitiesMetadataBuildingsDatasetId) {
    return hasPermission(
      userContext.permissions,
      [GQPermissionType.ManageDataset],
      {
        allowAnyObject: false,
        source: {
          id: facilitiesMetadataBuildingsDatasetId,
        },
      }
    );
  }
  return false;
}

// Methodology customization
export function hasPermissionsForMethodologyCustomization(
  userContext: UserContextProps
) {
  return hasPermission(userContext.permissions, [
    GQPermissionType.ManageMeasurement,
  ]);
}
export const HAS_ACCESS_TO_METHODOLOGY_CUSTOMIZATION: FeatureAccessCheckDescriptor =
  {
    kind: 'all',
    accessChecks: [
      hasPermissionsAccessCheck(hasPermissionsForMethodologyCustomization),
      hasFeatureFlagAccessCheck(
        GQFlags.CalculationMethodologyCustomizationPage,
        'Your Watershed plan does not include Methodology Customization.'
      ),
    ],
  };

// Reports
function hasPermissionsForFormalReporting(userContext: UserContextProps) {
  return hasPermission(userContext.permissions, [
    GQPermissionType.ViewFootprintDetail,
  ]);
}
export const HAS_ACCESS_TO_FORMAL_REPORTING: FeatureAccessCheckDescriptor = {
  kind: 'all',
  accessChecks: [hasPermissionsAccessCheck(hasPermissionsForFormalReporting)],
};

// Data governance
export function hasPermissionsForDataGovernance(userContext: UserContextProps) {
  return hasPermission(
    userContext.permissions,
    [GQPermissionType.ManageMeasurement],
    { allowAnyObject: true }
  );
}
export const HAS_ACCESS_TO_DATA_GOVERNANCE: FeatureAccessCheckDescriptor = {
  kind: 'all',
  accessChecks: [hasPermissionsAccessCheck(hasPermissionsForDataGovernance)],
};

// Clean power
function hasPermissionsForCleanPower(userContext: UserContextProps) {
  return (
    hasPermission(userContext.permissions, [
      GQPermissionType.ManageMarketplacePurchases,
    ]) &&
    hasPermission(userContext.permissions, [
      GQPermissionType.ViewFootprintDetail,
    ])
  );
}

export const HAS_ACCESS_TO_CLEAN_POWER: FeatureAccessCheckDescriptor = {
  kind: 'all',
  accessChecks: [
    // TODO: @dgattey CPT-3182 bring back the selector and delete this
    () => ({
      hasAccess: false,
      reason: 'Multiple footprints is not compatible with clean power quote',
    }),
    hasPermissionsAccessCheck(hasPermissionsForCleanPower),
  ],
};

// Supply chain
function hasPermissionsForSupplyChain(userContext: UserContextProps) {
  return hasPermissionToManageAnySupplier(userContext.permissions);
}
export const HAS_ACCESS_TO_SUPPLY_CHAIN: FeatureAccessCheckDescriptor = {
  kind: 'all',
  accessChecks: [
    hasFeatureFlagAccessCheck(
      GQFlags.FootprintSuppliersPage,
      'Your Watershed plan does not include Supply Chain.'
    ),
    hasPermissionsAccessCheck(hasPermissionsForSupplyChain),
    hasFootprintIntervalCheck(
      'You must have a footprint to access supply chain'
    ),
  ],
};

// Benchmarks
// This access check does not check for permission to view footprint detail!
// ONLY use this in combination with a check for GQPermissionType.ViewFootprintDetail
// Or use hasAccessToBenchmarks
export const HAS_ACCESS_TO_BENCHMARKS: FeatureAccessCheckDescriptor = {
  kind: 'some',
  accessChecks: [
    hasWatershedPlanAccessCheck({
      atLeast: GQWatershedPlan.Standard,
      legacyFallbackAtLeast: GQWatershedPlanLegacy.Standard,
    }),
    hasFeatureFlagAccessCheck(GQFlags.ContractOverrideUpsellBenchmarks),
  ],
};
export function hasAccessToBenchmarks(
  userContext: UserContextProps,
  featureFlags: FeatureFlagsMap
) {
  return (
    hasPermission(userContext.permissions, [
      GQPermissionType.ViewFootprintDetail,
    ]) &&
    checkAccessChecks(HAS_ACCESS_TO_BENCHMARKS, userContext, featureFlags)
      .hasAccess
  );
}

// SDI Historical Results
export const HAS_ACCESS_TO_SDI_HISTORICAL_RESULTS: FeatureAccessCheckDescriptor =
  {
    kind: 'all',
    accessChecks: [
      hasWatershedPlanAccessCheck({
        atLeast: GQWatershedPlan.Standard,
        legacyFallbackAtLeast: GQWatershedPlanLegacy.Standard,
      }),
    ],
  };
