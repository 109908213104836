import camelCase from 'lodash/camelCase';
import {
  GQFinanceWorksheetColumn,
  GQGridColumnFormat,
} from '../generated/graphql';
import { isValidCurrencyCode } from '../utils/currencies';
import { humanize } from '../utils/helpers';
import { routeForLearningHub } from '../dashboardRoutes';

type FinanceFieldTitleFn = (args: { currency?: string | null }) => string;

// We can't serialize the title function over the API, so we have the
// GraphQL type and then also the title function, which can be called
// on the server or the client
export type FinanceField = Omit<
  GQFinanceWorksheetColumn,
  'financeWorksheetId'
> & {
  titleFn?: FinanceFieldTitleFn;
  equation?: string;
};

export enum InputKey {
  // Fund
  fundGroup = 'fundGroup',
  fundCategory = 'fundCategory',
  excludeAsSandbox = 'excludeAsSandbox',

  // AbstractAsset
  assetClass = 'assetClass',
  assetGroupId = 'assetGroupId',
  buildingSizeUnit = 'buildingSizeUnit',
  countryAlpha2 = 'countryAlpha2',
  name = 'name',
  externalId = 'externalId',

  // See DBAssetCorporate
  naicsCode = 'naicsCode',
  companyId = 'companyId',
  company = 'company',
  companyIsin = 'companyIsin',
  companyLei = 'companyLei',
  companyTicker = 'companyTicker',
  companyBea = 'companyBea',
  companyUrl = 'companyUrl',
  companySAndPId = 'companySAndPId',
  companyDunsNumber = 'companyDunsNumber',
  companyEin = 'companyEin',
  companyVatId = 'companyVatId',
  companyLocalRegistryId = 'companyLocalRegistryId',

  // See DBAssetCommercialRealEstate
  buildingKind = 'buildingKind',
  buildingSubkind = 'buildingSubkind',
  city = 'city',
  postalCode = 'postalCode',
  state = 'state',
  streetAddress = 'streetAddress',
  streetAddress2 = 'streetAddress2',

  // See DBAssetSovereignBond
  sovereignEntity = 'sovereignEntity',

  // See DBAssetYear
  estimationMethodology = 'estimationMethodology',
  buildingSizeNative = 'buildingSizeNative',
  currencyCode = 'currencyCode',
  headcount = 'headcount',
  percentRenewable = 'percentRenewable',
  revenueNative = 'revenueNative',
  cloudSpendNative = 'cloudSpendNative',
  flightMiles = 'flightMiles',
  percentEmployeesWfh = 'percentEmployeesWfh',
  scope1OverrideTco2e = 'scope1OverrideTco2e',
  scope1OverridePcaf = 'scope1OverridePcaf',
  scope2LocationOverrideTco2e = 'scope2LocationOverrideTco2e',
  scope2LocationOverridePcaf = 'scope2LocationOverridePcaf',
  scope2MarketOverrideTco2e = 'scope2MarketOverrideTco2e',
  scope2MarketOverridePcaf = 'scope2MarketOverridePcaf',
  scope3OverrideKind = 'scope3OverrideKind',
  scope301OverrideTco2e = 'scope301OverrideTco2e',
  scope302OverrideTco2e = 'scope302OverrideTco2e',
  scope303OverrideTco2e = 'scope303OverrideTco2e',
  scope304OverrideTco2e = 'scope304OverrideTco2e',
  scope305OverrideTco2e = 'scope305OverrideTco2e',
  scope306OverrideTco2e = 'scope306OverrideTco2e',
  scope307OverrideTco2e = 'scope307OverrideTco2e',
  scope308OverrideTco2e = 'scope308OverrideTco2e',
  scope309OverrideTco2e = 'scope309OverrideTco2e',
  scope310OverrideTco2e = 'scope310OverrideTco2e',
  scope311OverrideTco2e = 'scope311OverrideTco2e',
  scope312OverrideTco2e = 'scope312OverrideTco2e',
  scope313OverrideTco2e = 'scope313OverrideTco2e',
  scope314OverrideTco2e = 'scope314OverrideTco2e',
  scope315OverrideTco2e = 'scope315OverrideTco2e',
  scope3OverrideTco2e = 'scope3OverrideTco2e',
  scope3OverridePcaf = 'scope3OverridePcaf',
  totalMwh = 'totalMwh',
  valueNative = 'valueNative',
  year = 'year',
  assetCorporateId = 'assetCorporateId',
  assetCommercialRealEstateId = 'assetCommercialRealEstateId',

  // See DBAssetHolding
  fundId = 'fundId',
  assetYearId = 'assetYearId',
  yearOfInitialInvestment = 'yearOfInitialInvestment',
  outstandingAmountNative = 'outstandingAmountNative',
  outstandingAmountNativeQ1 = 'outstandingAmountNativeQ1',
  outstandingAmountNativeQ2 = 'outstandingAmountNativeQ2',
  outstandingAmountNativeQ3 = 'outstandingAmountNativeQ3',
  outstandingAmountNativeQ4 = 'outstandingAmountNativeQ4',
  premiumNative = 'premiumNative',

  // contact
  contacts = 'contacts',
  contactName = 'contactName',
  contactEmail = 'contactEmail',
  contactRole = 'contactRole',
  // used in bulk import
  role = 'role',
  email = 'email',

  // changelog
  scope1Kgco2e = 'scope1Kgco2e',
  scope2LocationKgco2e = 'scope2LocationKgco2e',
  scope2MarketKgco2e = 'scope2MarketKgco2e',
  scope3Kgco2e = 'scope3Kgco2e',
  scope301Kgco2e = 'scope301Kgco2e',
  scope302Kgco2e = 'scope302Kgco2e',
  scope303Kgco2e = 'scope303Kgco2e',
  scope304Kgco2e = 'scope304Kgco2e',
  scope305Kgco2e = 'scope305Kgco2e',
  scope306Kgco2e = 'scope306Kgco2e',
  scope307Kgco2e = 'scope307Kgco2e',
  scope308Kgco2e = 'scope308Kgco2e',
  scope309Kgco2e = 'scope309Kgco2e',
  scope310Kgco2e = 'scope310Kgco2e',
  scope311Kgco2e = 'scope311Kgco2e',
  scope312Kgco2e = 'scope312Kgco2e',
  scope313Kgco2e = 'scope313Kgco2e',
  scope314Kgco2e = 'scope314Kgco2e',
  scope315Kgco2e = 'scope315Kgco2e',
  country = 'country',
  sizeUnit = 'sizeUnit',
  buildingName = 'buildingName',

  // Bulk import column names
  assetName = 'assetName',
  fundName = 'fundName',
  assetGroupName = 'assetGroupName',
  id = 'id',

  // ICI alignment
  measuringScope1And2Emissions = 'measuringScope1And2Emissions',
  measuringMaterialScope3Emissions = 'measuringMaterialScope3Emissions',
  initiatedDecarbonizationPlan = 'initiatedDecarbonizationPlan',
  committedToNearTermSbtAlignedNetZero = 'committedToNearTermSbtAlignedNetZero',
  demonstratingYoyEmissionsInLineWithNetZero = 'demonstratingYoyEmissionsInLineWithNetZero',
  iciAlignmentOverride = 'iciAlignmentOverride',
}
export type InputKeyValue = keyof typeof InputKey;

function buildCurrencyTitle(baseTitle: string): FinanceFieldTitleFn {
  return (args) => {
    if (args.currency && isValidCurrencyCode(args.currency)) {
      return `${baseTitle} (${args.currency})`;
    }
    return baseTitle;
  };
}

export const LINKS = {
  pcafPartA:
    'https://static.helpjuice.com/helpjuice_production/uploads/upload/image/14470/3184553/1685558316403-PCAF%2BPart%2BA.pdf',
  tcfdMetrics:
    'https://www.tcfdhub.org/Downloads/pdfs/E09%20-%20Carbon%20footprinting%20-%20metrics.pdf',
  ghgProtocolScope2:
    'https://ghgprotocol.org/sites/default/files/2022-12/Scope2_ExecSum_Final.pdf',
  ghgProtocolScope3:
    'https://ghgprotocol.org/sites/default/files/2023-03/Scope3_Calculation_Guidance_0%5B1%5D.pdf',
  pmdrRoadmap:
    'https://www.bain.com/how-we-help/private-markets-decarbonization-roadmap/',
  learningHubPCAF: routeForLearningHub({
    from: 'FinanceProduct',
    article: 'PCAFScoring',
    viewMode: 'finance',
  }),
  learningHubFinancedEmissions: routeForLearningHub({
    from: 'FinanceProduct',
    article: 'FinancedEmissions',
    viewMode: 'finance',
  }),
  learningHubScopesOfEmissions: routeForLearningHub({
    from: 'FinanceProduct',
    article: 'ScopesOfEmissions',
    viewMode: 'finance',
  }),
  learningHubMeasuring: routeForLearningHub({
    from: 'FinanceProduct',
    article: 'FinancedEmissionsFromEquityBondsLoans',
    viewMode: 'finance',
  }),
  learningHubClimateTargetSetting: routeForLearningHub({
    from: 'FinanceProduct',
    article: 'ClimateTargetSetting',
    viewMode: 'finance',
  }),
  learningHubCEE: routeForLearningHub({
    from: 'FinanceProduct',
    article: 'CompanyEstimationEngine',
    viewMode: 'finance',
  }),
  learningHubRealEstate: routeForLearningHub({
    from: 'FinanceProduct',
    article: 'FinancedEmissionsFromRealEstate',
    viewMode: 'finance',
  }),
  learningHubReportingAndRegulations: routeForLearningHub({
    from: 'FinanceProduct',
    article: 'ReportingAndRegulations',
    viewMode: 'finance',
  }),
  learningHubMethodology: routeForLearningHub({
    from: 'FinanceProduct',
    article: 'UnderstandingEEIOModels',
    viewMode: 'finance',
  }),
  bulkImport: `finance/measurement/import`,
  edci: 'https://www.esgdc.org/',
  sfdrBlog: 'https://watershed.com/blog/sfdr-guide',
} as const;

const FUND_RE = /\bfunds?\b/gi;

export function renameFundToLineOfBusiness(text: string): string {
  return text.replaceAll(FUND_RE, (match) => {
    if (match[0] === match[0].toUpperCase()) {
      if (match.endsWith('s')) return 'Lines of business';
      return 'Line of business';
    } else {
      if (match.endsWith('s')) return 'lines of business';
      return 'line of business';
    }
  });
}

export type FinanceFieldLike =
  | {
      headerName?: string;
      description?: string | null;
    }
  | {
      headerName: string;
      description: string;
    };

export function renameFundFieldsToLineOfBusiness<T extends FinanceFieldLike>(
  fields: Array<T>
): Array<T> {
  return fields.map((field) => {
    let ret = field;
    if (field.headerName && FUND_RE.test(field.headerName)) {
      ret = {
        ...ret,
        headerName: renameFundToLineOfBusiness(field.headerName),
      };
    }
    if (field.description && FUND_RE.test(field.description)) {
      ret = {
        ...ret,
        description: renameFundToLineOfBusiness(field.description),
      };
    }
    return ret;
  });
}

export const allInputs: Array<FinanceField> = [
  // Fund
  {
    id: InputKey.fundGroup,
    headerName: 'Fund group',
    columnFormat: GQGridColumnFormat.String,
    description: `A collection of funds.`,
    width: 140,
  },
  {
    id: InputKey.fundCategory,
    headerName: 'Fund category',
    columnFormat: GQGridColumnFormat.String,
    description: `A collection of fund groups.`,
    width: 140,
  },
  {
    id: InputKey.excludeAsSandbox,
    headerName: 'Exclude as sandbox',
    columnFormat: GQGridColumnFormat.String,
    description: `Watershed will not include sandbox funds' data in your overall financed emissions calculations.`,
    width: 140,
  },
  // AbstractAsset
  {
    id: InputKey.assetClass,
    headerName: 'Asset class',
    columnFormat: GQGridColumnFormat.String,
    description: `The type of underlying financial exposure. We align asset classes with PCAF's definitions. Read more about PCAF asset classes <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    width: 140,
  },
  {
    id: InputKey.assetGroupId,
    headerName: 'Asset group ID',
    columnFormat: GQGridColumnFormat.String,
    description: `Watershed creates unique identifiers for each record you create. When re-uploading data, please keep those IDs in your template; they enable Watershed to correctly override older data uploads.`,
    width: 100,
  },
  {
    id: InputKey.estimationMethodology,
    headerName: 'Emission factors',
    columnFormat: GQGridColumnFormat.String,
    description: `The model used to estimate emissions. You can set a default methodology when you configure a footprint. Only provide emission factors in the template when you plan to customize. Read more about emissions factors <a target="_blank" href="${LINKS.learningHubMethodology}">here</a>.`,
  },
  {
    id: InputKey.buildingSizeUnit,
    headerName: 'Building size unit',
    columnFormat: GQGridColumnFormat.String,
    description:
      'Unit of measurement for building area. Square feet or square meters. For corporate assets, if none is provided the model will default to square feet.',
  },
  {
    id: InputKey.countryAlpha2,
    headerName: 'Country',
    columnFormat: GQGridColumnFormat.String,
    description: `An asset’s primary country of operation. If the asset operates in multiple countries, choose the one with the highest percentage of business activity. Required to estimate total emissions.`,
    width: 220,
  },
  {
    id: InputKey.sovereignEntity,
    headerName: 'Sovereign entity',
    columnFormat: GQGridColumnFormat.String,
    description: `The sovereign entity that issued the debt. Required to estimate total emissions.`,
    width: 220,
  },
  {
    id: InputKey.name,
    headerName: 'Asset name',
    columnFormat: GQGridColumnFormat.String,
    description: 'The name of the asset.',
    disableReorder: true,
    disableHide: false,
    disableResize: false,
    width: 220,
  },
  {
    id: InputKey.assetName,
    headerName: 'Asset name',
    columnFormat: GQGridColumnFormat.String,
    description: 'The name of the asset.',
    width: 220,
  },
  {
    id: InputKey.externalId,
    headerName: 'External ID',
    columnFormat: GQGridColumnFormat.String,
    description:
      'If this record has a unique identifier in a source system, you may include that here for later matching.',
  },

  // See DBAssetCorporate
  {
    id: InputKey.naicsCode,
    headerName: 'Industry',
    columnFormat: GQGridColumnFormat.String,
    description: `The industry of the asset which you provided. We support BEA, NAICS, NACE, GICS, SICS, ANZSICS, and UKSIC codes. Read more about the industry codes we use <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 250,
  },
  {
    id: InputKey.companyId,
    headerName: 'Watershed Company ID',
    columnFormat: GQGridColumnFormat.String,
    description:
      'Complete this field to match your asset with any of its publicly available climate data — such as a CDP report or SBTi commitment.',
    width: 250,
  },
  {
    id: InputKey.company,
    headerName: 'Watershed Company',
    columnFormat: GQGridColumnFormat.String,
    description:
      'Complete this field to match your asset with any of its publicly available climate data — such as a CDP report or SBTi commitment.',
    width: 250,
  },
  {
    id: InputKey.companyIsin,
    headerName: 'ISIN number',
    columnFormat: GQGridColumnFormat.String,
    description: `The International Securities Identification Number (ISIN) is a code that uniquely identifies a specific securities issue. This is used to help match the asset to Watershed's internal company climate data.`,
  },
  {
    id: InputKey.companyLei,
    headerName: 'LEI code',
    columnFormat: GQGridColumnFormat.String,
    description: `The Legal Entity Identifier (LEI) is a unique identifier for entities engaging in financial transactions. This is used to help match the asset to Watershed's internal company climate data.`,
  },
  {
    id: InputKey.companyTicker,
    headerName: 'Company stock ticker',
    columnFormat: GQGridColumnFormat.String,
    description: `The ticker symbol is an abbreviation used to uniquely identify publicly traded shares of a particular stock on a particular stock market. This is used to help match the asset to Watershed's internal company climate data.`,
  },
  {
    id: InputKey.companyBea,
    headerName: 'BEA code',
    columnFormat: GQGridColumnFormat.String,
    description: `The Bureau of Economic Analysis (BEA) code assigned to a company. This is used to help match the asset to Watershed's internal company climate data.`,
  },
  {
    id: InputKey.companyUrl,
    headerName: 'Company website URL',
    columnFormat: GQGridColumnFormat.String,
    description: `The URL is the address of the company's website. This is used to help match the asset to Watershed's internal company climate data.`,
  },
  {
    id: InputKey.companySAndPId,
    headerName: 'S&P identifier',
    columnFormat: GQGridColumnFormat.String,
    description: `The S&P ID is the identifier for the company in the S&P Global database. This is used to help match the asset to Watershed's internal company climate data.`,
  },
  {
    id: InputKey.companyDunsNumber,
    headerName: 'DUNS number',
    columnFormat: GQGridColumnFormat.String,
    description: `The Dun & Bradstreet (D&B) Number is a unique identifier for a business entity. This is used to help match the asset to Watershed's internal company climate data.`,
  },
  {
    id: InputKey.companyEin,
    headerName: 'EIN number',
    columnFormat: GQGridColumnFormat.String,
    description: `The Employer Identification Number (EIN) is a unique identifier for a business entity. This is used to help match the asset to Watershed's internal company climate data.`,
  },
  {
    id: InputKey.companyVatId,
    headerName: 'VAT identifier',
    columnFormat: GQGridColumnFormat.String,
    description: `The VAT ID is a unique identifier for a business entity. This is used to help match the asset to Watershed's internal company climate data.`,
  },
  {
    id: InputKey.companyLocalRegistryId,
    headerName: 'Local registry identifier',
    columnFormat: GQGridColumnFormat.String,
    description: `The local registry ID is a unique identifier for a business entity. This is used to help match the asset to Watershed's internal company climate data.`,
  },

  // See DBAssetCommercialRealEstate
  {
    id: InputKey.buildingKind,
    headerName: 'Building kind',
    columnFormat: GQGridColumnFormat.String,
    description: 'The type of building (for commercial real estate assets).',
  },
  {
    id: InputKey.buildingSubkind,
    headerName: 'Building subkind',
    columnFormat: GQGridColumnFormat.String,
    description:
      'The subclassification of building type (for commercial real estate assets).',
  },
  {
    id: InputKey.city,
    headerName: 'City',
    columnFormat: GQGridColumnFormat.String,
    description:
      'The city in which the building is located (for commercial real estate assets).',
  },
  {
    id: InputKey.postalCode,
    headerName: 'Postal code',
    columnFormat: GQGridColumnFormat.String,
    description:
      'The postal code of the building (for commercial real estate assets).',
  },
  {
    id: InputKey.state,
    headerName: 'State',
    columnFormat: GQGridColumnFormat.String,
    description:
      'The state in which the building is located (for commercial real estate assets).',
  },
  {
    id: InputKey.streetAddress,
    headerName: 'Street address',
    columnFormat: GQGridColumnFormat.String,
    description:
      'The street address of the building (for commercial real estate assets).',
  },
  {
    id: InputKey.streetAddress2,
    headerName: 'Floor, suite, etc.',
    columnFormat: GQGridColumnFormat.String,
    description:
      'The second address line of the building (for commercial real estate assets).',
  },

  // See DBAssetYear
  {
    id: InputKey.buildingSizeNative,
    headerName: 'Total building area',
    columnFormat: GQGridColumnFormat.Integer,
    description: `Square area (feet or meters) for all buildings that are used in business activities. Read more about how we use this data point for company estimations <a target="_blank" href="${LINKS.learningHubCEE}">here</a>, and commercial real estate estimations <a target="_blank" href="${LINKS.learningHubRealEstate}">here</a>.`,
    width: 150,
  },
  {
    id: InputKey.currencyCode,
    headerName: 'Currency',
    columnFormat: GQGridColumnFormat.String,
    description: `The currency in which you provided this record's financial data (Revenue and asset value for annual data; Outstanding amount for holdings).`,
    width: 220,
  },
  {
    id: InputKey.headcount,
    headerName: 'Headcount',
    columnFormat: GQGridColumnFormat.Integer,
    description: `Total number of full-time employees of the asset in the specified year. Read more about how we use this data point <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 105,
  },
  {
    id: InputKey.percentRenewable,
    headerName: 'Renewable electricity %',
    columnFormat: GQGridColumnFormat.Percentage,
    description: `The percentage of clean power that an asset purchases (e.g., through EACs or a VPPA) that is not from the grid. Read more about how we use this data point <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 150,
  },
  {
    id: InputKey.revenueNative,
    headerName: 'Revenue',
    columnFormat: GQGridColumnFormat.Currency,
    description: `Annual revenue of the asset for the specified year. Read more about how we use this data point <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 105,
  },
  {
    id: InputKey.cloudSpendNative,
    headerName: 'Cloud spend',
    columnFormat: GQGridColumnFormat.Currency,
    description: 'Annual cloud spend of the asset for the specified year.',
    width: 105,
  },
  {
    id: InputKey.flightMiles,
    headerName: 'Flight miles',
    columnFormat: GQGridColumnFormat.Integer,
    description: `Total mileage from business travel flights in the specified year. Read more about how we use this data point <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 105,
  },
  {
    id: InputKey.percentEmployeesWfh,
    headerName: 'Work-from-home %',
    columnFormat: GQGridColumnFormat.Percentage,
    description: `The percentage of full-time employees that work from home/remotely. This data point only affects your estimate if you also provide headcount. Read more about how we use this data point <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 105,
  },
  {
    id: InputKey.scope1OverrideTco2e,
    headerName: 'Scope 1 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 1 includes the direct release of greenhouse gases from sources a company owns or controls (e.g. company-owned vehicles or furnaces). Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope1OverridePcaf,
    headerName: 'Scope 1 override PCAF score',
    columnFormat: GQGridColumnFormat.Integer,
    description: `PCAF score representing the quality of the Scope 1 override (e.g., estimate, actual, etc.)`,
    width: 105,
  },
  {
    id: InputKey.scope2LocationOverrideTco2e,
    headerName: 'Scope 2, location-based override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 2 includes emissions from the generation of electricity, steam, heat, or cooling that a company purchases (e.g., from a power utility). A location-based <a target="_blank" href="${LINKS.ghgProtocolScope2}">method</a> reflects the average emissions intensity of grids on which energy consumption occurs (using mostly grid-average emission factor data). Scope overrides will replace estimated scopes.`,
    width: 150,
  },
  {
    id: InputKey.scope2LocationOverridePcaf,
    headerName: 'Scope 2, location-based override PCAF score',
    columnFormat: GQGridColumnFormat.Integer,
    description: `PCAF score representing the quality of the Scope 2, location-based override (e.g., estimate, actual, etc.)`,
    width: 105,
  },
  {
    id: InputKey.scope2MarketOverrideTco2e,
    headerName: 'Scope 2, market-based override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 2 includes emissions from the generation of electricity, steam, heat, or cooling that a company purchases (e.g., from a power utility). A market-based <a target="_blank" href="${LINKS.ghgProtocolScope2}">method</a> reflects emissions from electricity that companies have purposefully chosen (or their lack of choice). It derives emission factors from contractual instruments such as EACs or VPPAs. Scope overrides will replace estimated scopes.`,
    width: 150,
  },
  {
    id: InputKey.scope2MarketOverridePcaf,
    headerName: 'Scope 2, market-based override PCAF score',
    columnFormat: GQGridColumnFormat.Integer,
    description: `PCAF score representing the quality of the Scope 2, market-based override (e.g., estimate, actual, etc.)`,
    width: 105,
  },
  {
    id: InputKey.scope3OverrideKind,
    headerName: 'Scope 3 override kind',
    columnFormat: GQGridColumnFormat.String,
    description: `The override columns used for scope 3 calculation. Please choose between overall and subscope. Required if any Scope 3 or Subscope 3 overrides are provided. Any Scope 3-related overrides that don't match the Scope 3 override kind are ignored.`,
  },
  {
    id: InputKey.scope301OverrideTco2e,
    headerName: 'Scope 3.1 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.1 includes emissions from purchased goods and services, such as raw materials, cloud usage, or consulting services. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope302OverrideTco2e,
    headerName: 'Scope 3.2 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.2 includes emissions from the production of capital goods that a company purchases, such as machinery, buildings, or vehicles. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope303OverrideTco2e,
    headerName: 'Scope 3.3 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.3 includes emissions related to the production of fuels and energy purchased and consumed by a company that are not included in scope 1 or scope 2 (e.g., electricity transmission and distribution loss). See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope304OverrideTco2e,
    headerName: 'Scope 3.4 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.4 includes emissions from upstream transportation and distribution of products. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope305OverrideTco2e,
    headerName: 'Scope 3.5 override (tCO2e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.5 includes emissions from third-party disposal and treatment of waste generated by a company’s owned or controlled operations, including solid waste and wastewater. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope306OverrideTco2e,
    headerName: 'Scope 3.6 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.6 includes emissions from a company's business travel, including air travel, rail travel, auto travel, bus travel, and hotel stays. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope307OverrideTco2e,
    headerName: 'Scope 3.7 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.7 includes emissions from employees' commute and remote energy use (e.g., home office electricity usage). See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope308OverrideTco2e,
    headerName: 'Scope 3.8 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.8 includes emissions from the operation of assets that are leased by a company and are not already included in its scope 1 or scope 2 inventories, for example, if a company is a lessee in a WeWork. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope309OverrideTco2e,
    headerName: 'Scope 3.9 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.9 includes emissions from downstream transportation and distribution of products. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope310OverrideTco2e,
    headerName: 'Scope 3.10 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.10 emissions from the processing of sold intermediate products by third parties (e.g., manufacturers) subsequent to sale by the reporting company. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope311OverrideTco2e,
    headerName: 'Scope 3.11 override (tCO2e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.11 includes emissions from the use of a good or service by end consumers (e.g., car manufacturers count the emissions from people driving their cars in this scope). See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope312OverrideTco2e,
    headerName: 'Scope 3.12 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.12 includes emissions from end-of-life treatment of products sold by a company. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope313OverrideTco2e,
    headerName: 'Scope 3.13 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.13 includes emissions from the operation of assets that are owned by a company (acting as lessor) and leased to other entities that are not already included in scope 1 or scope 2. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope314OverrideTco2e,
    headerName: 'Scope 3.14 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.14 includes emissions from the operation of franchises not included in scope 1 or scope 2 (e.g., a large chain restaurant's franchise emissions). See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope315OverrideTco2e,
    headerName: 'Scope 3.15 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3.15 includes emissions from investing and lending activities. See <a target="_blank" href="${LINKS.ghgProtocolScope3}">here</a> for more details. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: InputKey.scope3OverrideTco2e,
    headerName: 'Scope 3 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description:
      'Total scope 3 emissions. Scope overrides will replace estimated scopes.',
    width: 105,
  },
  {
    id: InputKey.scope3OverridePcaf,
    headerName: 'Scope 3 override PCAF score',
    columnFormat: GQGridColumnFormat.Integer,
    description: `PCAF score representing the quality of the Scope 3 override (e.g., estimate, actual, etc.)`,
    width: 105,
  },
  {
    id: InputKey.totalMwh,
    headerName: 'Total electricity consumption (MWh)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Total electricity consumption of this asset (MWh) in the specified year. Read more about how we use this data point <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 105,
  },
  {
    id: InputKey.valueNative,
    headerName: 'Asset value',
    columnFormat: GQGridColumnFormat.Currency,
    description: `Required to estimate financed emissions for some asset classes. For companies, this is enterprise value at end of year. For commercial real estate, this is property value at origination. Read the PCAF-aligned definitions of these terms for companies <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a> and commercial real estate <a target="_blank" href="${LINKS.learningHubRealEstate}">here</a>.`,
    width: 126,
  },
  {
    id: InputKey.year,
    headerName: 'Year',
    columnFormat: GQGridColumnFormat.Integer,
    description:
      'The year corresponding to the revenue, asset value, and similar financial measures.',
    width: 100,
  },

  // AssetHolding
  {
    id: InputKey.fundId,
    headerName: 'Fund',
    columnFormat: GQGridColumnFormat.String,
    description:
      'If your assets are divided among multiple funds, select the fund that you want to add this row to.',
    width: 150,
  },
  {
    id: InputKey.assetYearId,
    headerName: 'Fund',
    columnFormat: GQGridColumnFormat.String,
    description:
      'Watershed creates unique identifiers for each asset annual data record you create. When re-uploading data, please keep those IDs in your template; they enable Watershed to correctly override older data uploads.',
    width: 150,
  },
  {
    id: InputKey.yearOfInitialInvestment,
    headerName: 'Initial investment year',
    columnFormat: GQGridColumnFormat.Integer,
    description:
      'The year in which the asset was first acquired or invested in.',
    width: 150,
  },
  {
    id: InputKey.outstandingAmountNative,
    headerName: 'Outstanding amount',
    columnFormat: GQGridColumnFormat.Currency,
    description: `Required to estimate financed emissions for some asset classes. The amount of a firm's investment or loan. For sovereign debt, this is the face value of the bond. Used to calculate the attribution factor. Read the PCAF-aligned definitions of this term for companies <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a> and commercial real estate <a target="_blank" href="${LINKS.learningHubRealEstate}">here</a>.`,
    width: 164,
  },
  {
    id: InputKey.outstandingAmountNativeQ1,
    headerName: 'Outstanding amount Q1',
    columnFormat: GQGridColumnFormat.Currency,
    description: `The outstanding amount of an investment or loan, as of the end of Q1 (March 31). Read the PCAF-aligned definitions of these terms for companies <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a> and commercial real estate <a target="_blank" href="${LINKS.learningHubRealEstate}">here</a>.`,
    width: 164,
  },
  {
    id: InputKey.outstandingAmountNativeQ2,
    headerName: 'Outstanding amount Q2',
    columnFormat: GQGridColumnFormat.Currency,
    description: `The outstanding amount of an investment or loan, as of the end of Q2 (June 30). Read the PCAF-aligned definitions of these terms for companies <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a> and commercial real estate <a target="_blank" href="${LINKS.learningHubRealEstate}">here</a>.`,
    width: 164,
  },
  {
    id: InputKey.outstandingAmountNativeQ3,
    headerName: 'Outstanding amount Q3',
    columnFormat: GQGridColumnFormat.Currency,
    description: `The outstanding amount of an investment or loan, as of the end of Q3 (September 30). Read the PCAF-aligned definitions of these terms for companies <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a> and commercial real estate <a target="_blank" href="${LINKS.learningHubRealEstate}">here</a>.`,
    width: 164,
  },
  {
    id: InputKey.outstandingAmountNativeQ4,
    headerName: 'Outstanding amount Q4',
    columnFormat: GQGridColumnFormat.Currency,
    description: `The outstanding amount of an investment or loan, as of the end of Q4 (December 31). Read the PCAF-aligned definitions of these terms for companies <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a> and commercial real estate <a target="_blank" href="${LINKS.learningHubRealEstate}">here</a>.`,
    width: 164,
  },
  {
    id: InputKey.premiumNative,
    headerName: 'Premium',
    columnFormat: GQGridColumnFormat.Currency,
    description: `The amount paid for an insurance policy. This is used to calculate the attribution factor for commercial lines of insurance.`,
    width: 164,
  },
  {
    id: InputKey.contacts,
    headerName: 'Points of contact',
    columnFormat: GQGridColumnFormat.String,
    description: 'Points of contact for the asset.',
    width: 164,
  },
  {
    id: InputKey.contactName,
    headerName: 'Point of contact name',
    columnFormat: GQGridColumnFormat.String,
    description: 'Primary point of contact for the asset.',
    width: 164,
  },
  {
    id: InputKey.contactEmail,
    headerName: 'Point of contact email',
    columnFormat: GQGridColumnFormat.String,
    description: 'Primary point of contact email for the asset.',
    width: 164,
  },
  {
    id: InputKey.contactRole,
    headerName: 'Point of contact role',
    columnFormat: GQGridColumnFormat.String,
    description: 'Primary point of contact role for the asset.',
    width: 164,
  },
  {
    id: InputKey.role,
    headerName: 'Point of contact role',
    columnFormat: GQGridColumnFormat.String,
    description: `The point of contact's role.`,
    width: 164,
  },
  {
    id: InputKey.email,
    headerName: 'Point of contact role',
    columnFormat: GQGridColumnFormat.String,
    description: `The point of contact's email.`,
    width: 164,
  },
  // changelog
  {
    id: InputKey.scope1Kgco2e,
    headerName: 'Scope 1 (kgCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: 'Scope 1 kgCO₂e override',
    width: 105,
  },
  {
    id: InputKey.scope2LocationKgco2e,
    headerName: 'Scope 2 Location (kgCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: 'Scope 2 Location kgCO₂e override',
    width: 150,
  },
  {
    id: InputKey.scope2MarketKgco2e,
    headerName: 'Scope 2 Market (kgCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: 'Scope 2 Market kgCO₂e override',
    width: 140,
  },
  {
    id: InputKey.scope3Kgco2e,
    headerName: 'Scope 3 (kgCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: 'Scope 3 kgCO₂e override',
    width: 110,
  },
  {
    id: InputKey.country,
    headerName: 'Country',
    columnFormat: GQGridColumnFormat.Country,
    description: 'Country of building',
    width: 125,
  },
  {
    id: InputKey.sizeUnit,
    headerName: 'Size unit',
    columnFormat: GQGridColumnFormat.String,
    description: 'Units used for building size',
    width: 125,
  },
  {
    id: InputKey.buildingName,
    headerName: 'Building name',
    columnFormat: GQGridColumnFormat.String,
    description: 'Name of building for asset real estate',
    width: 125,
  },

  // bulk import fields
  {
    id: InputKey.fundName,
    headerName: 'Fund name',
    columnFormat: GQGridColumnFormat.String,
    description: 'Name of fund to include holding in.',
    width: 125,
  },
  {
    id: InputKey.assetGroupName,
    headerName: 'Asset group name',
    columnFormat: GQGridColumnFormat.String,
    description: 'Name of asset group to include asset in.',
    width: 100,
  },
  {
    id: InputKey.id,
    headerName: 'ID',
    columnFormat: GQGridColumnFormat.String,
    description:
      'Watershed creates unique identifiers for each record you create. When re-uploading data, please keep those IDs in your template; they enable Watershed to correctly override older data uploads.',
    width: 125,
  },
  {
    id: InputKey.assetCorporateId,
    headerName: 'Corporate asset ID',
    columnFormat: GQGridColumnFormat.String,
    description:
      'Watershed creates unique identifiers for each asset you create. When re-uploading data, please keep those IDs in your template; they enable Watershed to correctly override older data uploads.',
    width: 125,
  },
  {
    id: InputKey.assetCommercialRealEstateId,
    headerName: 'Corporate asset ID',
    columnFormat: GQGridColumnFormat.String,
    description:
      'Watershed creates unique identifiers for each asset you create. When re-uploading data, please keep those IDs in your template; they enable Watershed to correctly override older data uploads.',
    width: 125,
  },
  {
    id: InputKey.measuringScope1And2Emissions,
    headerName: 'Measuring Scope 1 & 2 emissions?',
    columnFormat: GQGridColumnFormat.NullableCheckbox,
    description: `Whether the asset is measuring Scope 1 & 2 emissions from operations and making data available to the fund. This input helps determine this company’s climate maturity, as defined by the Initiative Climate International’s (iCI) <a target="_blank" href="${LINKS.pmdrRoadmap}">Private Markets Decarbonization Roadmap</a> (PMDR).`,
    width: 125,
  },
  {
    id: InputKey.measuringMaterialScope3Emissions,
    headerName: 'Measuring material Scope 3 emissions?',
    columnFormat: GQGridColumnFormat.NullableCheckbox,
    description: `Whether the asset is measuring material Scope 3 emissions from operations and making data available to the fund. This input helps determine this company’s climate maturity, as defined by the Initiative Climate International’s (iCI) <a target="_blank" href="${LINKS.pmdrRoadmap}">Private Markets Decarbonization Roadmap</a> (PMDR).`,
    width: 125,
  },
  {
    id: InputKey.initiatedDecarbonizationPlan,
    headerName: 'Initiated decarbonization plan?',
    columnFormat: GQGridColumnFormat.NullableCheckbox,
    description: `Whether the asset has initiated a decarbonization plan to reduce emissions (intensity) in line with an approach agreed with the fund. This input helps determine this company’s climate maturity, as defined by the Initiative Climate International’s (iCI) <a target="_blank" href="${LINKS.pmdrRoadmap}">Private Markets Decarbonization Roadmap</a> (PMDR).`,
    width: 125,
  },
  {
    id: InputKey.committedToNearTermSbtAlignedNetZero,
    headerName:
      'Committed to a near-term science-based target aligned to a net zero pathway?',
    columnFormat: GQGridColumnFormat.NullableCheckbox,
    description: `Whether the asset has committed to a near-term science-based target aligned to a net zero pathway. This input helps determine this company’s climate maturity, as defined by the Initiative Climate International’s (iCI) <a target="_blank" href="${LINKS.pmdrRoadmap}">Private Markets Decarbonization Roadmap</a> (PMDR).`,
    width: 125,
  },
  {
    id: InputKey.demonstratingYoyEmissionsInLineWithNetZero,
    headerName:
      'Demonstrating a year on year emissions profile in line with the net zero pathway?',
    columnFormat: GQGridColumnFormat.NullableCheckbox,
    description: `Whether the asset is demonstrating a year on year emissions profile in line with the net zero pathway. This input helps determine this company’s climate maturity, as defined by the Initiative Climate International’s (iCI) <a target="_blank" href="${LINKS.pmdrRoadmap}">Private Markets Decarbonization Roadmap</a> (PMDR).`,
    width: 125,
  },
  {
    id: InputKey.iciAlignmentOverride,
    headerName: 'iCI alignment status override',
    columnFormat: GQGridColumnFormat.String,
    description: `iCI alignment status override. This input helps determine this company’s climate maturity, as defined by the Initiative Climate International’s (iCI) <a target="_blank" href="${LINKS.pmdrRoadmap}">Private Markets Decarbonization Roadmap</a> (PMDR).`,
    width: 125,
  },
].map((field) => ({
  // Include default values
  columnConfiguration: null,
  editable: true,
  field: field.id,
  width: null,
  disableHide: false,
  disableReorder: false,
  disableResize: false,
  ...field,
}));

export enum OutputKey {
  name = 'name',
  fundIds = 'fundIds',
  assetName = 'assetName',
  assetCategory = 'assetCategory',
  assetId = 'assetId',
  attributionFactor = 'attributionFactor',
  financedEmissions = 'financedEmissions',
  financedEmissionsScope1 = 'financedEmissionsScope1',
  financedEmissionsScope1WithLulucf = 'financedEmissionsScope1WithLulucf',
  financedEmissionsScope1WithoutLulucf = 'financedEmissionsScope1WithoutLulucf',
  financedEmissionsScope2 = 'financedEmissionsScope2',
  financedEmissionsScope3 = 'financedEmissionsScope3',
  financedEmissionsScope2Market = 'financedEmissionsScope2Market',
  financedEmissionsScope2Location = 'financedEmissionsScope2Location',
  financedEmissionsScope301 = 'financedEmissionsScope301',
  financedEmissionsScope302 = 'financedEmissionsScope302',
  financedEmissionsScope303 = 'financedEmissionsScope303',
  financedEmissionsScope304 = 'financedEmissionsScope304',
  financedEmissionsScope305 = 'financedEmissionsScope305',
  financedEmissionsScope306 = 'financedEmissionsScope306',
  financedEmissionsScope307 = 'financedEmissionsScope307',
  financedEmissionsScope308 = 'financedEmissionsScope308',
  financedEmissionsScope309 = 'financedEmissionsScope309',
  financedEmissionsScope310 = 'financedEmissionsScope310',
  financedEmissionsScope311 = 'financedEmissionsScope311',
  financedEmissionsScope312 = 'financedEmissionsScope312',
  financedEmissionsScope313 = 'financedEmissionsScope313',
  financedEmissionsScope314 = 'financedEmissionsScope314',
  financedEmissionsScope315 = 'financedEmissionsScope315',
  financedEmissionsScope3OtherUpstream = 'financedEmissionsScope3OtherUpstream',
  financedEmissionsScope3OtherDownstream = 'financedEmissionsScope3OtherDownstream',
  financedEmissionsScope1And2 = 'financedEmissionsScope1And2',
  economicIntensity = 'economicIntensity',
  economicIntensityDisplay = 'economicIntensityDisplay',
  economicIntensityScope1And2 = 'economicIntensityScope1And2',
  revenueIntensity = 'revenueIntensity',
  revenueIntensityDisplay = 'revenueIntensityDisplay',
  // CURRENCY DALLAS TODO: do we need to update these?
  revenueIntensityScope1 = 'revenueIntensityScope1',
  revenueIntensityScope2 = 'revenueIntensityScope2',
  revenueIntensityScope3 = 'revenueIntensityScope3',

  financedRevenueIntensityScope1 = 'financedRevenueIntensityScope1',
  financedRevenueIntensityScope2 = 'financedRevenueIntensityScope2',
  financedRevenueIntensityScope3 = 'financedRevenueIntensityScope3',
  waci = 'waci',
  waciDisplay = 'waciDisplay',
  waciScope1 = 'waciScope1',
  waciScope2 = 'waciScope2',
  waciScope3 = 'waciScope3',
  pcaf = 'pcaf',
  pcafScoreScope1 = 'pcafScoreScope1',
  pcafScoreScope2 = 'pcafScoreScope2',
  pcafScoreScope3 = 'pcafScoreScope3',
  financedEmissionsPcafScoreScope1 = 'financedEmissionsPcafScoreScope1',
  financedEmissionsPcafScoreScope2 = 'financedEmissionsPcafScoreScope2',
  financedEmissionsPcafScoreScope3 = 'financedEmissionsPcafScoreScope3',
  pcafWeightedByEmissions = 'pcafWeightedByEmissions',
  totalEmissions = 'totalEmissions',
  totalEmissionsScope1 = 'totalEmissionsScope1',
  totalEmissionsScope2 = 'totalEmissionsScope2',
  totalEmissionsScope3 = 'totalEmissionsScope3',
  totalEmissionsScope1WithLulucf = 'totalEmissionsScope1WithLulucf',
  totalEmissionsScope1WithoutLulucf = 'totalEmissionsScope1WithoutLulucf',
  totalEmissionsScope2Market = 'totalEmissionsScope2Market',
  totalEmissionsScope2Location = 'totalEmissionsScope2Location',
  totalEmissionsScope301 = 'totalEmissionsScope301',
  totalEmissionsScope302 = 'totalEmissionsScope302',
  totalEmissionsScope303 = 'totalEmissionsScope303',
  totalEmissionsScope304 = 'totalEmissionsScope304',
  totalEmissionsScope305 = 'totalEmissionsScope305',
  totalEmissionsScope306 = 'totalEmissionsScope306',
  totalEmissionsScope307 = 'totalEmissionsScope307',
  totalEmissionsScope308 = 'totalEmissionsScope308',
  totalEmissionsScope309 = 'totalEmissionsScope309',
  totalEmissionsScope310 = 'totalEmissionsScope310',
  totalEmissionsScope311 = 'totalEmissionsScope311',
  totalEmissionsScope312 = 'totalEmissionsScope312',
  totalEmissionsScope313 = 'totalEmissionsScope313',
  totalEmissionsScope314 = 'totalEmissionsScope314',
  totalEmissionsScope315 = 'totalEmissionsScope315',
  totalEmissionsScope3OtherUpstream = 'totalEmissionsScope3OtherUpstream',
  totalEmissionsScope3OtherDownstream = 'totalEmissionsScope3OtherDownstream',
  totalEmissionsScope1And2 = 'totalEmissionsScope1And2',

  footprintSource = 'footprintSource',
  disclosureSource = 'disclosureSource',
  revenueSourceLabel = 'revenueSourceLabel',
  climateProgress = 'climateProgress',
  sbtCommitmentStage = 'sbtCommitmentStage',
  iigccStatus = 'iigccStatus',

  currencyConversionRate = 'currencyConversionRate',
  revenueUsd = 'revenueUsd',
  valueUsd = 'valueUsd',
  outstandingAmountUsd = 'outstandingAmountUsd',
  outstandingAmountUsdMetric = 'outstandingAmountUsdMetric',
  outstandingAmountDisplay = 'outstandingAmountDisplay',
  valueTarget = 'valueTarget',
  valueDisplay = 'valueDisplay',
  revenueTarget = 'revenueTarget',
  revenueDisplay = 'revenueDisplay',
  outstandingAmountTarget = 'outstandingAmountTarget',

  // data collection: survey
  surveyStatus = 'surveyStatus',
  surveyPortalUrl = 'surveyPortalUrl',

  // data collection: estimation
  estimationStatus = 'estimationStatus',

  // data collection: measurement
  msmtStatus = 'msmtStatus',
  msmtSnapshotAt = 'msmtSnapshotAt',
  msmtProjectName = 'msmtProjectName',
  msmtDCKickoff = 'msmtDCKickoff',
  msmtDCEnd = 'msmtDCEnd',
  msmtDraftFPDate = 'msmtDraftFPDate',
  msmtFPApprovalDate = 'msmtFPApprovalDate',
  msmtEstimateFPApprovalDate = 'msmtEstimateFPApprovalDate',
  msmtCoverageStartDate = 'msmtCoverageStartDate',
  msmtCoverageEndDate = 'msmtCoverageEndDate',
  msmtCompletedAt = 'msmtCompletedAt',
  msmtTimelineSource = 'msmtTimelineSource',
  msmtCollectedCompletedDatasets = 'msmtCollectedCompletedDatasets',
  msmtCollectedExpectedCompletedDatasets = 'msmtCollectedExpectedCompletedDatasets',
  msmtReviewProcessingCompletedDatasets = 'msmtReviewProcessingCompletedDatasets',
  msmtReviewProcessingExpectedCompletedDatasets = 'msmtReviewProcessingExpectedCompletedDatasets',
  msmtTotalDatasets = 'msmtTotalDatasets',

  // data flags
  revenueMultiple = 'revenueMultiple',
  scope1And2PortionOfEmissions = 'scope1And2PortionOfEmissions',
  outstandingAmountPortion = 'outstandingAmountPortion',

  // inputs as snapshot outputs
  inputsScope1Tco2e = 'inputsScope1Tco2e',
  inputsScope1Pcaf = 'inputsScope1Pcaf',
  inputsScope2LocationTco2e = 'inputsScope2LocationTco2e',
  inputsScope2LocationPcaf = 'inputsScope2LocationPcaf',
  inputsScope2MarketTco2e = 'inputsScope2MarketTco2e',
  inputsScope2MarketPcaf = 'inputsScope2MarketPcaf',
  inputsScope3OverrideKind = 'inputsScope3OverrideKind',
  inputsScope3Tco2e = 'inputsScope3Tco2e',
  inputsScope3Pcaf = 'inputsScope3Pcaf',
  inputsScope301Tco2e = 'inputsScope301Tco2e',
  inputsScope302Tco2e = 'inputsScope302Tco2e',
  inputsScope303Tco2e = 'inputsScope303Tco2e',
  inputsScope304Tco2e = 'inputsScope304Tco2e',
  inputsScope305Tco2e = 'inputsScope305Tco2e',
  inputsScope306Tco2e = 'inputsScope306Tco2e',
  inputsScope307Tco2e = 'inputsScope307Tco2e',
  inputsScope308Tco2e = 'inputsScope308Tco2e',
  inputsScope309Tco2e = 'inputsScope309Tco2e',
  inputsScope310Tco2e = 'inputsScope310Tco2e',
  inputsScope311Tco2e = 'inputsScope311Tco2e',
  inputsScope312Tco2e = 'inputsScope312Tco2e',
  inputsScope313Tco2e = 'inputsScope313Tco2e',
  inputsScope314Tco2e = 'inputsScope314Tco2e',
  inputsScope315Tco2e = 'inputsScope315Tco2e',

  inputsTotalMwh = 'inputsTotalMwh',

  // Industry outputs
  industryLabel = 'industryLabel',
  industrySectorLabel = 'industrySectorLabel',
  industrySectorCode = 'industrySectorCode',

  holdingCount = 'holdingCount',
  assetCount = 'assetCount',

  // ICI alignment
  iciAlignmentStatus = 'iciAlignmentStatus',

  // Custom fields for data flags
  priorYearTotalEmissions = 'priorYearTotalEmissions',
  priorYearRevenueNative = 'priorYearRevenueNative',
  percentageChange = 'percentageChange',
  priorYearValueNative = 'priorYearValueNative',
  priorYearFootprintSource = 'priorYearFootprintSource',
  priorYearRevenueIntensity = 'priorYearRevenueIntensity',
  priorYearFinancedEmissions = 'priorYearFinancedEmissions',
  priorYearOutstandingAmountNative = 'priorYearOutstandingAmountNative',
}
export type OutputKeyValue = keyof typeof OutputKey;

export const AllFieldKeys = [
  ...Object.values(InputKey),
  ...Object.values(OutputKey),
];

export const allOutputs: Array<FinanceField> = [
  {
    id: OutputKey.name,
    headerName: 'Asset name',
    columnFormat: GQGridColumnFormat.String,
    description: 'The name of the asset.',
    width: 200,
  },
  {
    id: OutputKey.assetName,
    headerName: 'Asset name',
    columnFormat: GQGridColumnFormat.String,
    description: 'The name of the asset.',
    width: 200,
  },
  {
    id: OutputKey.assetCategory,
    headerName: 'Asset category',
    columnFormat: GQGridColumnFormat.String,
    description: 'The category of the asset.',
    width: 200,
  },
  {
    id: OutputKey.assetId,
    headerName: 'Asset ID',
    columnFormat: GQGridColumnFormat.String,
    description: 'The Watershed ID of the asset.',
    width: 220,
  },
  {
    id: OutputKey.fundIds,
    headerName: 'Funds',
    description: 'The fund or funds to which the asset belongs.',
    columnFormat: GQGridColumnFormat.String,
  },
  {
    id: OutputKey.attributionFactor,
    headerName: 'Attribution factor',
    description: `A financial institution's share of an asset. It is calculated as the outstanding amount of loans or investments over the asset value in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Percentage,
    width: 160,
  },
  {
    id: OutputKey.financedEmissions,
    headerName: 'Financed emissions: All scopes',
    description: `Emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a> (<a target="_blank" href="${LINKS.learningHubFinancedEmissions}">Learn more</a>). Percentage of fund's emissions are based only on the assets accounted for in Watershed.`,
    equation: `Total emissions * Attribution factor = Attributed financed emissions; Attributed financed emissions + Directly calculated financed emissions = Financed emissions`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 245,
  },
  {
    id: OutputKey.financedEmissionsScope1,
    headerName: 'Financed emissions: Scope 1',
    description: `Scope 1 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>. For sovereign debt, this will prioritize scope 1 emissions with land use, land use change, and forestry.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope1WithLulucf,
    headerName: 'Financed emissions: Scope 1 with LULUCF',
    description: `Scope 1 emissions of the sovereign entity multiplied by the financial institution's percentage ownership including land use, land use change, and forestry, expressed in tCO₂e. Only relevant for sovereign debt.`,
    columnFormat: GQGridColumnFormat.Metric,
  },
  {
    id: OutputKey.financedEmissionsScope1WithoutLulucf,
    headerName: 'Financed emissions: Scope 1 without LULUCF',
    description: `Scope 1 emissions of the sovereign entity multiplied by the financial institution's percentage ownership excluding land use, land use change, and forestry, expressed in tCO₂e. Only relevant for sovereign debt.`,
    columnFormat: GQGridColumnFormat.Metric,
  },
  {
    id: OutputKey.financedEmissionsScope2,
    headerName: 'Financed emissions: Scope 2',
    description: `Scope 2 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope3,
    headerName: 'Financed emissions: Scope 3',
    description: `Scope 3 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope2Market,
    headerName: 'Financed emissions: Scope 2 Market',
    description: `Scope 2 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope2Location,
    headerName: 'Financed emissions: Scope 2 Location',
    description: `Scope 2 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope301,
    headerName: 'Financed emissions: Scope 3.1',
    description: `Scope 3.1 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope302,
    headerName: 'Financed emissions: Scope 3.2',
    description: `Scope 3.2 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope303,
    headerName: 'Financed emissions: Scope 3.3',
    description: `Scope 3.3 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope304,
    headerName: 'Financed emissions: Scope 3.4',
    description: `Scope 3.4 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope305,
    headerName: 'Financed emissions: Scope 3.5',
    description: `Scope 3.5 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope306,
    headerName: 'Financed emissions: Scope 3.6',
    description: `Scope 3.6 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope307,
    headerName: 'Financed emissions: Scope 3.7',
    description: `Scope 3.7 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope308,
    headerName: 'Financed emissions: Scope 3.8',
    description: `Scope 3.8 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope309,
    headerName: 'Financed emissions: Scope 3.9',
    description: `Scope 3.9 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope310,
    headerName: 'Financed emissions: Scope 3.10',
    description: `Scope 3.10 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope311,
    headerName: 'Financed emissions: Scope 3.11',
    description: `Scope 3.11 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope312,
    headerName: 'Financed emissions: Scope 3.12',
    description: `Scope 3.12 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope313,
    headerName: 'Financed emissions: Scope 3.13',
    description: `Scope 3.13 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope314,
    headerName: 'Financed emissions: Scope 3.14',
    description: `Scope 3.14 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope315,
    headerName: 'Financed emissions: Scope 3.15',
    description: `Scope 3.15 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope3OtherUpstream,
    headerName: 'Financed emissions: Other Scope 3 Upstream',
    description: `Other scope 3 upstream emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope3OtherDownstream,
    headerName: 'Financed emissions: Other Scope 3 Downstream',
    description: `Other scope 3 downstream emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.financedEmissionsScope1And2,
    headerName: 'Financed emissions: Scope 1 and 2',
    description: `Scope 1 & 2 emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. See more details <a target="_blank" href="${LINKS.learningHubFinancedEmissions}">here</a>.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 260,
  },
  {
    id: OutputKey.economicIntensity,
    headerName: 'Economic intensity',
    titleFn: () => 'Economic intensity (USD)',
    description: `Financed emissions divided by the loan or outstanding amount in USD, expressed as tCO₂e/$M. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>.`,
    equation:
      'Financed emissions / Outstanding amount ($M) = Economic intensity',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.economicIntensityDisplay,
    headerName: 'Economic intensity',
    titleFn: buildCurrencyTitle('Economic intensity'),
    description: `Financed emissions divided by the loan or outstanding amount, expressed as tCO₂e/(millions of display currency) loaned or invested. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>.`,
    equation:
      'Financed emissions / Outstanding amount (millions) = Economic intensity',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.economicIntensityScope1And2,
    headerName: 'Economic intensity (scope 1 and 2)',
    description:
      'Absolute emissions (only including Scope 1 and Scope 2) divided by the investment or loan amount in USD million.',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.revenueIntensity,
    headerName: 'Revenue intensity',
    titleFn: () => 'Revenue intensity (USD)',
    description:
      'Total emissions divided by borrower or investee revenue in USD, expressed as tCO₂e/$M.',
    equation: 'Total emissions / Revenue ($M) = Revenue intensity',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.revenueIntensityDisplay,
    headerName: 'Revenue intensity',
    titleFn: buildCurrencyTitle('Revenue intensity'),
    description:
      'Total emissions divided by borrower or investee revenue, expressed as tCO₂e/(millions of display currency)',
    equation: 'Total emissions / Revenue (millions) = Revenue intensity',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.financedRevenueIntensityScope1,
    headerName: 'Revenue intensity: Scope 1',
    description:
      'Scope 1 total emissions per million of financed revenue. Also referred to as carbon intensity or carbon efficiency.',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.financedRevenueIntensityScope2,
    headerName: 'Revenue intensity: Scope 2',
    description: 'Scope 2 total emissions of an asset divided by its revenue.',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.financedRevenueIntensityScope3,
    headerName: 'Revenue intensity: Scope 3',
    description: 'Scope 3 total emissions of an asset divided by its revenue.',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.revenueIntensityScope1,
    headerName: 'Revenue intensity: Scope 1',
    description:
      'Scope 1 total emissions per million of financed revenue. Also referred to as carbon intensity or carbon efficiency.',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.revenueIntensityScope2,
    headerName: 'Revenue intensity: Scope 2',
    description: 'Scope 2 total emissions of an asset divided by its revenue.',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.revenueIntensityScope3,
    headerName: 'Revenue intensity: Scope 3',
    description: 'Scope 3 total emissions of an asset divided by its revenue.',
    columnFormat: GQGridColumnFormat.Metric,
    width: 175,
  },
  {
    id: OutputKey.waci,
    headerName: 'WACI',
    titleFn: () => 'WACI (USD)',
    description: `Portfolio’s exposure to emission-intensive companies, expressed as tCO₂e/$M company revenue. Calculated as the sum of each asset's scope 1, 2, and 3 revenue intensity, weighted by its proportion in the fund. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a> and <a target="_blank" href="${LINKS.tcfdMetrics}">TCFD</a>.`,
    equation:
      'WACI = Σ (Revenue intensity (Millions) * (Outstanding amount / Total outstanding amount))',
    columnFormat: GQGridColumnFormat.Metric,
  },
  {
    id: OutputKey.waciDisplay,
    headerName: 'WACI',
    titleFn: buildCurrencyTitle('WACI'),
    description: `Portfolio’s exposure to emission-intensive companies, expressed as tCO₂e/(millions of display currency) company revenue. Calculated as the sum of each asset's scope 1, 2, and 3 revenue intensity, weighted by its proportion in the fund. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a> and <a target="_blank" href="${LINKS.tcfdMetrics}">TCFD</a>.`,
    equation:
      'WACI = Σ (Revenue intensity (Millions) * (Outstanding amount / Total outstanding amount))',
    columnFormat: GQGridColumnFormat.Metric,
  },
  // TODO: SWAP THESE OUT FOR revenueIntensityScope1, revenueIntensityScope2, revenueIntensityScope3 in follow up
  {
    id: OutputKey.waciScope1,
    headerName: 'Revenue intensity: Scope 1',
    description: 'Scope 1 emissions of an asset divided by its revenue.',
    columnFormat: GQGridColumnFormat.Metric,
  },
  {
    id: OutputKey.waciScope2,
    headerName: 'Revenue intensity: Scope 2',
    description: 'Scope 2 emissions of an asset divided by its revenue.',
    columnFormat: GQGridColumnFormat.Metric,
  },
  {
    id: OutputKey.waciScope3,
    headerName: 'Revenue intensity: Scope 3',
    description: 'Scope 3 emissions of an asset divided by its revenue.',
    columnFormat: GQGridColumnFormat.Metric,
  },
  {
    id: OutputKey.pcaf,
    headerName: 'Total emissions PCAF Score',
    description: `The weighted PCAF data quality score for this segment of your portfolio. PCAF scores are weighted by outstanding amount, as defined on page 129 of <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>. Note: This excludes corporate assets using PCAF score 5 methodology.`,
    equation:
      'PCAF Score = Σ (Data quality score * (Outstanding amount / Total outstanding amount))',
    columnFormat: GQGridColumnFormat.Metric,
  },
  {
    id: OutputKey.pcafScoreScope1,
    headerName: 'Total emissions PCAF score: Scope 1',
    description: `The PCAF data quality score for Scope 1 total emissions, calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>.`,
    columnFormat: GQGridColumnFormat.Integer,
    width: 130,
  },
  {
    id: OutputKey.pcafScoreScope2,
    headerName: 'Total emissions PCAF score: Scope 2',
    description: `The PCAF data quality score for Scope 2 total emissions, calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>.`,
    columnFormat: GQGridColumnFormat.Integer,
    width: 130,
  },
  {
    id: OutputKey.pcafScoreScope3,
    headerName: 'Total emissions PCAF score: Scope 3',
    description: `The PCAF data quality score for Scope 3 total emissions, calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>.`,
    columnFormat: GQGridColumnFormat.Integer,
    width: 130,
  },
  {
    id: OutputKey.financedEmissionsPcafScoreScope1,
    headerName: 'Financed emissions PCAF score: Scope 1',
    description: `The PCAF data quality score for Scope 1 financed emissions, calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>.`,
    columnFormat: GQGridColumnFormat.Integer,
    width: 130,
  },
  {
    id: OutputKey.financedEmissionsPcafScoreScope2,
    headerName: 'Financed emissions PCAF score: Scope 2',
    description: `The PCAF data quality score for Scope 2 financed emissions, calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>.`,
    columnFormat: GQGridColumnFormat.Integer,
    width: 130,
  },
  {
    id: OutputKey.financedEmissionsPcafScoreScope3,
    headerName: 'Financed emissions PCAF score: Scope 3',
    description: `The PCAF data quality score for Scope 3 financed emissions, calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a>.`,
    columnFormat: GQGridColumnFormat.Integer,
    width: 130,
  },
  {
    id: OutputKey.pcafWeightedByEmissions,
    headerName: 'Aggregate PCAF score',
    description: 'Aggregate of each scope PCAF score weighted by emissions',
    columnFormat: GQGridColumnFormat.Float,
    width: 130,
  },
  {
    id: OutputKey.totalEmissions,
    headerName: 'Total emissions: All scopes',
    description: `Total scope 1, 2, and 3 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset. Percentage of emissions are based only on the assets accounted for in Watershed.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 220,
  },
  {
    id: OutputKey.totalEmissionsScope1,
    headerName: 'Total emissions: Scope 1',
    description: `Total scope 1 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset. For sovereign debt, this will prioritize scope 1 emissions with land use, land use change, and forestry.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope2,
    headerName: 'Total emissions: Scope 2',
    description: `Total scope 2 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope3,
    headerName: 'Total emissions: Scope 3',
    description: `Total scope 3 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope1WithLulucf,
    headerName: 'Total emissions: Scope 1 with LULUCF',
    description: `Total scope 1 emissions of an sovereign entity including land use, land use change, and forestry, expressed in tCO₂e. Only relevant for sovereign debt.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope1WithoutLulucf,
    headerName: 'Total emissions: Scope 1 without LULUCF',
    description: `Total scope 1 emissions of an sovereign entity excluding land use, land use change, and forestry, expressed in tCO₂e. Only relevant for sovereign debt.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope2Market,
    headerName: 'Total emissions: Scope 2 Market',
    description: `Total scope 2 Market <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope2Location,
    headerName: 'Total emissions: Scope 2 Location',
    description: `Total scope 2 Location <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope301,
    headerName: 'Total emissions: Scope 3.1',
    description: `Total scope 3.1 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope302,
    headerName: 'Total emissions: Scope 3.2',
    description: `Total scope 3.2 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope303,
    headerName: 'Total emissions: Scope 3.3',
    description: `Total scope 3.3 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope304,
    headerName: 'Total emissions: Scope 3.4',
    description: `Total scope 3.4 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope305,
    headerName: 'Total emissions: Scope 3.5',
    description: `Total scope 3.5 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope306,
    headerName: 'Total emissions: Scope 3.6',
    description: `Total scope 3.6 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope307,
    headerName: 'Total emissions: Scope 3.7',
    description: `Total scope 3.7 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope308,
    headerName: 'Total emissions: Scope 3.8',
    description: `Total scope 3.8 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope309,
    headerName: 'Total emissions: Scope 3.9',
    description: `Total scope 3.9 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope310,
    headerName: 'Total emissions: Scope 3.10',
    description: `Total scope 3.10 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope311,
    headerName: 'Total emissions: Scope 3.11',
    description: `Total scope 3.11 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope312,
    headerName: 'Total emissions: Scope 3.12',
    description: `Total scope 3.12 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope313,
    headerName: 'Total emissions: Scope 3.13',
    description: `Total scope 3.13 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope314,
    headerName: 'Total emissions: Scope 3.14',
    description: `Total scope 3.14 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope315,
    headerName: 'Total emissions: Scope 3.15',
    description: `Total scope 3.15 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope3OtherUpstream,
    headerName: 'Total emissions: Other Scope 3 Upstream',
    description: `Total other scope 3 upstream <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope3OtherDownstream,
    headerName: 'Total emissions: Other Scope 3 Downstream',
    description: `Total other downstream scope 3 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.totalEmissionsScope1And2,
    headerName: 'Total emissions: Scope 1 and 2',
    description: `Total scope 1 and 2 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 240,
  },
  {
    id: OutputKey.footprintSource,
    headerName: 'Footprint source',
    columnFormat: GQGridColumnFormat.String,
    description: `The source of this footprint data. Read more about footprint sources <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a>.`,
  },
  {
    // Only included for backwards compatibility for logging
    id: OutputKey.disclosureSource,
    headerName: 'Footprint source',
    columnFormat: GQGridColumnFormat.String,
    description: 'This indicates where our footprint data has been sourced.',
  },
  {
    id: OutputKey.revenueSourceLabel,
    headerName: 'Revenue source',
    columnFormat: GQGridColumnFormat.String,
    description:
      'This indicates where our revenue data has been sourced, whether from a disclosure or user input.',
  },
  {
    id: OutputKey.climateProgress,
    headerName: 'Climate progress',
    columnFormat: GQGridColumnFormat.String,
    description: `The current stage of an asset's climate program based on public disclosures. Indicates whether an asset has published footprint data, set targets, and/or reported to climate bodies such as CDP.`,
    width: 160,
  },
  {
    id: OutputKey.sbtCommitmentStage,
    headerName: 'SBTi status',
    columnFormat: GQGridColumnFormat.String,
    description: `The status of an asset's Science-Based Targets Initiative (SBTi) target, if applicable. Read more about SBTi <a target="_blank" href="${LINKS.learningHubClimateTargetSetting}">here</a>.`,
  },
  {
    id: OutputKey.surveyStatus,
    headerName: 'Portal status',
    columnFormat: GQGridColumnFormat.String,
    description: `Watershed's Portal feature enables you to collect data from your assets via a survey tool. This shows the status of data collection.`,
    width: 140,
  },
  {
    id: OutputKey.surveyPortalUrl,
    headerName: 'Portal URL',
    columnFormat: GQGridColumnFormat.String,
    description: `Watershed's Portal feature enables you to collect data from your assets via a survey tool. Click this link to see the Portal for a given asset.`,
    width: 140,
  },

  // TODO: (swati) better column descriptions
  {
    id: OutputKey.msmtSnapshotAt,
    headerName: 'Last updated',
    columnFormat: GQGridColumnFormat.DateTime,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this indicates the last time it disclosed its measurement progress to an investor's Watershed Finance dashboard.`,
    width: 164,
  },
  {
    id: OutputKey.msmtProjectName,
    headerName: 'Project name',
    columnFormat: GQGridColumnFormat.String,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this indicates the name of the measurement it shared to an investor's Watershed Finance dashboard (e.g., "2021 footprint").`,
    width: 164,
  },
  {
    id: OutputKey.msmtStatus,
    headerName: 'Measurement status',
    columnFormat: GQGridColumnFormat.String,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows whether that measurement has started, is on or off track, or is complete.`,
    width: 164,
  },
  {
    id: OutputKey.msmtDCKickoff,
    headerName: 'Target data collection start',
    columnFormat: GQGridColumnFormat.Date,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the target start date for data collection.`,
    width: 164,
  },
  {
    id: OutputKey.msmtDCEnd,
    headerName: 'Target data collection end',
    columnFormat: GQGridColumnFormat.Date,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the target end date for data collection.`,
    width: 164,
  },
  {
    id: OutputKey.msmtDraftFPDate,
    headerName: 'Draft footprint date',
    columnFormat: GQGridColumnFormat.Date,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the date the Watershed team completed this asset's draft footprint.`,
    width: 164,
  },
  {
    id: OutputKey.msmtFPApprovalDate,
    headerName: 'Target footprint approval date',
    columnFormat: GQGridColumnFormat.Date,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the target date for footprint approval.`,
    width: 164,
  },
  {
    id: OutputKey.msmtEstimateFPApprovalDate,
    headerName: 'Estimated footprint approval date',
    columnFormat: GQGridColumnFormat.Date,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the estimated date of footprint approval. Empty if the project is complete.`,
    width: 164,
  },
  {
    id: OutputKey.msmtCoverageStartDate,
    headerName: 'Coverage start date',
    columnFormat: GQGridColumnFormat.Date,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the beginning of the measurement period. For instance, a 2021 footprint measurement would indicate January 1st, 2021 as the coverage start date.`,
    width: 164,
  },
  {
    id: OutputKey.msmtCoverageEndDate,
    headerName: 'Coverage end date',
    columnFormat: GQGridColumnFormat.Date,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the end of the measurement period. For instance, a 2021 footprint measurement would indicate December 31st, 2021 as the coverage end date.`,
    width: 164,
  },
  {
    id: OutputKey.msmtCompletedAt,
    headerName: 'Measurement completion date',
    columnFormat: GQGridColumnFormat.Date,
    description: `If an asset measured its bottom-up footprint with Watershed, this shows the date on which the latest measurement was completed.`,
    width: 164,
  },
  {
    id: OutputKey.msmtTimelineSource,
    headerName: 'Measurement timeline source',
    columnFormat: GQGridColumnFormat.String,
    description: `The source of measurement timeline (Watershed or external).`,
    width: 164,
  },
  {
    id: OutputKey.msmtCollectedCompletedDatasets,
    headerName: 'Collected datasets',
    columnFormat: GQGridColumnFormat.String,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the number of datasets collected from this asset to date.`,
    width: 164,
  },
  {
    id: OutputKey.msmtCollectedExpectedCompletedDatasets,
    headerName: 'Expected collected datasets',
    columnFormat: GQGridColumnFormat.Integer,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the number of datasets expected to be collected.`,
    width: 164,
  },
  {
    id: OutputKey.msmtReviewProcessingCompletedDatasets,
    headerName: 'Processed datasets',
    columnFormat: GQGridColumnFormat.String,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the number of datasets processed by Watershed to date.`,
    width: 164,
  },
  {
    id: OutputKey.msmtReviewProcessingExpectedCompletedDatasets,
    headerName: 'Expected processed datasets',
    columnFormat: GQGridColumnFormat.Integer,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the total number of datasets that will need to be processed to complete the measurement.`,
    width: 164,
  },
  {
    id: OutputKey.msmtTotalDatasets,
    headerName: 'Total datasets for measurement',
    columnFormat: GQGridColumnFormat.Integer,
    description: `If an asset is measuring its bottom-up footprint with Watershed, this shows the total number of datasets that need to be collected and processed for this asset's measurement.`,
    width: 164,
  },
  {
    id: OutputKey.estimationStatus,
    headerName: 'Estimation status',
    columnFormat: GQGridColumnFormat.String,
    description: `This indicates if you have estimated either an asset's total emissions or financed emissions in Watershed Finance.`,
    width: 164,
  },
  {
    id: OutputKey.iigccStatus,
    headerName: 'IIGCC status',
    columnFormat: GQGridColumnFormat.String,
    description:
      'What stage is the company at in terms of the IIGCC Net Zero Investment Framework?',
  },
  {
    id: OutputKey.revenueUsd,
    headerName: 'Revenue (USD)',
    columnFormat: GQGridColumnFormat.Currency,
    description: 'Revenue, converted from native currency to USD.',
    width: 150,
  },
  {
    id: OutputKey.valueUsd,
    headerName: 'Asset value (USD)',
    columnFormat: GQGridColumnFormat.Currency,
    description: 'Asset value, converted from native currency to USD.',
    width: 150,
  },
  {
    id: OutputKey.outstandingAmountUsd,
    headerName: 'Outstanding amount (USD)',
    columnFormat: GQGridColumnFormat.Currency,
    description: 'Outstanding amount, converted from native currency to USD.',
    width: 150,
  },
  {
    id: OutputKey.valueTarget,
    headerName: 'Asset value (USD)',
    columnFormat: GQGridColumnFormat.Currency,
    description: 'Asset value, converted from native currency to USD.',
    width: 150,
  },
  {
    id: OutputKey.valueDisplay,
    headerName: 'Asset value',
    titleFn: buildCurrencyTitle('Asset value'),
    columnFormat: GQGridColumnFormat.Currency,
    description:
      'Asset value, converted from native currency to the display currency for the organization.',
    width: 150,
  },
  {
    id: OutputKey.revenueTarget,
    headerName: 'Revenue (USD)',
    columnFormat: GQGridColumnFormat.Currency,
    description: 'Revenue, converted from native currency to USD.',
    width: 150,
  },
  {
    id: OutputKey.revenueDisplay,
    headerName: 'Revenue',
    titleFn: buildCurrencyTitle('Revenue'),
    columnFormat: GQGridColumnFormat.Currency,
    description:
      'Revenue, converted from native currency to the display currency for the organization.',
    width: 150,
  },
  {
    id: OutputKey.outstandingAmountTarget,
    headerName: 'Outstanding amount (USD)',
    columnFormat: GQGridColumnFormat.Currency,
    description: 'Outstanding amount, converted from native currency to USD.',
    width: 150,
  },
  {
    id: OutputKey.outstandingAmountDisplay,
    headerName: 'Outstanding amount',
    titleFn: buildCurrencyTitle('Outstanding amount'),
    columnFormat: GQGridColumnFormat.Currency,
    description:
      'Outstanding amount, converted from native currency to the display currency for the organization.',
    width: 150,
  },
  {
    id: OutputKey.outstandingAmountUsdMetric,
    headerName: 'Outstanding amount',
    columnFormat: GQGridColumnFormat.Currency,
    description:
      'Sum of outstanding amount, converted from native currency to $USD',
    width: 150,
  },
  {
    id: OutputKey.revenueMultiple,
    headerName: 'Revenue multiple',
    columnFormat: GQGridColumnFormat.Float,
    description: 'Asset value / revenue',
    width: 150,
  },
  {
    id: OutputKey.scope1And2PortionOfEmissions,
    headerName: 'Portion of Scope 1 & 2',
    columnFormat: GQGridColumnFormat.Percentage,
    description: 'Portion of total emissions made up by Scope 1 and 2.',
    width: 150,
  },
  {
    id: OutputKey.outstandingAmountPortion,
    headerName: 'Outstanding amount portion',
    columnFormat: GQGridColumnFormat.Float,
    description: 'Outstanding amount divided by asset value',
    width: 150,
  },
  {
    id: OutputKey.inputsScope1Tco2e,
    headerName: 'Scope 1 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 1 includes the direct release of greenhouse gases from sources a company owns or controls (e.g. company-owned vehicles or furnaces). Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope1Pcaf,
    headerName: 'Scope 1 override PCAF score',
    columnFormat: GQGridColumnFormat.Integer,
    description: `PCAF score representing the quality of the Scope 1 override (e.g., estimate, actual, etc.)`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope2LocationTco2e,
    headerName: 'Scope 2, location-based override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 2 includes emissions from the generation of electricity, steam, heat, or cooling that a company purchases (e.g., from a power utility). A location-based <a target="_blank" href="${LINKS.ghgProtocolScope2}">method</a> reflects the average emissions intensity of grids on which energy consumption occurs (using mostly grid-average emission factor data). Scope overrides will replace estimated scopes.`,
    width: 150,
  },
  {
    id: OutputKey.inputsScope2LocationPcaf,
    headerName: 'Scope 2, location-based override PCAF score',
    columnFormat: GQGridColumnFormat.Integer,
    description: `PCAF score representing the quality of the Scope 2, location-based override (e.g., estimate, actual, etc.)`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope2MarketTco2e,
    headerName: 'Scope 2, market-based override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 2 includes emissions from the generation of electricity, steam, heat, or cooling that a company purchases (e.g., from a power utility). A market-based <a target="_blank" href="${LINKS.ghgProtocolScope2}">method</a> reflects emissions from electricity that companies have purposefully chosen (or their lack of choice). It derives emission factors from contractual instruments such as EACs or VPPAs. Scope overrides will replace estimated scopes.`,
    width: 150,
  },
  {
    id: OutputKey.inputsScope2MarketPcaf,
    headerName: 'Scope 2, market-based override PCAF score',
    columnFormat: GQGridColumnFormat.Integer,
    description: `PCAF score representing the quality of the Scope 2, market-based override (e.g., estimate, actual, etc.)`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope3OverrideKind,
    headerName: 'Scope 3 override kind',
    columnFormat: GQGridColumnFormat.String,
    description: `The kind of scope 3 override. If 'Overall', the full scope 3 will be replaced by the single scope 3 override value. If 'Subscope', individual subscopes will be overridden where they are specified.   Scope overrides will replace estimated scopes.`,
    width: 150,
  },
  {
    id: OutputKey.inputsScope3Tco2e,
    headerName: 'Scope 3 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description:
      'Total scope 3 emissions. Scope overrides will replace estimated scopes.',
    width: 105,
  },
  {
    id: OutputKey.inputsScope3Pcaf,
    headerName: 'Scope 3 override PCAF score',
    columnFormat: GQGridColumnFormat.Integer,
    description: `PCAF score representing the quality of the Scope 3 override (e.g., estimate, actual, etc.)`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope301Tco2e,
    headerName: 'Scope 3, category 1 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 1 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope302Tco2e,
    headerName: 'Scope 3, category 2 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 2 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope303Tco2e,
    headerName: 'Scope 3, category 3 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 3 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope304Tco2e,
    headerName: 'Scope 3, category 4 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 4 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope305Tco2e,
    headerName: 'Scope 3, category 5 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 5 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope306Tco2e,
    headerName: 'Scope 3, category 6 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 6 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope307Tco2e,
    headerName: 'Scope 3, category 7 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 7 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope308Tco2e,
    headerName: 'Scope 3, category 8 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 8 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope309Tco2e,
    headerName: 'Scope 3, category 9 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 9 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope310Tco2e,
    headerName: 'Scope 3, category 10 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 10 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope311Tco2e,
    headerName: 'Scope 3, category 11 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 11 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope312Tco2e,
    headerName: 'Scope 3, category 12 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 12 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope313Tco2e,
    headerName: 'Scope 3, category 13 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 13 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope314Tco2e,
    headerName: 'Scope 3, category 14 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 14 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsScope315Tco2e,
    headerName: 'Scope 3, category 15 override (tCO₂e)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Scope 3 category 15 emissions. Scope overrides will replace estimated scopes.`,
    width: 105,
  },
  {
    id: OutputKey.inputsTotalMwh,
    headerName: 'Total electricity consumption (MWh)',
    columnFormat: GQGridColumnFormat.Float,
    description: `Total electricity consumption of this asset (MWh) in the specified year. Read more about how we use this data point <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 105,
  },
  {
    id: OutputKey.industryLabel,
    headerName: 'Industry',
    columnFormat: GQGridColumnFormat.String,
    description: `The industry of the asset which you provided. We support BEA, NAICS, NACE, GICS, SICS, ANZSICS, and UKSIC codes. Read more about the industry codes we use <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 250,
  },
  {
    id: OutputKey.industrySectorLabel,
    headerName: 'Industry sector',
    columnFormat: GQGridColumnFormat.String,
    description:
      'The industry sector of the asset which you provided. For sector groupings, all BEA, NAICS, NACE, GICS, SICS, ANZSICS, and UKSIC codes are converted first to BEA, and the BEA sector is used here for consistency across industry code sets.',
    width: 250,
  },
  {
    id: OutputKey.industrySectorCode,
    headerName: 'Industry sector code',
    columnFormat: GQGridColumnFormat.String,
    description: 'The industry sector code of the asset which you provided.',
    width: 105,
  },
  {
    id: OutputKey.holdingCount,
    headerName: 'Holding count',
    columnFormat: GQGridColumnFormat.Integer,
    description: 'The number of holdings in this group.',
    width: 105,
  },
  {
    id: OutputKey.assetCount,
    headerName: 'Asset count',
    columnFormat: GQGridColumnFormat.Integer,
    description: 'The number of assets in this group.',
    width: 105,
  },
  {
    id: OutputKey.iciAlignmentStatus,
    headerName: 'iCI alignment status',
    columnFormat: GQGridColumnFormat.String,
    description: `This company’s climate maturity, as defined by the Initiative Climate International’s (iCI) <a target="_blank" href="${LINKS.pmdrRoadmap}">Private Markets Decarbonization Roadmap</a> (PMDR).`,
    width: 125,
  },

  // Custom output for data flags
  {
    id: OutputKey.priorYearTotalEmissions,
    headerName: 'Prior year total emissions: All scopes',
    description: `Total scope 1, 2, and 3 <a target="_blank" href="${LINKS.learningHubScopesOfEmissions}">emissions</a> of an asset. Percentage of emissions are based only on the assets accounted for in Watershed.`,
    columnFormat: GQGridColumnFormat.Metric,
    width: 260,
  },
  {
    id: OutputKey.priorYearRevenueNative,
    headerName: 'Prior year revenue',
    columnFormat: GQGridColumnFormat.Currency,
    description: `Annual revenue of the asset for the prior year. Read more about how we use this data point <a target="_blank" href="${LINKS.learningHubCEE}">here</a>.`,
    width: 180,
  },
  {
    id: OutputKey.percentageChange,
    headerName: 'Percentage change',
    columnFormat: GQGridColumnFormat.Percentage,
    description: `Difference between this year and the prior year.`,
    width: 180,
  },
  {
    id: OutputKey.priorYearValueNative,
    headerName: 'Prior year asset value',
    columnFormat: GQGridColumnFormat.Currency,
    description: `Required to estimate financed emissions. For companies, this is enterprise value at end of year. For commercial real estate, this is property value at origination. Read the PCAF-aligned definitions of these terms for companies <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a> and commercial real estate <a target="_blank" href="${LINKS.learningHubRealEstate}">here</a>.`,
    width: 180,
  },
  {
    id: OutputKey.priorYearFootprintSource,
    headerName: 'Prior year footprint source',
    columnFormat: GQGridColumnFormat.String,
    description: `The source of this footprint data. Read more about footprint sources <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a>.`,
    width: 200,
  },
  {
    id: OutputKey.priorYearRevenueIntensity,
    headerName: 'Prior year revenue intensity',
    description:
      'Total emissions divided by borrower or investee revenue in USD, expressed as tCO₂e/$M.',
    equation: 'Total emissions / Revenue $M = Revenue intensity',
    columnFormat: GQGridColumnFormat.Metric,
    width: 200,
  },
  {
    id: OutputKey.priorYearFinancedEmissions,
    headerName: 'Prior year financed emissions: All scopes',
    description: `Emissions of the borrower or investee multiplied by the financial institution's percentage ownership, expressed in tCO₂e. Calculated in line with <a target="_blank" href="${LINKS.pcafPartA}">PCAF Part A</a> (<a target="_blank" href="${LINKS.learningHubFinancedEmissions}">Learn more</a>). Percentage of fund's emissions are based only on the assets accounted for in Watershed.`,
    equation: 'Total emissions * Attribution factor = Financed emissions',
    columnFormat: GQGridColumnFormat.Metric,
    width: 300,
  },
  {
    id: OutputKey.priorYearOutstandingAmountNative,
    headerName: 'Prior year outstanding amount',
    columnFormat: GQGridColumnFormat.Currency,
    description: `Required to estimate financed emissions. The amount of a firm's investment or loan. For sovereign debt, this is the face value of the bond. Used to calculate the attribution factor. Read the PCAF-aligned definitions of this term for companies <a target="_blank" href="${LINKS.learningHubMeasuring}">here</a> and commercial real estate <a target="_blank" href="${LINKS.learningHubRealEstate}">here</a>.`,
    width: 240,
  },
].map((field) => ({
  // Include default values
  editable: false,
  field: field.id,
  width: null,
  disableHide: false,
  disableReorder: false,
  disableResize: false,
  columnConfiguration: null,
  ...field,
}));

export const financeFieldRegistryValues = [
  ...allOutputs,
  ...allInputs.filter(
    (input) => input.field !== 'name' && input.field !== 'assetName'
  ),
];

const financeFieldRegistry = financeFieldRegistryValues.reduce(
  (memo, field) => {
    memo[field.field as InputKey | OutputKey] = field;
    return memo;
  },
  {} as { [key in InputKey | OutputKey]: FinanceField }
);

export default financeFieldRegistry;

export function getFinanceFieldHeaderName(
  field: keyof typeof financeFieldRegistry,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): string {
  const { headerName } = financeFieldRegistry[field];
  if (shouldRenameFund) {
    return renameFundToLineOfBusiness(headerName);
  }
  return headerName;
}

export function getFinanceFieldDescription(
  field: keyof typeof financeFieldRegistry,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): string {
  const { description } = financeFieldRegistry[field];
  if (shouldRenameFund) {
    return renameFundToLineOfBusiness(description);
  }
  return description;
}

export function getFinanceNonNegativeFieldErrorMessage(
  field: keyof typeof financeFieldRegistry,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): string {
  const headerName = getFinanceFieldHeaderName(field, { shouldRenameFund });
  return headerName + ' cannot be negative';
}

export function getFinancePositiveFieldErrorMessage(
  field: keyof typeof financeFieldRegistry,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): string {
  const headerName = getFinanceFieldHeaderName(field, { shouldRenameFund });
  return headerName + ' cannot be zero';
}

export function getFinanceLessThanEqual100FieldPctMessage(
  field: keyof typeof financeFieldRegistry,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): string {
  const headerName = getFinanceFieldHeaderName(field, { shouldRenameFund });
  return headerName + ' cannot be greater than 100%';
}

export function getFinanceIntegerFieldErrorMessage(
  field: keyof typeof financeFieldRegistry,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): string {
  const headerName = getFinanceFieldHeaderName(field, { shouldRenameFund });
  return headerName + ' must be an integer';
}

export function getFinanceNonEmptyStringErrorMessage(
  field: keyof typeof financeFieldRegistry,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): string {
  const headerName = getFinanceFieldHeaderName(field, { shouldRenameFund });
  return headerName + ' cannot be blank';
}

export const FINANCE_FILTER_TABLE_TO_COLUMNS: Record<string, Array<string>> = {
  fin_snap_dim_asset: [
    'asset_category',
    'asset_id',
    'building_kind',
    'building_size_unit',
    'building_subkind',
    'city',
    'country_alpha2',
    'postal_code',
    'state',
    'tag_data',
  ],
  fin_snap_dim_year: [
    'climate_progress',
    'footprint_source',
    'emissions_year_source',
    'estimation_methodology',
    'iigcc_status',
    'sbt_commitment_stage',
    'revenue_source_currency',
    'revenue_source_label',
    'year',
    'tag_data',
    'measuring_scope1_and2_emissions',
    'measuring_material_scope3_emissions',
    'initiated_decarbonization_plan',
    'committed_to_near_term_sbt_aligned_net_zero',
    'demonstrating_yoy_emissions_in_line_with_net_zero',
    'ici_alignment_override',
    'ici_alignment_status',
  ],
  fin_snap_dim_fund: ['fund_id', 'fund_group', 'fund_category', 'tag_data'],
  fin_snap_dim_holding: ['asset_class', 'tag_data'],
  fin_snap_dim_industry: [
    'industry_label',
    'industry_sector_label',
    'industry_sector_code',
  ],
  fin_snap_dim_scope: ['scope', 'subscope'],
};

export const GENERATED_FINANCE_FILTER_TABLES = [
  'fin_snap_dim_industry',
  'fin_snap_dim_scope',
] as const;

export const FINANCE_FILTER_TABLE_TO_FUND_JOIN: Record<string, string> = {
  fin_snap_dim_asset:
    'LEFT JOIN fin_snap_dim_year ON fin_snap_dim_year.fin_snap_asset_id = fin_snap_dim_asset.id LEFT JOIN fin_snap_dim_holding ON fin_snap_dim_holding.fin_snap_year_id = fin_snap_dim_year.id LEFT JOIN fin_snap_dim_fund ON fin_snap_dim_fund.id = fin_snap_dim_holding.fin_snap_fund_id',
  fin_snap_dim_year:
    'LEFT JOIN fin_snap_dim_holding ON fin_snap_dim_holding.fin_snap_year_id = fin_snap_dim_year.id LEFT JOIN fin_snap_dim_fund ON fin_snap_dim_fund.id = fin_snap_dim_holding.fin_snap_fund_id',
  fin_snap_dim_fund: '',
  fin_snap_dim_holding:
    'LEFT JOIN fin_snap_dim_fund ON fin_snap_dim_fund.id = fin_snap_dim_holding.fin_snap_fund_id',
  fin_snap_dim_industry: '',
  fin_snap_dim_scope: '',
};

export const FINANCE_FILTER_COLUMNS = Object.entries(
  FINANCE_FILTER_TABLE_TO_COLUMNS
).flatMap(([table, columns]) => {
  return (
    columns
      // weird that we have to remove these
      .filter((column) => column !== 'year' && column !== 'tag_data')
      .map((column) => `${table}.${column}`)
  );
});

export const FINANCE_FILTER_COLUMNS_QUERY_PARAMS = FINANCE_FILTER_COLUMNS.map(
  (column) => camelCase(column.split('.').pop())
);

export const getFinanceFieldLabel = (
  field: string,
  {
    shouldRenameFund,
    filterOptions,
  }: {
    shouldRenameFund: boolean;
    filterOptions?: Record<string, { label: string }>;
  }
): string => {
  if (filterOptions && filterOptions[field]) {
    return filterOptions[field].label;
  }

  try {
    return getFinanceFieldHeaderName(
      field as keyof typeof financeFieldRegistry,
      { shouldRenameFund }
    );
  } catch (e) {
    // Ya, it's not great, but it's simple label overrides.
    if (field === 'scope') {
      return 'GHG scope';
    }
    if (field === 'subscope') {
      return 'Scope 3 category';
    }
    return humanize(field);
  }
};

export function getFinanceFieldHeader(
  financeField: FinanceField,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): FinanceFieldTitleFn {
  return (args): string => {
    const headerName = financeField.titleFn?.(args) ?? financeField.headerName;
    if (shouldRenameFund) {
      return renameFundToLineOfBusiness(headerName);
    }
    return headerName;
  };
}

// TODO: Allow sentence case too? Suncorp currently uses Pascal Case.
export const COMPANY_NAME_TAG_NAME = 'Company Name';
