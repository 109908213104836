import gql from 'graphql-tag';
import React, { useContext, useMemo, useState } from 'react';
import { useCompleteOnboardingMutation } from '@watershed/shared-frontend/generated/urql';
import { GQOnboardingKind } from '@watershed/shared-universal/generated/graphql';
import { useUserContext } from './UserContext';

gql`
  mutation CompleteOnboarding($userId: ID!, $kind: OnboardingKind!)
    @withOwner(owner: EnterpriseFoundations) {
    completeOnboarding(input: { userId: $userId, kind: $kind }) {
      user {
        id
      }
      userOnboardingsCompleted
    }
  }
`;

type UserOnboardingsContextType = {
  userOnboardingsCompleted: Set<GQOnboardingKind>;
  completeOnboarding: (kind: GQOnboardingKind) => void;
};

const defaults: UserOnboardingsContextType = {
  userOnboardingsCompleted: new Set([]),
  completeOnboarding: (kind: GQOnboardingKind) => {},
};

export const UserOnboardingsContext = React.createContext(defaults);

export function UserOnboardingsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const userContext = useUserContext();

  const [userOnboardingsCompleted, setUserOnboardingsCompleted] = useState(
    new Set(userContext.userOnboardingsCompleted)
  );

  const [, executeUpdateOnboarding] = useCompleteOnboardingMutation();

  const value = useMemo(() => {
    const completeOnboarding = async (kind: GQOnboardingKind) => {
      if (userContext.loginAsUser === null) {
        await executeUpdateOnboarding({
          userId: userContext.userId,
          kind,
        });
      }

      setUserOnboardingsCompleted((state) => {
        return new Set([...state, kind]);
      });
    };

    return {
      userOnboardingsCompleted,
      completeOnboarding,
    };
  }, [
    userOnboardingsCompleted,
    executeUpdateOnboarding,
    userContext.userId,
    userContext.loginAsUser,
  ]);

  return (
    <UserOnboardingsContext.Provider value={value}>
      {children}
    </UserOnboardingsContext.Provider>
  );
}

export function useUserOnboardings(): [
  userOnboardingsCompleted: Set<GQOnboardingKind>,
  completeOnboarding: (kind: GQOnboardingKind) => void,
] {
  const { userOnboardingsCompleted, completeOnboarding } = useContext(
    UserOnboardingsContext
  );

  return [userOnboardingsCompleted, completeOnboarding];
}

export function useUserOnboarding(
  onboardingKind: GQOnboardingKind
): [boolean, () => void] {
  const { userOnboardingsCompleted, completeOnboarding } = useContext(
    UserOnboardingsContext
  );
  const hasCompletedOnboarding = userOnboardingsCompleted.has(onboardingKind);
  return [hasCompletedOnboarding, () => completeOnboarding(onboardingKind)];
}
