import { PrimaryTypeName } from '../../batSchemas/types';

export const FinanceHoldingTypesList = [
  'corporate_asset_holding',
  'real_estate_asset_holding',
  'asset_group_holding',
  'personal_motor_vehicle_insurance_holding',
  'sovereign_debt_holding',
  'insurance_commercial_line_holding',
] as const satisfies ReadonlyArray<PrimaryTypeName>;

// We define our finance holding types here so that we can reliably reference
// the string names elsewhere, and be sure they are valid BAT types.
export type FinanceHoldingType = (typeof FinanceHoldingTypesList)[number];

// Names of references data associated with finance assets.  Should match up
// with what we have defined in cliq
// https://github.com/watershed-climate/cliq/tree/main/cliq/domain/finance/rds
export type FinanceAssetRefDataName =
  | 'corporate_asset_properties'
  | 'corporate_asset_emissions_factors'
  | 'real_estate_asset_properties'
  | 'real_estate_asset_emissions_factors'
  | 'asset_group_properties'
  | 'asset_group_emissions_factors'
  | 'personal_motor_vehicle_insurance_emissions_factors'
  | 'corporate_asset_pcaf_5_emissions_factors';

export const RolledOutFinanceHoldingTypes: Record<FinanceHoldingType, boolean> =
  {
    corporate_asset_holding: true,
    real_estate_asset_holding: true,
    asset_group_holding: false,
    personal_motor_vehicle_insurance_holding: false,
    sovereign_debt_holding: false,
    insurance_commercial_line_holding: false,
  };

export const FinanceHoldingTypeToAssetString: Record<
  FinanceHoldingType,
  string
> = {
  corporate_asset_holding: 'Corporate Asset',
  insurance_commercial_line_holding: 'Corporate Asset',
  real_estate_asset_holding: 'Commercial Real Estate',
  asset_group_holding: 'Asset Group',
  personal_motor_vehicle_insurance_holding: 'Personal Motor Vehicle Insurance',
  sovereign_debt_holding: 'Sovereign Debt',
};
