import { gql } from 'graphql-tag';
import { Trans, useLingui } from '@lingui/react/macro';
import { Box, Stack, Typography } from '@mui/material';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import CircleRemoveIcon from '@watershed/icons/components/CircleRemove';
import Button from '@watershed/ui-core/components/Button';
import { useEffect } from 'react';
import { doLogout } from '@watershed/shared-frontend/hooks/useLogout';
import PageContainer from '@watershed/shared-frontend/components/PageContainer';
import { useUserContext } from '../utils/UserContext';
import { GQPermissionType } from '@watershed/shared-universal/generated/graphql';
import Callout from '@watershed/shared-frontend/components/Callout';
import {
  useCreateUserPermissionRequestMutation,
  useForbiddenPageDataQuery,
} from '@watershed/shared-frontend/generated/urql';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import { getGqlResultData } from '@watershed/shared-frontend/utils/errorUtils';
import TextLink from '@watershed/ui-core/components/TextLink';
import lowerCase from 'lodash/lowerCase';
import { getPermissionDisplayName } from '@watershed/shared-universal/permissions/permissionsDisplayUtils';
import SendIcon from '@watershed/icons/components/Send';
import CircleCheckmarkIcon from '@watershed/icons/components/CircleCheckmark';
import ProgressButton from '@watershed/ui-core/components/ProgressButton';
import { getLatestUserActivatedPreferred } from '@watershed/shared-universal/utils/userUtils';
import useHasPermission from '../utils/useHasPermission';
import { routeForHome } from '@watershed/shared-universal/dashboardRoutes';
import { useRouter } from 'next/router';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

gql`
  fragment UserContactFields on User {
    id
    name
    displayName
    email
    createdAt
    loginActivated
  }

  query ForbiddenPageData($missingPermission: PermissionType!)
    @withOwner(owner: EnterpriseFoundations) {
    adminUsers {
      ...UserContactFields
    }
    pendingUserPermissionRequestsForUser(permission: $missingPermission) {
      ...UserPermissionRequestFields
    }
  }

  mutation CreateUserPermissionRequest(
    $input: CreateUserPermissionRequestInput!
  ) @withOwner(owner: EnterpriseFoundations) {
    createUserPermissionRequest(input: $input) {
      userPermissionRequest {
        ...UserPermissionRequestFields
      }
    }
  }
`;

function RequestPermissionsCallout({
  missingPermission,
}: {
  missingPermission: GQPermissionType;
}) {
  const [result] = useForbiddenPageDataQuery({
    variables: {
      missingPermission,
    },
  });
  const [createPermissionRequestResult, createUserPermissionRequest] =
    useCreateUserPermissionRequestMutation();
  const { t } = useLingui();

  if (isFetchingOrStale(result)) {
    return null;
  }

  const data = getGqlResultData(result);
  const adminUsers = data?.adminUsers;
  if (!adminUsers || adminUsers?.length === 0) {
    return null;
  }

  // if the user has a pending request
  // or is currently sending a request
  // don't allow them to send another
  const hasPendingRequest =
    data.pendingUserPermissionRequestsForUser.length > 0 ||
    !!createPermissionRequestResult.data;
  const allowUserToSendPermissionRequest =
    !hasPendingRequest && !isFetchingOrStale(createPermissionRequestResult);

  const adminUser = getLatestUserActivatedPreferred(adminUsers);
  const permissionDesc = lowerCase(getPermissionDisplayName(missingPermission));
  return (
    <Callout
      variant="info"
      description={
        <>
          <Typography variant="h4" sx={{ mb: 1 }}>
            <Trans>
              Ask{' '}
              <TextLink href={`mailto:${adminUser.email}`}>
                {adminUser.displayName}
              </TextLink>{' '}
              for permission to {permissionDesc}.
            </Trans>
          </Typography>
          <ProgressButton
            disabled={!allowUserToSendPermissionRequest}
            label={
              hasPendingRequest
                ? t({
                    message: 'Sent request',
                    context: 'button label when request has finished sending',
                  })
                : t({
                    message: 'Request permissions',
                    context: 'button label for user to request permissions',
                  })
            }
            progressLabel={t({
              message: 'Sending request',
              context: 'In progress label for sending request',
            })}
            endIcon={hasPendingRequest ? <CircleCheckmarkIcon /> : <SendIcon />}
            isInProgress={isFetchingOrStale(createPermissionRequestResult)}
            onClick={async () => {
              await createUserPermissionRequest({
                input: { permission: missingPermission },
              });
            }}
          />
        </>
      }
    />
  );
}

export function ForbiddenPageBody({
  title = (
    <Trans context="title when user does not have permission to access page">
      Forbidden
    </Trans>
  ),
  subtitle = (
    <Trans>Reach out to your organization administrator for access</Trans>
  ),
  missingPermission,
}: {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  missingPermission?: GQPermissionType;
}) {
  const { permissions } = useUserContext();
  const router = useRouter();
  useEffect(() => {
    Analytics.error('forbiddenPageShown');
  }, []);

  return (
    <>
      <CircleRemoveIcon size={128} marginBottom={1} color="warning.main" />
      <Typography variant="h1" paragraph>
        {title}
      </Typography>
      <Stack gap={2} sx={{ alignItems: 'center' }}>
        {subtitle && <Typography variant="body2">{subtitle}</Typography>}
        {missingPermission && (
          <RequestPermissionsCallout missingPermission={missingPermission} />
        )}
        <Box>
          <Stack direction="row" spacing={1}>
            {permissions.length > 0 && (
              <Button
                onClick={async () => {
                  await router.push(routeForHome());
                  window.location.reload();
                }}
                color="primary"
              >
                <Trans context="button copy">Go to the homepage</Trans>
              </Button>
            )}
            <Button onClick={() => doLogout()}>
              <Trans context="button copy">Sign out</Trans>
            </Button>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}

interface ForbiddenPageProps {
  // TODO(Henry): should be a list of missing permissions relevant to the
  // forbidden error
  missingPermission?: GQPermissionType;
}

export function withPagePermissionCheck<P extends {}>(
  {
    requiredPermission,
    wrapper = (children) => <>{children}</>,
  }: {
    requiredPermission: GQPermissionType;
    wrapper?: (children: React.ReactNode) => JSX.Element;
  },
  Component: React.ComponentType<React.PropsWithChildren<P>>
) {
  return function WithWatershedPlanAccessCheck(props: P) {
    const hasPermission = useHasPermission([requiredPermission]);
    if (!hasPermission) {
      return wrapper(<ForbiddenPage />);
    }
    return <Component {...props} />;
  };
}

export default function ForbiddenPage(props: ForbiddenPageProps) {
  const { missingPermission } = props;
  const { permissions } = useUserContext();

  const pageCopy = {
    title: (
      <Trans context="title when user does not have permission to access page">
        Forbidden
      </Trans>
    ),
    subtitle: (
      <Trans>
        Please reach out to your organization administrator for access to this
        page
      </Trans>
    ),
  };
  if (permissions.length === 0) {
    pageCopy.title = (
      <Trans context="title when user has not been granted any permissions">
        No permissions granted
      </Trans>
    );

    pageCopy.subtitle = (
      <Trans>
        Your organization’s administrator invited you to Watershed without any
        permissions. Please reach out to them to access Watershed.
      </Trans>
    );
  }

  return (
    <PageContainer
      testId={TestIds.ForbiddenPage}
      isFullPage={false}
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: 64,
      }}
    >
      <ForbiddenPageBody {...pageCopy} missingPermission={missingPermission} />
    </PageContainer>
  );
}
