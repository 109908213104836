import { Dialog } from '@watershed/ui-core/components/Dialog';
import gql from 'graphql-tag';
import { useCreateFinanceSavedViewMutation } from '@watershed/shared-frontend/generated/urql';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import z from 'zod';
import {
  CreateFinanceSavedViewForm,
  createFinanceSavedViewFormSchema,
} from '../../finance/inputs/FinanceSavedViewForm';
import { routeForFinanceView } from '@watershed/shared-universal/dashboardRoutes';
import { useRouter } from 'next/router';
import Callout from '@watershed/shared-frontend/components/Callout';
import ErrorIcon from '@watershed/icons/components/Error';
import { useState } from 'react';
import { FullFilters } from '@watershed/shared-universal/apiSchemas/finance/zodFinance';
import { combineFiltersForDropdown } from '../FinanceFilterButton';

gql`
  mutation CreateFinanceSavedView($input: CreateFinanceSavedViewInput!) @withOwner(owner: WinFinance) {
    createFinanceSavedView(input: $input) {
      financeSavedView {
        id
        ...FinanceSavedViewAllFields
      }
    }
  }
`;

export default function CreateFinanceSavedViewDialog({
  open,
  onClose,
  fullFilters,
  hiddenColumns,
}: {
  open: boolean;
  onClose: () => void;
  fullFilters?: FullFilters;
  hiddenColumns?: Array<string> | null;
}) {
  const router = useRouter();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [, executeCreateFinanceSavedViewMutation] =
    useCreateFinanceSavedViewMutation();

  async function handleSubmit(
    input: z.TypeOf<typeof createFinanceSavedViewFormSchema>
  ) {
    if (errorMsg) {
      setErrorMsg(null);
    }
    const result = await executeCreateFinanceSavedViewMutation({
      input: {
        ...input,
        charts: input.charts ?? [],
        metrics: input.metrics ?? [],
        hiddenColumns: hiddenColumns ?? undefined,
        layout: input.layout ?? 'FinanceLayoutChartsMetricsInPanel',
      },
    });
    if (result.error) {
      Analytics.error('Error creating finance saved view', {
        error: result.error,
      });
      setErrorMsg(result.error.message.replace('[GraphQL] ', ''));
    } else {
      // TODO: This should probably do an invalidation of the urql cache
      // instead of doing this?
      onClose();
      const newViewId =
        result.data?.createFinanceSavedView?.financeSavedView?.id;
      if (!newViewId) {
        return;
      }
      await router.push(routeForFinanceView(newViewId, router.query));
    }
  }

  return (
    <Dialog
      disableBackdropClick
      maxWidth="md"
      open={open}
      onClose={onClose}
      header={{
        title: 'Create view',
        hideClose: false,
      }}
    >
      {errorMsg ? (
        <Callout
          variant="error"
          // TODO: i18n (please resolve or remove this TODO line if legit)
          // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
          title="Error creating view"
          description={errorMsg}
          IconComponent={ErrorIcon}
          sx={{ mb: 2 }}
        />
      ) : null}
      <CreateFinanceSavedViewForm
        onClose={onClose}
        onSubmit={handleSubmit}
        defaultValues={{
          filters: fullFilters
            ? combineFiltersForDropdown(fullFilters, true)
            : {},
          layout: 'FinanceLayoutChartsMetricsInPanel',
        }}
      />
    </Dialog>
  );
}
