import { gql } from 'graphql-tag';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import {
  GQPermissionType,
  GQReportMode,
} from '@watershed/shared-universal/generated/graphql';
import useHasPermission from '../../utils/useHasPermission';
import { useReportSummariesQuery } from '@watershed/shared-frontend/generated/urql';
import {
  WatershedProduct,
  useCurrentProductContext,
} from '../../utils/CurrentProductContext';

gql`
  fragment ReportSummary on Report {
    id
    name
    updatedAt
  }

  fragment CustomReportSummary on FootprintCustomReport {
    id
    name
    updatedAt
  }

  query ReportSummaries($isAdmin: Boolean!, $reportMode: ReportMode!) @withOwner(owner: Reporting) {
    formalReports(last: 5, reportMode: $reportMode) {
      edges {
        node {
          ...ReportSummary
        }
      }
    }
    footprintAnalysis @include(if: $isAdmin) {
      id
      customReports {
        ...CustomReportSummary
      }
    }
  }
`;

export function useRecentReportSummaries() {
  const isAdmin = useHasPermission([GQPermissionType.Admin]);
  const { currentProduct } = useCurrentProductContext();

  const [{ data, fetching: isLoading }] = useReportSummariesQuery({
    variables: {
      isAdmin,
      reportMode:
        currentProduct === WatershedProduct.Finance
          ? GQReportMode.Finance
          : GQReportMode.Corporate,
    },
    pause: currentProduct === WatershedProduct.Unknown,
  });

  const reports = flattenConnection(data?.formalReports) || [];
  const customReports = isAdmin
    ? data?.footprintAnalysis?.customReports || []
    : [];
  // sorting is returned from the server
  const recentReports = [...reports, ...customReports].slice(0, 5);

  return { recentReports, isLoading };
}
